import { configureStore } from '@reduxjs/toolkit'
import { DegreeSlice} from '../Slices/Degree'
import { IndustrytSlice } from '../Slices/Industry'
import { JobTypeSlice } from '../Slices/JobType'
import { QualificationSlice } from '../Slices/Qualification'
import { SkillSlice } from '../Slices/Skill'
import { UserSlice } from '../Slices/User'
import { AuthSlice } from '../Slices/AuthSlice'
import { Sliderslice } from '../Slices/Slider'
import { JobSlice } from '../Slices/JobPost'
import { CompanySlice } from '../Slices/Company'

export const Store = configureStore({
  reducer: {
    Auth:AuthSlice.reducer,
    User:UserSlice.reducer,
    Degrees:DegreeSlice.reducer,
    Industry:IndustrytSlice.reducer,
    JobType:JobTypeSlice.reducer,
    Qualification:QualificationSlice.reducer,
    Skill:SkillSlice.reducer,
    Slider:Sliderslice.reducer,
    Jobs:JobSlice.reducer,
    Company:CompanySlice.reducer
  },
})