import React, { useEffect, useState } from "react";
import { PiBagSimple } from "react-icons/pi";
import { GoLocation } from "react-icons/go";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { LiaIndustrySolid } from "react-icons/lia";
import { SlGraduation } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import { yearOption, thousands, lacOption } from "../../../Helper/Option";
import Location from "../../Admin/Location";
import { useDispatch, useSelector } from "react-redux";
import { SearchJob, ViewsOnJob } from "../../../Redux/Slices/JobPost";
import { formatTimeAgo } from "../../../Helper/FromatTime";
import { FetchIndustries } from "../../../Redux/Slices/Industry";
import { FetchJobTypes } from "../../../Redux/Slices/JobType";
import { FetchQualifications } from "../../../Redux/Slices/Qualification";
import SearchImg from "../../../asset/new/images/40-02-02.png";
import { FaArrowLeft } from "react-icons/fa";

const ThirdPartyListing = () => {
  const { allJobs } = useSelector((state) => state?.Jobs);
  const { qualificationsData } = useSelector((state) => state?.Qualification);
  const [searchedJob, setSearchJob] = useState(allJobs);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState({});
  const [searchHistory, setSearchHistory] = useState(null);

  const [locationData, setLocationData] = useState({
    Country: "",
    State: "",
    Cities: [],
  });
  // Function to receive Location data from the child
  const receiveDataFromChild = (data) => {
    setLocationData(data);
  };

  let name, value;
  const searchHandler = (e) => {
    e.preventDefault();
    name = e.target.name;
    value = e.target.value;
    setSearchQuery({ ...searchQuery, [name]: value });
  };

  // Search Button Handler
  const searchButtonHandler = async (e) => {
    e.preventDefault();

    let data = {
      type: "ThirdParty Listing",
      days_ago: searchQuery.daysAgo,
      qualification: [searchQuery.industry], // Done
      from_exp: searchQuery.exp_from, // Done
      to_exp: searchQuery.exp_to, //Done
      ctc_from_lacs: searchQuery.ctc_from_lacs, // Done
      ctc_from_thousand: searchQuery.ctc_from_thousand, //done
      ctc_to_lacs: searchQuery.ctc_to_lacs, // Done
      ctc_to_thousand: searchQuery.ctc_to_thousand, // Done
      // country: locationData.Country, // Done
      cities: locationData.Cities, // Done
    };

    // Function to remove undefined, null, empty strings, and empty arrays
    const removeEmpty = (obj) => {
      for (const key in obj) {
        if (
          obj[key] === null ||
          obj[key] === undefined ||
          (typeof obj[key] === "string" && obj[key].trim() === "")
        ) {
          delete obj[key];
        } else if (Array.isArray(obj[key])) {
          obj[key] = obj[key].filter(
            (item) => item !== null && item !== undefined
          );
          if (obj[key].length === 0) {
            delete obj[key];
          }
        } else if (typeof obj[key] === "object") {
          removeEmpty(obj[key]);
          if (Object.keys(obj[key]).length === 0) {
            delete obj[key];
          }
        }
      }
    };

    // Remove undefined, null, empty strings, and empty arrays from data
    removeEmpty(data);

    let fromdata = await SearchJob(data);
    setSearchJob(fromdata);
    setSearchHistory(data);
  };

  // Profile Visit
  const viewHandler = async (id, count) => {
    let data = { id, count: count + 1 };
    await ViewsOnJob(data);
    navigate(`/job-description/${id}`);
  };

  useEffect(() => {
    let data = {
      page: 1,
      search: "",
    };
    dispatch(FetchIndustries(data));
    dispatch(FetchQualifications());
    dispatch(FetchJobTypes());

    document.title = "ThirdParty Listing Job | CAJobPortal";
  }, [searchedJob]);

  const jobhandler = async () => {
    let data = {
      type: ["ThirdParty Listing", "Short-Term Assignments"],
    };
    let fromdata = await SearchJob(data);
    setSearchJob(fromdata);
  };

  useEffect(() => {
    jobhandler();
  }, []);

  const handlePageChange = async (page) => {
    let data = { ...searchHistory, page };
    let fromdata = await SearchJob(data);
    setSearchJob(fromdata);
  };

  // Calculate visible page range
  let startPage = 1;
  let endPage = 1;

  if (searchedJob?.pagination) {
    startPage = Math.max(1, searchedJob.pagination.page - 2);
    endPage = Math.min(
      searchedJob.pagination.totalPages,
      searchedJob.pagination.page + 2
    );

    // Adjust start and end page if close to beginning or end
    if (searchedJob.pagination.page <= 3) {
      endPage = Math.min(5, searchedJob.pagination.totalPages);
    } else if (
      searchedJob.pagination.page >=
      searchedJob.pagination.totalPages - 2
    ) {
      startPage = Math.max(1, searchedJob.pagination.totalPages - 4);
    }
  }

  return (
    <>
      {/* Hero Section */}
      <div>
        <div className="row hero-job">
          <p className="back-arrow col-md-3 mt-2" onClick={() => navigate(-1)}>
            <FaArrowLeft />
          </p>
          <h1 className=" col-8 text-center fs-3">Third Party Jobs</h1>
        </div>
      </div>
      {/* -------------- Job - Filter Section --------- */}
      <div className="featured-job-container">
        <div className="row">
          {/* ----Sidebar --- */}
          <div className="col-md-3">
            {/* Job Posted Date */}
            <div className="row">
              <div className="col-8">
                <h4>Filters</h4>
              </div>
              <div className="col-4">
                {/* <p className="text-danger mt-1">ClearAll</p> */}
              </div>
            </div>
            <div className="filter-box2">
              <p className="search-minihead">Job Posted</p>

              <select
                name="daysAgo"
                onChange={(e) => searchHandler(e)}
                className="form-select mb-3"
                aria-label="Default select example"
              >
                <option value="">Select</option>
                <option value="1">Last 1 Day</option>
                <option value="3">Last 3 Days</option>
                <option value="7">Last 7 Days</option>
                <option value="15">Last 15 Days</option>
                <option value="30">Last 30 Days</option>
                <option value="">More than 30 Days</option>
              </select>
            </div>

            {/* --------- Job Category/Industry -------- */}

            <div className="filter-box2">
              <p className="search-minihead">Job Category</p>

              <select
                name="industry"
                onChange={(e) => searchHandler(e)}
                className="form-select mb-3"
                aria-label="Default select example"
              >
                <option value={""}>Select Job Type </option>
                {qualificationsData?.map((value, index) => (
                  <option key={index + 1} value={`${value?.name}`}>
                    {value?.name}
                  </option>
                ))}
              </select>
            </div>

            {/* --------- Experince -------- */}

            <div className="filter-box2">
              <p className="search-minihead">Experience :</p>
              <form>
                <div className="row">
                  <div className="col">
                    <label
                      htmlFor="formGroupExampleInput"
                      className="label-head"
                    >
                      From (Years)
                    </label>
                    <select
                      name="exp_from"
                      onChange={(e) => searchHandler(e)}
                      className="form-select mb-3 two-in-one"
                      aria-label="Default select example"
                    >
                      <option>All</option>
                      {yearOption?.map((value) => (
                        <option key={value + 2} value={value}>
                          {`${value} Year`}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col">
                    <label
                      htmlFor="formGroupExampleInput"
                      className="label-head"
                    >
                      To (Years)
                    </label>
                    <select
                      name="exp_to"
                      onChange={(e) => searchHandler(e)}
                      className="form-select mb-3 two-in-one"
                      aria-label="Default select example"
                    >
                      <option>All</option>
                      {yearOption?.map((value) => (
                        <option key={value + 2} value={value}>
                          {`${value} Year`}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </form>
            </div>
            <hr />
            {/* --------- Salary -------- */}

            <div className="filter-box2">
              <p className="search-minihead">Salary INR (Lakhs Per Annum):</p>
              <form>
                <div className="row">
                  {/* From */}
                  <div className="col">
                    <label
                      htmlFor="formGroupExampleInput"
                      className="label-head"
                    >
                      Range From(Lacs)
                    </label>
                    <select
                      name="ctc_from_lacs"
                      onChange={(e) => searchHandler(e)}
                      className="form-select mb-3 two-in-one"
                      aria-label="Default select example"
                    >
                      <option>All</option>
                      <option value={""}>Select</option>
                      {lacOption?.map((value) => (
                        <option key={value + 2} value={value}>
                          {`${value} Lacs`}
                        </option>
                      ))}
                    </select>
                    <select
                      name="ctc_from_thousand"
                      onChange={(e) => searchHandler(e)}
                      className="form-select mb-3 two-in-one"
                      aria-label="Default select example"
                    >
                      <option>All</option>
                      <option value={""}>Select</option>
                      {thousands?.map((value) => (
                        <option key={value + 2} value={value}>
                          {`${value} Thousands`}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* To */}
                  <div className="col">
                    <label
                      htmlFor="formGroupExampleInput"
                      className="label-head"
                    >
                      To(Lacs)
                    </label>
                    <select
                      name="ctc_to_lacs"
                      onChange={(e) => searchHandler(e)}
                      className="form-select mb-3 two-in-one"
                      aria-label="Default select example"
                    >
                      <option>All</option>
                      <option value={""}>Select</option>
                      {lacOption?.map((value) => (
                        <option key={value + 2} value={value}>
                          {`${value} Lacs`}
                        </option>
                      ))}
                    </select>
                    <select
                      name="ctc_to_thousand"
                      onChange={(e) => searchHandler(e)}
                      className="form-select mb-3 two-in-one"
                      aria-label="Default select example"
                    >
                      <option>All</option>
                      <option value={""}>Select</option>
                      {thousands?.map((value) => (
                        <option key={value + 2} value={value}>
                          {`${value} Thousands`}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </form>
            </div>
            {/* --------- Location -------- */}

            <Location sendDataToParent={receiveDataFromChild} />

            {/* ------------ Search Button ----------- */}

            <button
              onClick={searchButtonHandler}
              className="border-0 w-100 rounded-pill mt-3"
            >
              Search
            </button>

            {/* ---------Job List Section-------- */}
          </div>
          {/* ----End of Sidebar --- */}
          {/* --- Job List */}
          <div className="col-md-9 border-start">
            {searchedJob?.docs?.filter(
              (value) =>
                value?.admin_approval === "approved" && value?.is_active
            ).length === 0 ? (
              <>
                <div className="h-50 d-flex justify-content-center align-items-center flex-column">
                  <img
                    src={SearchImg}
                    className=""
                    alt="img"
                    style={{ maxWidth: "300px", height: "300px" }}
                  />
                  <h5 className="mb-5">No results found</h5>
                </div>
              </>
            ) : (
              searchedJob?.docs?.map((value, index) => {
                if (value?.admin_approval === "approved" && value?.is_active) {
                  return (
                    <div
                      onClick={() => {
                        viewHandler(value._id, value?.views);
                      }}
                      className="joblist-card mt-2 m-2"
                      key={index + 2}
                    >
                      <h5 className="job-card">{value?.title || "N/A"}</h5>
                      <p className="company-name">
                        {value?.company_name ?? "N/A"}
                      </p>
                      <div className="job-details my-2 ms-2">
                        {value?.exp?.from !== null &&
                          value?.exp?.from !== undefined &&
                          value?.exp?.to !== null &&
                          value?.exp?.to !== undefined && (
                            <span>
                              <PiBagSimple style={{ fontSize: "19px" }} />{" "}
                              {`${value.exp.from} - ${value.exp.to} Years`}
                            </span>
                          )}
                        {value?.ctc?.from_lac !== null &&
                          value?.ctc?.from_lac !== undefined &&
                          value?.ctc?.to_lac !== null &&
                          value?.ctc?.to_lac !== undefined && (
                            <span>
                              <LiaRupeeSignSolid style={{ fontSize: "19px" }} />{" "}
                              {`${value.ctc.from_lac} - ${value.ctc.to_lac} Lacs`}
                            </span>
                          )}
                        {value?.cities?.length > 0 &&
                          value.cities[0] !== undefined && (
                            <span>
                              <GoLocation style={{ fontSize: "19px" }} />{" "}
                              {value.cities[0]}
                            </span>
                          )}
                        {value?.industry?.name !== null &&
                          value.industry.name !== undefined && (
                            <span>
                              <LiaIndustrySolid style={{ fontSize: "19px" }} />{" "}
                              {value.industry.name}
                            </span>
                          )}
                      </div>
                      {value?.qualification?.length > 0 &&
                        value.qualification[0]?.name !== undefined && (
                          <p className="ms-2">
                            <SlGraduation style={{ fontSize: "19px" }} />{" "}
                            {value.qualification[0].name}
                          </p>
                        )}
                      <p className="job-type">
                        Job Type: {value?.category ?? "N/A"}
                      </p>
                      <div className="card-buttom">
                        <div>
                          {value?.qualification.map((item) => (
                            <button className="btn btn-primary card-buttom1 ms-1">
                              {item?.name}
                            </button>
                          ))}
                        </div>

                        <button className="btn btn-primary card-buttom2">
                          {formatTimeAgo(new Date(value?.admin_approval_date))}
                        </button>
                      </div>
                    </div>
                  );
                }
              })
            )}
            {/* --- Pagination ----- */}
            {searchedJob?.pagination && (
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li
                    className={`page-item ${
                      searchedJob?.pagination?.prevPage ? "" : "disabled"
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() =>
                        handlePageChange(searchedJob?.pagination?.prevPage)
                      }
                    >
                      Previous
                    </button>
                  </li>
                  {Array.from(
                    { length: endPage - startPage + 1 },
                    (_, i) => startPage + i
                  ).map((page) => (
                    <li
                      key={page}
                      className={`page-item ${
                        searchedJob?.pagination?.page === page ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      searchedJob?.pagination?.nextPage ? "" : "disabled"
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() =>
                        handlePageChange(searchedJob?.pagination?.nextPage)
                      }
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            )}
            {/* ----- Pagination End */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ThirdPartyListing;
