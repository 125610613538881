export const yearOption = Array.from({ length: 30 }, (_, index) => index);
export const thousands = Array.from({ length: 100 }, (_, index) => index);
export const monthOption = Array.from({ length: 12 }, (_, index) => index);
export const lacOption = Array.from({ length: 100  }, (_, index) => index);
export const thousandOption = Array.from({ length: 100 }, (_, index) => index);

export const yearNoOption = Array.from(
  { length: 65 },
  (_, index) => 2075 - index
);
export var months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
