import React, { useEffect, useState } from "react";
import AdminNavbar from "./Common/AdminNavbar";
import AdminSideBar from "./Common/AdminSideBar";
import { FaUnlock } from "react-icons/fa6";
import { BsFillEyeFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  ExportUser,
  FetcJobSeekerhUsers,
  UserStatus,
} from "../../Redux/Slices/User";
import { BarLoader } from "react-spinners";
import toast from "react-hot-toast";

const JobSeekerList = () => {
  const { usersData } = useSelector((state) => state?.User);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  // Select

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(usersData.docs[0].data.map((user) => user._id));
    }
    setSelectAll(!selectAll);
  };

  const handleSelect = (user) => {
    if (selectedUsers.includes(user._id)) {
      setSelectedUsers(selectedUsers.filter((id) => id !== user._id));
    } else {
      setSelectedUsers([...selectedUsers, user._id]);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    let data = {
      search: e.target.value,
      role: "Jobseeker",
      page: 1,
    };
    dispatch(FetcJobSeekerhUsers(data));
  };

  const handleSort = (field) => {
    if (sortBy === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(field);
      setSortOrder("asc");
    }
  };
  const exportUser = async () => {
    try {
      setLoading(true);
      if (selectedUsers.length > 0) {
        await ExportUser("Jobseeker", selectedUsers);
        setLoading(false);
        toast.success("User exported successfully");
      } else {
        await ExportUser("Jobseeker");
        setLoading(false);
        toast.success("User exported successfully");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Failed to export user");
    }
  };

  // Block and Unblock
  const userStatushandler = async (id, value) => {
    const userClickedOK = await window.confirm(
      "Are you sure you want to proceed?"
    );

    if (userClickedOK) {
      let action = { action: value };
      await UserStatus(id, action);
      let data = {
        role: "Jobseeker",
      };
      dispatch(FetcJobSeekerhUsers(data));
    }
  };

  useEffect(() => {
    let data;
    let prevPage = localStorage.getItem("prevPage");
    if (prevPage) {
      data = JSON.parse(prevPage);
    } else {
      data = {
        role: "Jobseeker",
        page: 1,
      };
    }
    dispatch(FetcJobSeekerhUsers(data));
    document.title = "Jobseekers | Admin";
  }, [dispatch]);

  const handlePageChange = async (page) => {
    let data = {
      role: "Jobseeker",
      page: page,
      search: searchQuery,
    };
    dispatch(FetcJobSeekerhUsers(data));
    localStorage.setItem("prevPage", JSON.stringify(data));
  };

  // Calculate visible page range
  let startPage = 1;
  let endPage = 1;

  if (usersData?.pagination) {
    startPage = Math.max(1, usersData.pagination.page - 2);
    endPage = Math.min(
      usersData.pagination.totalPages,
      usersData.pagination.page + 2
    );

    // Adjust start and end page if close to beginning or end
    if (usersData.pagination.page <= 3) {
      endPage = Math.min(5, usersData.pagination.totalPages);
    } else if (
      usersData.pagination.page >=
      usersData.pagination.totalPages - 2
    ) {
      startPage = Math.max(1, usersData.pagination.totalPages - 4);
    }
  }

  return (
    <div>
      {/* Navbar */}
      <AdminNavbar />
      <div className="row m-0">
        <div className="col-md-2 p-0 nav-content">
          {/* Sidebar */}
          <AdminSideBar />
        </div>
        <div className="main-content col-md-10 p-0">
          {/* Main Content */}
          <h2 className="headline">Job Seekers List</h2>
          <div className="main-content-child">
            <div className="d-flex justify-content-between">
              <button onClick={exportUser} className="export-btn">
                {loading ? <BarLoader color="white" /> : "Export All Records"}
              </button>
              {/* Search Button */}
              <input
                type="search"
                id="form1"
                placeholder="Search"
                className="search-list"
                onChange={(e) => handleSearch(e)}
              />
            </div>
            {usersData?.docs && (
              <div className="main-panel">
                <div className="content-wrapper ">
                  <div className="col-md-12 detail-page_margin">
                    <div className="">
                      {usersData?.docs[0]?.data?.length > 0 ? (
                        <table
                          id="example"
                          className="table table-striped table-bordered datatable responsive"
                        >
                          <thead>
                            <tr>
                              <th>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="selectAllCheckbox"
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                  />
                                </div>
                              </th>
                              <th
                                className="headerSortDown"
                                onClick={() => handleSort("index")}
                              >
                                S.no{" "}
                              </th>
                              <th
                                className="headerSortUp"
                                onClick={() => handleSort("name")}
                              >
                                Name
                              </th>
                              <th onClick={() => handleSort("email")}>
                                Email{" "}
                              </th>
                              <th onClick={() => handleSort("phone")}>Phone</th>
                            </tr>
                          </thead>
                          <tbody>
                            {usersData?.docs[0]?.data?.map((user, index) => (
                              <tr key={index}>
                                <td>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value={user._id}
                                      checked={
                                        selectAll ||
                                        selectedUsers.includes(user._id)
                                      }
                                      onChange={() => handleSelect(user)}
                                    />
                                  </div>
                                </td>
                                <td>{index + 1}</td>
                                <td>{user?.name}</td>
                                <td>{user?.email}</td>
                                <td>{user?.mobile}</td>
                                <td className="text-nowrap">
                                  {user?.status === "Active" && (
                                    <button
                                      onClick={() =>
                                        userStatushandler(user?._id, "Inactive")
                                      }
                                      className="block-btn"
                                    >
                                      <FaUnlock className="me-1 fs-6" />
                                      Block
                                    </button>
                                  )}
                                  {user?.status === "Inactive" && (
                                    <button
                                      onClick={() =>
                                        userStatushandler(user?._id, "Active")
                                      }
                                      className="unblock-btn"
                                    >
                                      <FaUnlock className="me-1 fs-6" />
                                      Unblock
                                    </button>
                                  )}
                                  <button className="view-btn">
                                    <a
                                      href={`/Jobseeker/${user?._id}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="view-profile-link"
                                    >
                                      <BsFillEyeFill className="me-1 fs-6" />
                                      View Profile
                                    </a>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div>
                          <center>No Data Available In Table</center>
                          {/* <BarLoader color="#36d7b7" /> */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Pagination controls */}
            {/* --- Pagination ----- */}
            {usersData?.pagination && usersData?.docs?.length > 0 && (
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li
                    className={`page-item ${
                      usersData?.pagination?.prevPage ? "" : "disabled"
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() =>
                        handlePageChange(usersData?.pagination?.prevPage)
                      }
                    >
                      Previous
                    </button>
                  </li>
                  {Array.from(
                    { length: endPage - startPage + 1 },
                    (_, i) => startPage + i
                  ).map((page) => (
                    <li
                      key={page}
                      className={`page-item ${
                        usersData?.pagination?.page === page ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      usersData?.pagination?.nextPage ? "" : "disabled"
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() =>
                        handlePageChange(usersData?.pagination?.nextPage)
                      }
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            )}
            {/* ----- Pagination End */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobSeekerList;
