import React, { useEffect, useState } from "react";
import AdminNavbar from "./Common/AdminNavbar";
import AdminSideBar from "./Common/AdminSideBar";
import { useDispatch, useSelector } from "react-redux";
import hidePwdImg from '../../asset/new/icons/hide_passwd_icon.svg';
import showPwdImg from '../../asset/new/icons/show_passwd.svg';
import { GetUser, UpdateUser } from "../../Redux/Slices/User";

const AdminProfile = () => {
  const { userData } = useSelector((state) => state?.User);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealPwd2, setIsRevealPwd2] = useState(false);
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [error, setError] = useState("");
  let id = localStorage.getItem("id");
  const dispatch = useDispatch();
  //Password Reset Handle
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    let id = localStorage.getItem("id");
    if (newPass === confirmPass) {
      let newPassword = { password: newPass };
      await UpdateUser(id, newPassword);
      setError("");
    } else {
      setError("Password doesn't match");
    }
  };

  useEffect(() => {
    dispatch(GetUser(id));
    document.title = "Profile | Admin";
  }, []);

  return (
    <div>
      {/* ------- Navbar ------- */}
      <AdminNavbar />
      <div className="row m-0">
        <div className="col-12 col-md-2 nav-content p-0">
          {/* ------- Sidebar ------- */}
          <AdminSideBar />
        </div>
        <div className="main-content col-md-10 col-12 p-0">
          <h2 className="headline">My Profile</h2>
          {/* ------- Main Content  ------- */}
          <div className="main-content-child">
            <div className="row gx-3">
              <div className=" col-12  col-md-6 ">
                <div className="prfile-card  shadow-sm p-3 mb-5 bg-body-tertiary rounded">
                  <h4>Contact Information</h4>
                  <div className="card-profile-details">
                    <p>
                      <span className="prof-details">Name</span> :{" "}
                      {userData?.name}
                    </p>
                    <p>
                      <span className="prof-details">Email</span> :{" "}
                      {userData?.email}
                    </p>
                    <p>
                      <span className="prof-details">Phone</span> :{" "}
                      {userData?.mobile}
                    </p>
                  </div>

                  <div className="btn-profilecard">
                  </div>
                </div>
              </div>
              <div className="col-12  col-md-6 das-card">
                <div className="prfile-card shadow-sm p-3 mb-5 bg-body-tertiary rounded">
                  <h4>Change Password</h4>
                  <div className="card-profile-details">
                    <div className="form-outline pwd-container2">
                      <span style={{ color: "red" }}> {error} </span>

                      <input
                        onChange={(e) => setNewPass(e.target.value)}
                        type={isRevealPwd ? "text" : "password"}
                        id="form12"
                        className="form-control rounded-3"
                        placeholder="New Password"
                        style={{ height: "38px" }}
                      />
                        <img
                          title={isRevealPwd ? "Hide password" : "Show password"}
                          alt="passwd_img"
                          src={isRevealPwd ? hidePwdImg : showPwdImg}
                          onClick={() => setIsRevealPwd(prevState => !prevState)}
                         />
                      <br />
                      <input
                        onChange={(e) => setConfirmPass(e.target.value)}
                        type={isRevealPwd2 ? "text" : "password"}
                        id="form12"
                        className="form-control rounded-3"
                        placeholder="Confirm Password"
                        style={{ height: "38px" }}
                      />
                       <img
                          title={isRevealPwd2 ? "Hide password" : "Show password"}
                          alt="passwd_img"
                          src={isRevealPwd2 ? hidePwdImg : showPwdImg}
                          onClick={() => setIsRevealPwd2(prevState => !prevState)}
                         />
                      <br />
                    </div>
                  </div>

                  <div className="btn-profilecard">
                    <button
                      onClick={handlePasswordReset}
                      className="btn btn-primary btnpf1"
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProfile;
