import React, { useState } from 'react'
import {UpdateUser } from '../../../../Redux/Slices/User';
import hidePwdImg from '../../../../asset/new/icons/hide_passwd_icon.svg';
import showPwdImg from '../../../../asset/new/icons/show_passwd.svg';

const PasswordReset = () => {
    // const { userData } = useSelector((state) => state?.User);
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealPwd2, setIsRevealPwd2] = useState(false);
  const [error, setError] = useState("");

    //Password Reset Handle
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    let id = localStorage.getItem("id");
    if (newPass === confirmPass) {
      let newPassword = { password: newPass };
      await UpdateUser(id, newPassword);
      setError("");
    } else {
      setError("Password doesn't match");
    }
  };
  // useEffect(() => {
  //   dispatch(GetUser(id));
  // }, []);
  return (
    <div className="prfile-card my-4">
    <h3>Change Password</h3>
    <div className="prof-details">
      <div className="form-outline pwd-container2">
        <span style={{ color: "red" }}> {error} </span>
        <input
          onChange={(e) => setNewPass(e.target.value)}
          type={isRevealPwd ? "text" : "password"}
          id="form12"
          className="form-control rounded-3"
          placeholder="New Password"
          style={{ height: "38px" }}
        />
         <img
          title={isRevealPwd ? "Hide password" : "Show password"}
          alt="passwd_img"
          src={isRevealPwd ? hidePwdImg : showPwdImg}
          onClick={() => setIsRevealPwd(prevState => !prevState)}
          />
        <br />
        <input
          onChange={(e) => setConfirmPass(e.target.value)}
          type={isRevealPwd2 ? "text" : "password"}
          id="form11"
          className="form-control rounded-3"
          placeholder="Confirm Password"
          style={{ height: "38px" }}
        />
          <img
          title={isRevealPwd2 ? "Hide password" : "Show password"}
          alt="passwd_img"
          src={isRevealPwd2 ? hidePwdImg : showPwdImg}
          onClick={() => setIsRevealPwd2(prevState => !prevState)}
          />
        <br />
      </div>
    </div>

    <div className="btn-profilecard">
      <button
        onClick={handlePasswordReset}
        className="btn btn-primary btnpf1"
      >
        Change Password
      </button>
    </div>
  </div>
  )
}

export default PasswordReset