import React, { useEffect, useState } from "react";
import { GetUser, UpdateUser } from "../../../../Redux/Slices/User";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import avatar from "../../../../asset/new/images/avatar-1577909_1280.png";
import { BeatLoader } from "react-spinners";
import MaleAvatar from "../../../../asset/new/images/male.png";
import FemaleAvatar from "../../../../asset/new/images/female.png";
import { getProgressBarColor } from "../../../../Helper/CoreHelper";
const ProfileImg = ({ userData, completionPercentage, loading }) => {
  let id = localStorage.getItem("id");
  // const { userData, completionPercentage, loading } = useSelector(
  //   (state) => state?.User
  // );
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();

  //Image Handler
  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  // Handle image upload
  const openFileInput = () => {
    document.getElementById("fileInput").click();
  };
  const handleImageUpload = async () => {
    if (image) {
      const formData = new FormData();
      formData.append("profile_img", image);
      await UpdateUser(id, formData, "multipartformdata");
      dispatch(GetUser(id));
      setImage(null);
    } else {
      toast.error("Add Image");
    }
  };

  return (
    <>
      {/* Profile Img */}
      {userData?.profile_img ? (
        <img
          onClick={openFileInput}
          src={userData?.profile_img}
          alt="profile"
          className="profile-img img-thumbnail rounded-circle"
        />
      ) : (
        <img
          onClick={openFileInput}
          src={
            userData?.gender === "M" || userData?.gender === "Male"
              ? MaleAvatar
              : FemaleAvatar
          }
          alt="profile"
          className="profile-img img-thumbnail rounded-circle"
        />
      )}

      {/* Hidden file input */}
      <input
        type="file"
        id="fileInput"
        style={{ display: "none" }}
        accept="image/*"
        onChange={handleFileChange}
      />
      {/* Button to trigger image upload */}
      {image && (
        <button
          onClick={handleImageUpload}
          className="mt-4 w-25 mx-auto upload-btn"
        >
          Upload Image
        </button>
      )}
      {loading === true && (
        <center>
          <BeatLoader className="mt-3" color="#36d7b7" />
        </center>
      )}

      <center>
        <h3 className="card-head-line mt-3">{userData?.name}</h3>
      </center>
      {/* Profile Completion bar */}
      <span>Profile Completion</span>
      <div
        className="progress"
        role="progressbar"
        aria-label="Example with label"
        aria-valuenow={25}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        <div
          className={`progress-bar ${getProgressBarColor(
            completionPercentage
          )}`}
          style={{ width: `${completionPercentage}%` }}
        >
          {`${completionPercentage}%`}
        </div>
      </div>
    </>
  );
};

export default ProfileImg;
