import React, { useEffect, useState } from "react";
import AdminSideBar from "./Common/AdminSideBar";
import AdminNavbar from "./Common/AdminNavbar";
import { BsFillEyeFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { GetToSubFolder } from "../../Redux/Slices/Folder";
import { IoIosArrowBack } from "react-icons/io";
const AdminSubFolder = () => {
  const navigate = useNavigate();
  const [entriesToShow, setEntriesToShow] = useState(10);

  const { id } = useParams();
  const [subfolderdetails, setSubfolderdetails] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handlesubfolder = async () => {
    let res = await GetToSubFolder(id);
    setSubfolderdetails(res?.data);
  };

  const handleEntriesChange = (e) => {
    setEntriesToShow(Number(e.target.value));
  };
  useEffect(() => {
    handlesubfolder();
    document.title = "Sub-Folder | Admin";
  }, []);
  return (
    <div>
      {/* ------- Navbar ------- */}
      <AdminNavbar />
      <div className="row m-0">
        <div className="col-6 col-md-2 nav-content p-0">
          {/* ------- Sidebar ------- */}
          <AdminSideBar />
        </div>
        <div className="main-content col-md-10 p-0">
          {/* ------- Main Content  ------- */}
          <div className="main-content-child">
            <p onClick={() => navigate(-1)}>
              <IoIosArrowBack /> Back
            </p>

            <div className="">
              <div
                style={{ backgroundColor: "white", padding: "14px" }}
                className="table-responsive px-2"
              >
                <div
                  id="example_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div className="dataTables_length" id="example_length">
                    <label>
                      Show{" "}
                      <select
                        name="example_length"
                        value={entriesToShow}
                        onChange={handleEntriesChange}
                        aria-controls="example"
                        className
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>{" "}
                      entries
                    </label>
                    <input
                      className="search-table"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    ></input>
                  </div>
                  <br />
                  <table
                    id="example"
                    className="table dataTable no-footer"
                    cellSpacing={0}
                    style={{ width: "100%" }}
                    aria-describedby="example_info"
                  >
                    <thead className="table-headline">
                      <tr>
                        <th
                          className="th_text sorting sorting_asc"
                          tabIndex={0}
                          aria-controls="example"
                          rowSpan={1}
                          colSpan={1}
                          aria-sort="ascending"
                          aria-label="S.no: activate to sort column descending"
                          style={{ width: 209 }}
                        >
                          Name
                        </th>
                        <th
                          className="th_text sorting"
                          tabIndex={0}
                          aria-controls="example"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Folder: activate to sort column ascending"
                          style={{ width: 253 }}
                        >
                          Email
                        </th>
                        <th
                          className="th_text sorting"
                          tabIndex={0}
                          aria-controls="example"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Go To: activate to sort column ascending"
                          style={{ width: 315 }}
                        >
                          Phone
                        </th>
                        <th
                          className="th_text sorting"
                          tabIndex={0}
                          aria-controls="example"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Go To: activate to sort column ascending"
                          style={{ width: 315 }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "white !important" }}>
                      {subfolderdetails.length > 0 ? (
                        subfolderdetails
                          .slice(0, entriesToShow)
                          .filter(
                            (item) =>
                              item?.user_id?.name
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase()) ||
                              item?.user_id?.email
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                          )
                          .map((item) => (
                            <tr
                              style={{ backgroundColor: "white !important" }}
                              className="odd"
                            >
                              <td className="tb_text sorting_1">
                                {item?.user_id?.name}
                              </td>
                              <td className="tb_text">
                                {item?.user_id?.email}
                              </td>
                              <td className="tb_text">
                                {item?.user_id?.mobile}
                              </td>
                              <td className="tb_text">
                                <button
                                  onClick={() =>
                                    navigate(
                                      `/Jobseeker/${item?.user_id?._id}`
                                    )
                                  }
                                  className="view-btn"
                                >
                                  <BsFillEyeFill className="me-1 fs-6" />
                                  View Profile
                                </button>
                              </td>
                            </tr>
                          ))
                      ) : (
                        <td
                          valign="top"
                          colspan="4"
                          className="dataTables_empty p-2"
                        >
                          {" "}
                          <center>No Details</center>
                        </td>
                      )}
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSubFolder;
