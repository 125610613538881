import React, { useEffect, useState } from "react";
import Sidebar from "../../Common/Sidebar";
import { FiEdit } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { GetUser, UpdateUser } from "../../../Redux/Slices/User";
import { useNavigate } from "react-router-dom";
import hidePwdImg from '../../../asset/new/icons/hide_passwd_icon.svg';
import showPwdImg from '../../../asset/new/icons/show_passwd.svg';


const EmployerProfile = () => {
  const { userData } = useSelector((state) => state?.User);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealPwd2, setIsRevealPwd2] = useState(false);
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let id = localStorage.getItem("id");

  //Password Reset Handle
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    let id = localStorage.getItem("id");
    if (newPass === confirmPass) {
      let newPassword = { password: newPass };
      await UpdateUser(id, newPassword);
      setError("");
    } else {
      setError("Password doesn't match");
    }
  };
  useEffect(() => {
    dispatch(GetUser(id));
    document.title = "Profile | CAJobPortal";
  }, []);
  return (
    <div className="m-5">
      <div className="row ">
        {/* ------------ SideBar ------------- */}
        <Sidebar />

        <div className="col-md-9">
          <h1>My Profile</h1>

          {/* -------- 2 Card ------- */}
          <div className="d-flex justify-content-around ">
            <div className="p-1 w-50 das-card mt-3">
              <div className="prfile-card my-4">
                <h3>Contact Information</h3>
                <div className="">
                  <p>
                    <span className="prof-details">Name</span> :{" "}
                    {userData?.name}
                  </p>
                  <p>
                    <span className="prof-details">Designation</span> :{" "}
                    {userData?.experience[0]?.designation}
                  </p>
                  <p>
                    <span className="prof-details">Company Name</span> :{" "}
                    {userData?.experience[0]?.company_name}
                  </p>
                  <p>
                    <span className="prof-details">Company Website</span> :{" "}
                    {userData?.experience[0]?.company_web}
                  </p>
                  <p>
                    <span className="prof-details">Email</span> :{" "}
                    {userData?.email}
                  </p>
                  <p>
                    <span className="prof-details">Phone</span> :{" "}
                    {userData?.mobile}
                  </p>
                  <p>
                    <span className="prof-details">GST No</span> :{" "}
                    {userData?.gst}
                  </p>
                </div>

                <div className="btn-profilecard">
                  <button
                    onClick={() => navigate("/employer/edit/profile")}
                    className="btn btn-primary btnpf1"
                  >
                    <span>
                      <FiEdit style={{ fontSize: "20px" }} />
                    </span>
                    Edit
                  </button>
                </div>
              </div>
            </div>
            <div className="p-1  w-50 das-card mt-3">
              <div className="prfile-card my-4">
                <h3>Change Password</h3>
                <div className="prof-details">
                  <div className="form-outline pwd-container2">
                    <span style={{ color: "red" }}> {error} </span>
                    <input
                      onChange={(e) => setNewPass(e.target.value)}
                      type={isRevealPwd ? "text" : "password"}
                      id="form12"
                      className="form-control rounded-3"
                      placeholder="New Password"
                      style={{ height: "38px" }}
                    />
                      <img
                      title={isRevealPwd ? "Hide password" : "Show password"}
                      alt="passwd_img"
                      src={isRevealPwd ? hidePwdImg : showPwdImg}
                      onClick={() => setIsRevealPwd(prevState => !prevState)}
                      />
                    <br />
                    <input
                      onChange={(e) => setConfirmPass(e.target.value)}
                      type={isRevealPwd2 ? "text" : "password"}
                      id="form11"
                      className="form-control rounded-3"
                      placeholder="Confirm Password"
                      style={{ height: "38px" }}
                    />
                    <img
                      title={isRevealPwd2 ? "Hide password" : "Show password"}
                      alt="passwd_img"
                      src={isRevealPwd2 ? hidePwdImg : showPwdImg}
                      onClick={() => setIsRevealPwd2(prevState => !prevState)}
                      />
                    <br />
                  </div>
                </div>

                <div className="btn-profilecard">
                  <button
                    onClick={handlePasswordReset}
                    className="btn btn-primary btnpf1"
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
};

export default EmployerProfile;
