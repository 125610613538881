import React, { useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { monthOption, yearNoOption } from "../../../../Helper/Option";
import {
  DeleteQualification,
  GetUser,
  UpdateUser,
} from "../../../../Redux/Slices/User";
import { FetchDegrees } from "../../../../Redux/Slices/Degree";
import { MdDeleteOutline } from "react-icons/md";
import { GoPlus } from "react-icons/go";

const ProfessionalQualification = ({userData}) => {
  let id = localStorage.getItem("id");
  // const { userData } = useSelector((state) => state?.User);
  const { degreesData } = useSelector((state) => state?.Degrees);
  const [editData, setEditData] = useState(userData);
  const [qualification, setQualification] = useState([]);
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  //----Edit ----
  const handleEdit = async (id) => {
    setEditData(userData);
  };
  //----Delete ----

  const handleDelete = async (qualId) => {
    let userId = await userData?._id;
    await DeleteQualification(userId, qualId, "application/json");
    dispatch(GetUser(id));
  };

  // ------------- OnChange Validation --------------

  let name, value;
  const postUserData = (e) => {
    name = e.target.name;
    value = e.target.value;
    setEditData((prevData) => ({ ...prevData, [name]: value }));
    if (name === "marks") {
      if (value.length === 0) {
        setError({ ...error, marks: "Marks is Required" });
        setEditData({ ...editData, marks: "" });
      } else {
        setError({ ...error, marks: "" });
        setEditData({ ...editData, marks: value });
      }
    }
    if (name === "attempt") {
      if (value.length === 0) {
        setError({ ...error, attempt: "Attempt is Required" });
        setEditData({ ...editData, attempt: "" });
      } else {
        setError({ ...error, attempt: "" });
        setEditData({ ...editData, attempt: value });
      }
    }
  };
  // ------------- OnChange Validation --------------

  const postEditUserData = (e) => {
    const { name, value } = e.target;

    setQualification((prevData) => ({ ...prevData, [name]: value }));

    if (name === "marks") {
      if (value.length === 0) {
        setError((prevError) => ({ ...prevError, marks: "Marks is Required" }));
      } else {
        setError((prevError) => ({ ...prevError, marks: "" }));
      }
      setQualification((prevData) => ({ ...prevData, marks: value }));
    }

    if (name === "attempt") {
      if (value.length === 0) {
        setError((prevError) => ({
          ...prevError,
          attempt: "Attempt is Required",
        }));
      } else {
        setError((prevError) => ({ ...prevError, attempts: "" }));
      }
      setEditData((prevData) => ({ ...prevData, attempts: value }));
    }
  };

  //------------ Edit One Qualification ---------

  const handleEditQualification = async (value) => {
    setQualification(value);
  };

  //  -------------- Update  --------------

  const handleUpdate = async (id) => {
    let data = {
      degree: editData?.degree,
      grading_system: editData?.grading_system,
      marks: editData?.marks,
      attempts: editData?.attempt,
      term_of_passing: {
        year: parseInt(editData?.year),
        month: parseInt(editData?.month),
      },
    };
    let updatedQualification = [...editData?.prof_qualification, data];
    let update = { prof_qualification: updatedQualification };
    await UpdateUser(id, update, "application/json");
    dispatch(GetUser(id));
    // setEditData("");
  };
  //  --------------New  Update  --------------
  const handleNewUpdate = async (id) => {
  let userId = localStorage.getItem("id");

    let data = {
      degree: qualification?.degree,
      grading_system: qualification?.grading_system,
      marks: qualification?.marks,
      attempts: qualification?.attempts,
      term_of_passing: {
        year: parseInt(qualification?.year || qualification?.term_of_passing?.year),
        month: parseInt(qualification?.month ||qualification?.term_of_passing?.month),
      },
    };
    let updatedQualifications = [
      ...userData?.prof_qualification?.filter((qual) => qual._id !== id),
      data
    ];
    let update = { prof_qualification: updatedQualifications };
    await UpdateUser(userId, update, "application/json");
    dispatch(GetUser(userId));
    setEditData("");
  };

  useEffect(() => {
    dispatch(FetchDegrees());
  }, [dispatch]);
  return (
    <>
      <div className="prfile-card my-3">
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-center">
            <h3 className="card-head-line">Professional Qualification</h3>
          </div>
          <div>
            <button
              onClick={(e) => handleEdit(id)}
              data-bs-toggle="modal"
              data-bs-target="#editProfQualifmodal"
              className="btnpf4"
            >
              <GoPlus className="fs-2" />
            </button>
          </div>
        </div>
        {/*------ Prof Details ---- */}

        {userData?.prof_qualification?.map((value, index) => (
          <div className=" mt-2 border-bottom" key={`${index}`}>
            <p>
              <span className="prof-details">Degree</span>:{" "}
              {value?.degree?.name}{" "}
              <span className="float-end">
              <CiEdit
                className="profile-delete-btn"
                onClick={(e) =>
                  handleEditQualification(userData.prof_qualification[index])
                }
                data-bs-toggle="modal"
                data-bs-target="#editQualification"
              />
              <MdDeleteOutline
                className="profile-delete-btn"
                onClick={() => handleDelete(value?._id)}
              />
              </span>
              
            </p>
            <p>
              <span className="prof-details">Term of Passing</span> :{" "}
              {value?.term_of_passing?.year}-{value?.term_of_passing?.month}
            </p>
            <p>
              <span className="prof-details">Grade Score</span> : {value?.marks}
            </p>
            {(value?.attempts || value?.no_of_attempts) && (
              <p>
                <span className="prof-details">No. of Attempts</span> :{" "}
                {value?.attempts || value?.no_of_attempts}
              </p>
            )}
          </div>
        ))}

        {/* Edit Profile Modal */}
        <div
          className="modal fade modal-lg"
          id="editProfQualifmodal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Professional Qualification
                </h5>
                {/* Close Modal */}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <form>
                  <div className="">
                    {/* Primary Degree */}
                    <label htmlFor="recipient-name" className="col-form-label">
                      {error.degree ? (
                        <span style={{ color: "red" }}>{error?.name}</span>
                      ) : (
                        "Primary Degree :"
                      )}
                    </label>
                    <select
                      name="degree"
                      onChange={(e) => postUserData(e)}
                      className="form-select me-3"
                      aria-label="Default select example"
                    >
                      <option value={""}>Select Degree</option>

                      {degreesData?.map((value, index) => (
                        <option key={index + 1} value={value?._id}>
                          {`${value?.name}`}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* Term of Passing */}
                  <label htmlFor="recipient-name" className="col-form-label">
                    {error.year ? (
                      <span style={{ color: "red" }}> {error.year}</span>
                    ) : (
                      "Term of Passing:"
                    )}
                  </label>
                  <div className="d-flex align-items-center">
                    {/* ---- Year ---- */}
                    <select
                      name="year"
                      onChange={(e) => postUserData(e)}
                      className="form-select me-3"
                      aria-label="Default select example"
                    >
                      <option value={""}>Select Year</option>
                      {yearNoOption?.map((value) => (
                        <option key={value + 2} value={value}>
                          {`${value}`}
                        </option>
                      ))}
                    </select>
                    {/* ----- Month ----- */}
                    <select
                      name="month"
                      onChange={(e) => postUserData(e)}
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option value={""}>Select Month</option>
                      {monthOption?.map((value) => (
                        <option key={value} value={value}>
                          {`${value}`}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Grading System* */}
                  <label htmlFor="recipient-name" className="col-form-label">
                    {error.grade ? (
                      <span style={{ color: "red" }}> {error.grade}</span>
                    ) : (
                      "Grading System*:"
                    )}
                  </label>
                  <div className="d-flex align-items-center">
                    {/* Grading System */}
                    <select
                      name="grading_system"
                      className="form-select me-3"
                      aria-label="Default select example"
                      onChange={(e) => postUserData(e)}
                    >
                      <option value={""}>Select</option>
                      <option value={"Grade"}>Scale 10 Grading System</option>
                      <option value={"Percentage"}>
                        % Marks of 100 Maximum
                      </option>
                    </select>
                  </div>
                  {/* Marks */}
                  <label htmlFor="recipient-name" className="col-form-label">
                    {error.marks ? (
                      <span style={{ color: "red" }}> {error.marks}</span>
                    ) : (
                      "Marks:"
                    )}
                  </label>
                  <input
                    name="marks"
                    type="text"
                    className="form-control h-25"
                    placeholder="Marks"
                    onChange={(e) => postUserData(e)}
                  />
                  {/* Attempt */}
                  <label htmlFor="recipient-name" className="col-form-label">
                    {error.attempt ? (
                      <span style={{ color: "red" }}> {error.attempt}</span>
                    ) : (
                      "Attempt:"
                    )}
                  </label>
                  <input
                    name="attempt"
                    type="text"
                    className="form-control h-25"
                    placeholder="No of Attempt"
                    onChange={(e) => postUserData(e)}
                  />
                </form>
              </div>
              <div className="modal-footer">
                <button
                  onClick={(e) => handleUpdate(editData?._id)}
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Edit Prof Qualification Modal */}
        <div
          className="modal fade modal-lg"
          id="editQualification"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Professional Qualification
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <form>
                  {/* Primary Degree */}
                  <div>
                    <label htmlFor="recipient-name" className="col-form-label">
                      {error.degree ? (
                        <span style={{ color: "red" }}>{error?.name}</span>
                      ) : (
                        "Primary Degree :"
                      )}
                    </label>
                    <select
                      name="degree"
                      onChange={(e) => postEditUserData(e)}
                      className="form-select me-3"
                      aria-label="Default select example"
                      value={qualification?.degree?._id || ""}
                    >
                      <option value="">Select Degree</option>
                      {degreesData?.map((value, index) => (
                        <option key={index} value={value?._id}>
                          {value?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* Term of Passing */}
                  <label htmlFor="recipient-name" className="col-form-label">
                    {error.year ? (
                      <span style={{ color: "red" }}>{error.year}</span>
                    ) : (
                      "Term of Passing:"
                    )}
                  </label>
                  <div className="d-flex align-items-center">
                    {/* Year */}
                    <select
                      name="year"
                      onChange={(e) => postEditUserData(e)}
                      className="form-select me-3"
                      aria-label="Default select example"
                      value={qualification?.year || qualification?.term_of_passing?.year}
                    >
                      <option value="">Select Year</option>
                      {yearNoOption?.map((value, index) => (
                        <option key={index} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                    {/* Month */}
                    <select
                      name="month"
                      onChange={(e) => postEditUserData(e)}
                      className="form-select"
                      aria-label="Default select example"
                      value={qualification?.month||qualification?.term_of_passing?.month}
                    >
                      <option value="">Select Month</option>
                      {monthOption?.map((value, index) => (
                        <option key={index} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* Grading System */}
                  <label htmlFor="recipient-name" className="col-form-label">
                    {error.grade ? (
                      <span style={{ color: "red" }}>{error.grade}</span>
                    ) : (
                      "Grading System*:"
                    )}
                  </label>
                  <div className="d-flex align-items-center">
                    <select
                      name="grading_system"
                      className="form-select me-3"
                      aria-label="Default select example"
                      value={qualification?.grading_system || ""}
                      onChange={(e) => postEditUserData(e)}
                    >
                      <option value="">Select</option>
                      <option value={qualification?.grading_system}>
                        {qualification?.grading_system}
                      </option>
                      <option value="Grade">Scale 10 Grading System</option>
                      <option value="Percentage">% Marks of 100 Maximum</option>
                    </select>
                  </div>
                  {/* Marks */}
                  <label htmlFor="recipient-name" className="col-form-label">
                    {error.marks ? (
                      <span style={{ color: "red" }}>{error.marks}</span>
                    ) : (
                      "Marks:"
                    )}
                  </label>
                  <input
                    name="marks"
                    type="text"
                    className="form-control h-25"
                    placeholder="Marks"
                    value={qualification?.marks || ""}
                    onChange={(e) => postEditUserData(e)}
                  />
                  {/* Attempt */}
                  <label htmlFor="recipient-name" className="col-form-label">
                    {error.attempt ? (
                      <span style={{ color: "red" }}>{error.attempt}</span>
                    ) : (
                      "Attempt:"
                    )}
                  </label>
                  <input
                    name="attempts"
                    type="text"
                    className="form-control h-25"
                    placeholder="No of Attempt"
                    value={qualification?.attempts || ""}
                    onChange={(e) => postEditUserData(e)}
                  />
                </form>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => handleNewUpdate(qualification?._id)}
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfessionalQualification;
