import React, { useCallback, useEffect, useState } from "react";
import AdminSideBar from "./Common/AdminSideBar";
import AdminNavbar from "./Common/AdminNavbar";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { JobStatus, getAllJobs } from "../../Redux/Slices/JobPost";
import { formatDate } from "../../Helper/FromatTime";
const AdminPostedJobs = () => {
  const { allJobs } = useSelector((state) => state?.Jobs);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("approved");
  const [jobDetails, setJobDetails] = useState("");
  const [jobId, setJobID] = useState("");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleSearch = (e) => {
    let data = {
      admin_approval: activeTab,
      page:1,
      search:e.target.value
    };
    
     dispatch(getAllJobs(data));
    
  };

  // Block and Unblock
  const userStatushandler = async (id, data) => {
    setJobID(id);
    setJobDetails(data);
  };

  const submitJobDetails = async () => {
      let action = { action: jobDetails };
      await JobStatus(jobId, action);
      dispatch(getAllJobs());
  };

  useEffect(() => {
    let data = {
      admin_approval: activeTab,
    };
    dispatch(getAllJobs(data));
    document.title = "Posted Jobs | Admin";
  }, []);

  // Jobs According to Tab
  const fetchData = useCallback(async () => {
    let data = {
      admin_approval: activeTab,
      page:1
    };
    dispatch(getAllJobs(data));
  }, [activeTab]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // PAgination

  const handlePageChange = async (page) => {
    let data = {
      admin_approval: activeTab,
      page: page,
    };
    dispatch(getAllJobs(data));
  };

  // Calculate visible page range
  let startPage = 1;
  let endPage = 1;

  if (allJobs?.pagination) {
    startPage = Math.max(1, allJobs.pagination.page - 2);
    endPage = Math.min(
      allJobs.pagination.totalPages,
      allJobs.pagination.page + 2
    );

    // Adjust start and end page if close to beginning or end
    if (allJobs.pagination.page <= 3) {
      endPage = Math.min(5, allJobs.pagination.totalPages);
    } else if (allJobs.pagination.page >= allJobs.pagination.totalPages - 2) {
      startPage = Math.max(1, allJobs.pagination.totalPages - 4);
    }
  }

  return (
    <div>
      {/* ------- Navbar ------- */}
      <AdminNavbar />
      <div className="row m-0">
        <div className="col-md-2 p-0 nav-content">
          {/* ------- Sidebar ------- */}
          <AdminSideBar />
        </div>
        <div className="main-content col-md-10 p-0">
          {/* ------- Main Content  ------- */}
          <h2 className="headline">Posted Jobs</h2>
          <div className="main-content-child">
            <div className="d-flex justify-content-between">
              <button
                onClick={() => navigate("/admin/postjob")}
                type="button"
                className="add-btn"
              >
                Post Job
              </button>
              {/* --- Search Input --- */}

              <input
                type="search"
                id="form1"
                placeholder="Search"
                className="search-list"
                // value={searchQuery}
                onChange={(e) => handleSearch(e)}
              />
            </div>
            <div className="main-panel">
              <div
                className="container p-1 pt-0"
                style={{ backgroundColor: "white" }}
              >
                <nav>
                  <div
                    className="nav nav-tabs nav-jobs nav-fill"
                    id="nav-tab"
                    role="tablist"
                  >
                    <Link
                      className={`nav-item nav-link ${
                        activeTab === "approved" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("approved")}
                    >
                      <b>Approved</b>
                    </Link>
                    <Link
                      className={`nav-item nav-link ${
                        activeTab === "rejected" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("rejected")}
                    >
                      <b>Rejected</b>
                    </Link>
                    <Link
                      className={`nav-item nav-link ${
                        activeTab === "pending" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("pending")}
                    >
                      <b>Pending</b>
                    </Link>
                  </div>
                </nav>
                <div
                  className="tab-content py-3 px-3 px-sm-0"
                  id="nav-tabContent"
                >
                  {/* Use activeTab state to conditionally render content */}
                  {activeTab === "approved" && (
                    <div
                      className="tab-pane fade show active"
                      id="nav-approve"
                      role="tabpanel"
                    >
                      <table
                        id="approve"
                        className="display responsive table table-striped table-bordered dt-responsive nowrap"
                        cellSpacing={0}
                        style={{ width: "100%", fontSize: "12px" }}
                      >
                        <thead>
                          <tr>
                            <th className="text-nowrap">Job ID</th>
                            <th>Job Title</th>
                            <th>Posted By</th>
                            <th>Posted date</th>
                            <th>Industry</th>
                            <th>Apply Through</th>
                            <th>App.Cand</th>
                            <th>Views</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {/* Add rows for approved jobs here */}
                        <tbody>
                          {allJobs?.docs ? (
                            allJobs?.docs.map((value, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{value?.title}</td>
                                  <td>{value?.created_by?.name || "N/A"}</td>
                                  <td>{formatDate(value?.posted_date)}</td>
                                  <td>{value?.industry?.name || "N/A"}</td>
                                  <td>
                                    {value?.apply_through === "Form" && value?.form_link && (
                                      <Link to={value?.form_link} target="_blank">
                                        {value?.apply_through}
                                      </Link>
                                    )}
                                    {
                                     value?.apply_through === "Website" && value?.website_link && (
                                        <Link to={value?.website_link} target="_blank">
                                          {value?.apply_through}
                                        </Link>
                                      )
                                    }
                                    {
                                      value?.apply_through === "Resume" && (
                                        value?.apply_through
                                      )
                                    }
                                   
                                  </td>
                                  <td>{value?.applications?.length}</td>
                                  <td>{value?.views || "N/A"}</td>

                                  <td className="text-nowrap">
                                    <button
                                      onClick={() =>
                                        navigate(
                                          `/admin/reviewJob/${value?._id}`
                                        )
                                      }
                                      className="unblock-btn"
                                    >
                                      View Job Detail
                                    </button>
                                    <button
                                      onClick={() =>
                                        navigate(
                                          `/admin/job-applicants/${value?._id}`
                                        )
                                      }
                                      className="view-app-btn"
                                    >
                                      View Applicants
                                    </button>
                                    {activeTab === "approved" && (
                                      <button
                                        onClick={() =>
                                          navigate(
                                            `/admin/editjob/${value?._id}`
                                          )
                                        }
                                        className="edit-btn"
                                      >
                                        Edit
                                      </button>
                                    )}
                                    {activeTab === "approved" &&
                                      value?.is_active === true && (
                                        <button
                                          onClick={() =>
                                            userStatushandler(
                                              value?._id,
                                              "false"
                                            )
                                          }
                                          data-bs-toggle="modal"
                                          data-bs-target="#deactivateJobModal"
                                          className="block-btn"
                                        >
                                          Deactivate
                                        </button>
                                      )}
                                    {activeTab === "approved" &&
                                      value?.is_active === false && (
                                        <button
                                          onClick={() =>
                                            userStatushandler(
                                              value?._id,
                                              "true"
                                            )
                                          }
                                          data-bs-toggle="modal"
                                          data-bs-target="#deactivateJobModal"
                                          className="approve-btn"
                                        >
                                          Activate
                                        </button>
                                      )}
                                  </td>
                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td colSpan="7">No Approveded Job</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {activeTab === "rejected" && (
                    <div
                      className="tab-pane fade show active"
                      id="nav-approve"
                      role="tabpanel"
                    >
                      <table
                        id="reject"
                        className="display responsive table table-striped table-bordered dt-responsive nowrap"
                        cellSpacing={0}
                        style={{ width: "100%", fontSize: "12px" }}
                      >
                        <thead>
                          <tr>
                            <th>Job ID</th>
                            <th>Job Title</th>
                            <th>Posted By</th>
                            <th>Posted date</th>
                            <th>Industry</th>
                            <th>Reason</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {/* rows for rejected jobs here */}
                        <tbody>
                          {allJobs?.docs?.some(
                            (item) => item?.admin_approval === "rejected"
                          ) ? (
                            allJobs?.docs
                              .filter(
                                (item) => item?.admin_approval === "rejected"
                              )
                              .map((value, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{value?.title}</td>
                                  <td>{value?.created_by?.name || "N/A"}</td>
                                  <td>{formatDate(value?.posted_date)}</td>
                                  <td>{value?.industry?.name || "N/A"}</td>
                                  <td>{value?.remark || "N/A"}</td>
                                  <td>
                                    <button
                                      onClick={() =>
                                        navigate(
                                          `/admin/reviewJob/${value?._id}`
                                        )
                                      }
                                      className="unblock-btn"
                                    >
                                      View Job Detail
                                    </button>
                                  </td>
                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td colSpan="7">No Approveded Job</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {activeTab === "pending" && (
                    <div
                      className="tab-pane fade show active"
                      id="nav-approve"
                      role="tabpanel"
                    >
                      <table
                        id="pending"
                        className="display responsive table table-striped table-bordered dt-responsive nowrap"
                        cellSpacing={0}
                        style={{ width: "100%", fontSize: "12px" }}
                      >
                        <thead>
                          <tr>
                            <th>Job ID</th>
                            <th>Job Title</th>
                            <th>Posted By</th>
                            <th>Posted date</th>
                            <th>Industry</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {/* Rows for pending jobs here */}
                        <tbody>
                          {allJobs?.docs?.some(
                            (item) => item?.admin_approval === "pending"
                          ) ? (
                            allJobs?.docs
                              .filter(
                                (item) => item?.admin_approval === "pending"
                              )
                              .map((value, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{value?.title}</td>
                                  <td>{value?.created_by?.name || "N/A"}</td>
                                  <td>{formatDate(value?.posted_date)}</td>
                                  <td>{value?.industry?.name || "N/A"}</td>
                                  <td className="text-nowrap">
                                    <button
                                      onClick={() =>
                                        navigate(
                                          `/admin/reviewJob/${value?._id}`
                                        )
                                      }
                                      className="unblock-btn"
                                    >
                                      View Job Detail
                                    </button>
                                  </td>
                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td colSpan="7">No Pending Job Requests</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {/* --- Pagination ----- */}
                  {allJobs?.pagination && (
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-center">
                        <li
                          className={`page-item ${
                            allJobs?.pagination?.prevPage ? "" : "disabled"
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() =>
                              handlePageChange(allJobs?.pagination?.prevPage)
                            }
                          >
                            Previous
                          </button>
                        </li>
                        {Array.from({ length: endPage - startPage + 1 },(_, i) => startPage + i).map((page) => (
                          <li key={page} className={`page-item ${allJobs?.pagination?.page === page ? "active" : ""}`}>
                            <button className="page-link" onClick={() => handlePageChange(page)}>
                              {page}
                            </button>
                          </li>
                        ))}
                        <li
                          className={`page-item ${
                            allJobs?.pagination?.nextPage ? "" : "disabled"
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() =>
                              handlePageChange(allJobs?.pagination?.nextPage)
                            }
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </nav>
                  )}
                  {/* ----- Pagination End */}
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="deactivateJobModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-confirm">
              <div className="modal-content">
               
                <div class="modal-header">
                  <h4 class="modal-title">Are you sure you want to proceed ?</h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-footer">
                  <button
                    
                    type="button"
                    className="btn btn-info"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                   
                  >
                    Cancel
                  </button>
                  <button
                   onClick={submitJobDetails}
                    type="button"
                    className="btn btn-danger"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPostedJobs;
