import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance, config } from "../../Axios/AxiosInstance";
import toast from "react-hot-toast";

//Token
let header = config;

// http://localhost:3000/api/jobtypes/all
export const FetchJobTypes = createAsyncThunk(
  "jobtypes/all/fetch",
  async () => {
    try {
      let res = await axiosInstance.get("jobtypes/all");
      return res?.data;
    } catch (error) {
      return error.message
    }
  }
);

// http://localhost:3000/api/jobtypes
export const AddJobType = async (data) => {
  try {
    let res = await axiosInstance.post("jobtypes", data, header);
    toast.success(res?.data?.message);
    return res?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error.message;
  }
};

// http://localhost:3000/api/jobtypes/:id
export const EditJobType = async (id) => {
  try {
    let data = await axiosInstance.get(`jobtypes/${id}`, header);
    return data?.data;
  } catch (error) {
    return error.message;
  }
};

// http://localhost:3000/api/jobtypes/:id
export const UpdateJobType = async (data, id) => {
  try {
    const res = await axiosInstance.put(`jobtypes/${id}`, data, header);
    toast.success(res?.data?.message);
    return res?.data;
  } catch (error) {
    if (
      error?.response?.data?.message.split(" ").splice(0, 3).join(" ") ===
      "E11000 duplicate key"
    ) {
      toast.error("Name Already Exists");
    }
    return error.message;
  }
};
// http://localhost:3000/api/jobtypes/:id
export const DeleteJobTypes = async (id) => {
  try {
    let data = await axiosInstance.delete(`jobtypes/${id}`, header);
    toast.success(data?.data?.message);
    return data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error.message;
  }
};
const initialState = {
  jobTypesData: [],
  status: "idel",
};

export const JobTypeSlice = createSlice({
  name: "JobTypesData",
  initialState,
  reducer: {},
  extraReducers: {
    [FetchJobTypes.pending]: (state) => {
      state.status = "Loading......";
      // state.jobtypes_data = null;
    },
    [FetchJobTypes.fulfilled]: (state, { payload }) => {
      state.status = "idle";
      state.jobTypesData = payload;
    },
    [FetchJobTypes.rejected]: (state) => {
      state.status = "idel";
    },
  },
});
