import React, { useEffect, useState } from "react";
import Sidebar from "../../Common/Sidebar";
import { CreateFolder, GetFolder } from "../../../Redux/Slices/Folder";
import { Link } from "react-router-dom";

const EmployerFolders = () => {
  const [data, setData] = useState("");
  const [folders, setFolders] = useState([]);
  //Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    let inputData = { name: data };
    await CreateFolder(inputData);
    handlefolder();
  };

  // Folder List
  const handlefolder = async () => {
    let data = await GetFolder();
    setFolders(data?.docs);
  };

  useEffect(() => {
    handlefolder();
    document.title = "Folder | CAJobPortal";
  }, []);
  return (
    <div className="m-5">
      <div className="row ">
        {/* ------------ SideBar ------------- */}
        <Sidebar />
        <div className="col-md-10">
          <h1>My Folder</h1>
          {/* ------- Main Content  ------- */}
          <div className="">
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="add-btn"
                data-bs-toggle="modal"
                data-bs-target="#addFolderModal"
              >
                Add Folder
              </button>
              {/* --- Search Input --- */}

              
            </div>
            <div className="">
              <div
                style={{ backgroundColor: "white", padding: "14px" }}
                className="table-responsive px-2"
              >
                <div
                  id="example_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div className="dataTables_length" id="example_length">
                    <label>
                      Show{" "}
                      <select
                        name="example_length"
                        aria-controls="example"
                        className
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>{" "}
                      entries
                    </label>
                    <input
                      className="search-table"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                    ></input>
                  </div>
                  <br />
                  <table
                    id="example"
                    className="table dataTable no-footer"
                    cellSpacing={0}
                    style={{ width: "100%" }}
                    aria-describedby="example_info"
                  >
                    <thead className="table-headline">
                      <tr>
                        <th
                          className="th_text sorting sorting_asc"
                          tabIndex={0}
                          aria-controls="example"
                          rowSpan={1}
                          colSpan={1}
                          aria-sort="ascending"
                          aria-label="S.no: activate to sort column descending"
                          style={{ width: 209 }}
                        >
                          S.no
                        </th>
                        <th
                          className="th_text sorting"
                          tabIndex={0}
                          aria-controls="example"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Folder: activate to sort column ascending"
                          style={{ width: 253 }}
                        >
                          Folder
                        </th>
                        <th
                          className="th_text sorting"
                          tabIndex={0}
                          aria-controls="example"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Go To: activate to sort column ascending"
                          style={{ width: 315 }}
                        >
                          Go To
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "white !important" }}>
                      {folders?.length > 0 ? (
                        folders.map((item, index) => (
                          <tr
                            style={{ backgroundColor: "white !important" }}
                            className="odd"
                          >
                            <td className="tb_text sorting_1">{index + 1}</td>
                            <td className="tb_text">{item?.name}</td>
                            <td className="tb_text">
                              <Link to={`/employer/subfolders/${item?._id}`}>
                                View Folder
                              </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <td
                          valign="top"
                          colspan="3"
                          className="dataTables_empty p-2"
                        >
                          {" "}
                          <center>No Folder available</center>
                        </td>
                      )}
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
            </div>
          </div>

          {/* ----Modal Box --- */}
          <div
            className="modal fade"
            id="addFolderModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Add Folder
                  </h5>
                  {/* Close Modal */}
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <form>
                    <div className="">
                      <input
                        onChange={(e) => setData(e.target.value)}
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder="Folder Name"
                        id="recipient-name"
                      />
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    onClick={handleSubmit}
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployerFolders;
