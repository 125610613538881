import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Cities from "../../Helper/CountryStateCity.json";

import {
  lacOption,
  monthOption,
  thousandOption,
  yearNoOption,
  yearOption,
} from "../../Helper/Option";
import { useDispatch, useSelector } from "react-redux";
import { FetchIndustries } from "../../Redux/Slices/Industry";
import { FetchSkill } from "../../Redux/Slices/Skill";
import { GoLocation } from "react-icons/go";
import { LiaIndustrySolid, LiaRupeeSignSolid } from "react-icons/lia";
import { PiBagSimple } from "react-icons/pi";
import MaleAvatar from "../../asset/new/images/male.png";
import FemaleAvatar from "../../asset/new/images/female.png";
import AdminNavbar from "./Common/AdminNavbar";
import Location from "./Location";
import { SearchCandidate } from "../../Redux/Slices/Search";
import { CiMobile1 } from "react-icons/ci";
import { TfiEmail } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import { CreateSubFolder, GetFolder } from "../../Redux/Slices/Folder";
import { FetchDegrees } from "../../Redux/Slices/Degree";
import { ClipLoader } from "react-spinners";
import { CvAccessBalance, SelectedExport } from "../../Redux/Slices/User";
import { formatDate } from "../../Helper/CoreHelper";

const CandidateSearch = () => {
  const { industriesData } = useSelector((state) => state?.Industry);
  const { skillsData } = useSelector((state) => state?.Skill);
  const { degreesData } = useSelector((state) => state?.Degrees);
  const [candidates, setCandidates] = useState(null);
  const [loading, setLoading] = useState(false);
  const [select, setSelect] = useState("");
  const [folders, setFolders] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState({});
  const [entriesToShow, setEntriesToShow] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [selectedOption, setSelectedOption] = useState("include_keyword");
  const [selectedqualification, setselectedqualification] = useState([]);
  const [selectedSkills, setselectedSkills] = useState([]);
  const [selectedIndustry, setselectedIndustry] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const animatedComponents = makeAnimated();
  const [selectedValues, setSelectedValues] = useState([]);

  const [searchHistory, setSearchHistory] = useState(null);
  const dispatch = useDispatch();
  let role = localStorage.getItem("role");

  useEffect(() => {
    let data = {
      page: 1,
      search: "",
    };
    dispatch(FetchIndustries(data));
    dispatch(FetchSkill(data));
    dispatch(FetchDegrees());
    handlefolder();
    document.title = "Candidate Search";
  }, [dispatch]);

  // In Case User Back to Search Candidate Page
  const searchHistoryHandler = async (data) => {
    if (data) {
      let res = await SearchCandidate(data);
      setCandidates(res);
      setSearchHistory(data);
    }
  };

  useEffect(() => {
    let history = localStorage.getItem("searchHistory");
    if (history) {
      history = JSON.parse(history);
    } else {
      history = {};
    }
    searchHistoryHandler(history);
  }, []);

  // Folder List
  const handlefolder = async () => {
    let data = await GetFolder();
    setFolders(data?.docs);
  };
  // Select profile
  const handleChange = (e) => {
    const { name, checked } = e.target;

    const updateUsers = (modifier) => {
      const tempUsers = candidates?.docs?.map((user) => modifier(user));
      setCandidates({ ...candidates, docs: tempUsers });
      return tempUsers;
    };

    if (name === "allSelect") {
      const tempUsers = updateUsers((user) => ({
        ...user,
        isChecked: checked,
      }));
      setSelectedUsers(
        checked ? tempUsers.filter((user) => user.isChecked) : []
      );
    } else {
      const tempUsers = updateUsers((user) =>
        user.email === name ? { ...user, isChecked: checked } : user
      );

      setSelectedUsers((prevSelectedUsers) =>
        checked
          ? [
              ...prevSelectedUsers,
              tempUsers.find((user) => user.email === name),
            ]
          : prevSelectedUsers.filter((user) => user.email !== name)
      );
    }
  };

  const handleEntriesChange = async (e) => {
    setEntriesToShow(Number(e.target.value));
    let data = { ...searchHistory, limit: parseInt(e.target.value) };
    let res = await SearchCandidate(data);
    setSearchHistory(data);
    setCandidates(res);
  };

  // =========== Select Export =========

  const selectedExport = async () => {
    let users = [];

    if (role === "Admin") {
      return await SelectedExport(selectedUsers);
    }

    if (role === "Employer") {
      const confirmed = window.confirm(
        "Do you want to purchase these Profile ?"
      );
      if (confirmed) {
        for (let user of selectedUsers) {
          let res = await CvAccessBalance();
          if (res.success) {
            users.push(user);
          }
        }
        return await SelectedExport(users);
      } else {
        // Handle the case where the user cancels the action
      }
    }
  };

  const [locationData, setLocationData] = useState({
    Country: "",
    State: "",
    City: [],
  });

  // Transform API data into the desired format
  const options = skillsData?.docs?.map((item) => ({
    value: item.name,
    label: item.name,
  }));
  const options2 = degreesData.map((item) => ({
    value: item.name,
    label: item.name,
  }));
  const options3 = industriesData?.docs?.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const FilteredCities = Cities?.flatMap((country) =>
    country?.states?.flatMap((state) => state?.cities)
  );

  const optionsCity = FilteredCities.map((item) => ({
    value: item.name,
    label: item.name,
  })).sort((a, b) => a.label.localeCompare(b.label));

  const handleSelectChangeCity = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setSelectedCity(values);
  };
  // Qualifications
  const handleSelectChange = (qualification) => {
    const values = qualification.map((option) => option.value);
    setselectedqualification(values);
  };
  // Skills
  const handleSelectChange2 = (skills) => {
    const values = skills.map((option) => option.value);
    setselectedSkills(values);
  };
  // Industry
  const handleSelectChange3 = (industry) => {
    const values = industry.map((option) => option.value);
    setselectedIndustry(values);
  };

  // Notice Period Select

  const handleCheckboxChange = (value) => {
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((item) => item !== value));
    } else {
      setSelectedValues([...selectedValues, value]);
    }
  };

  // Function to receive Location data from the child
  const receiveDataFromChild = (data) => {
    setLocationData(data);
  };

  let name, value;
  const searchHandler = (e) => {
    e.preventDefault();
    name = e.target.name;
    value = e.target.value;
    setSearchQuery({ ...searchQuery, [name]: value });
    if (name === "resume") {
      if (value === "true") {
        setSearchQuery({ ...searchQuery, [name]: true });
      }
      if (value === "false") {
        setSearchQuery({ ...searchQuery, [name]: false });
      }
    }
  };

  const appendIfNotEmpty = (obj, key, value) => {
    if (
      value !== null &&
      value !== undefined &&
      value !== "" &&
      !(Array.isArray(value) && value.length === 0)
    ) {
      obj[key] = value;
    }
  };

  const searchKeywordHandler = async (e) => {
    if (e.target.type === "radio") {
      setSelectedOption(e.target.name);
    } else {
      setSearchInput(e.target.value);
    }
    const searchObject = {
      [selectedOption]: searchInput,
    };
  };
  // Skills List
  const skillProvider = (skill) => {
    if (skill?.length) {
      return skill?.map((value) => value?.name)?.join(", ");
    } else {
      return "";
    }
  };
  //----------- Search Handler --------

  const reSetData = async (e) => {
    e.preventDefault();
    setSearchQuery({
      exp_from: "",
      exp_to: "",
      passing_year: "",
      passing_month: "",
      ctc_from_lacs: "",
      ctc_from_thousand: "",
      ctc_to_lacs: "",
      ctc_to_thousand: "",
    });
    setSearchInput("");
    setselectedqualification([]);
    setselectedSkills([]);
    setselectedIndustry([]);
    setLocationData({ Country: "", State: "", City: [] });
    setSelectedOption("include_keyword");
  };

  const searchButtonHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    let data = {};
    let state = { state: [locationData.State] };

    appendIfNotEmpty(data, selectedOption, searchInput);
    appendIfNotEmpty(data, "exp_from", searchQuery.exp_from);
    appendIfNotEmpty(data, "exp_to", searchQuery.exp_to);
    appendIfNotEmpty(data, "passing_year", searchQuery.passing_year);
    appendIfNotEmpty(data, "passing_month", searchQuery.passing_month);
    appendIfNotEmpty(data, "ctc_from_lacs", searchQuery.ctc_from_lacs);
    appendIfNotEmpty(data, "ctc_from_thousand", searchQuery.ctc_from_thousand);
    appendIfNotEmpty(data, "ctc_to_lacs", searchQuery.ctc_to_lacs);
    appendIfNotEmpty(data, "ctc_to_thousand", searchQuery.ctc_to_thousand);

    // appendIfNotEmpty(data, "address.country", locationData.Country);
    // appendIfNotEmpty(data, "state", state);

    appendIfNotEmpty(data, "cities", selectedCity);
    appendIfNotEmpty(data, "other_option", searchQuery.other_option);
    appendIfNotEmpty(data, "qualifications", selectedqualification);
    appendIfNotEmpty(data, "skills", selectedSkills);
    appendIfNotEmpty(data, "min_age", searchQuery.min_age);
    appendIfNotEmpty(data, "max_age", searchQuery.max_age);
    appendIfNotEmpty(data, "industries", selectedIndustry);
    appendIfNotEmpty(data, "notice_period", selectedValues);
    appendIfNotEmpty(data, "gender", searchQuery.gender);
    appendIfNotEmpty(data, "company", searchQuery.company);
    appendIfNotEmpty(data, "active", searchQuery.active);
    appendIfNotEmpty(data, "lastactive", searchQuery.lastactive);
    appendIfNotEmpty(data, "resume", searchQuery.resume);

    let res = await SearchCandidate(data);
    setCandidates(res);
    setSearchHistory(data);
    setLoading(false);
    localStorage.setItem("searchHistory", JSON.stringify(data));
  };

  // Add to Folder
  const handleAddFolder = async (e) => {
    const userIds = await selectedUsers.map((user) => user._id);
    let data = {
      userids: userIds,
    };
    await CreateSubFolder(select, data);
  };

  const handlePageChange = async (page) => {
    let data = { ...searchHistory, page };

    if (candidates?.pagination?.page === page) {
      return;
    }
    localStorage.setItem("searchHistory", JSON.stringify(data));
    let res = await SearchCandidate(data);
    setSearchHistory(data);
    setCandidates(res);
  };

  // Calculate visible page range
  let startPage = 1;
  let endPage = 1;

  if (candidates?.pagination) {
    startPage = Math.max(1, candidates.pagination.page - 2);
    endPage = Math.min(
      candidates.pagination.totalPages,
      candidates.pagination.page + 2
    );

    // Adjust start and end page if close to beginning or end
    if (candidates.pagination.page <= 3) {
      endPage = Math.min(5, candidates.pagination.totalPages);
    } else if (
      candidates.pagination.page >=
      candidates.pagination.totalPages - 2
    ) {
      startPage = Math.max(1, candidates.pagination.totalPages - 4);
    }
  }

  // Url Path
  let resumeUrl = process.env.REACT_APP_AZURE_STORAGE_URL;

  // Handle Download Multiple Resume
  const handleMultipleDownload = async () => {
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const openInNewTab = (url) => {
      const newWindow = window.open(url, "_blank");
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed === "undefined"
      ) {
        alert(
          "Pop-up blocked. Please enable pop-ups for this website to download the files."
        );
        return false; // Indicate that pop-up was blocked
      }
      return true; // Indicate that pop-up was successfully opened
    };

    if (role === "Admin") {
      for (let user of selectedUsers) {
        try {
          if (!user?.resume) {
            console.warn(
              `User ${user?.name || "unknown"} does not have a resume.`
            );
            continue; // Proceed to the next user if resume is not available
          }
          let url = resumeUrl + user.resume;
          const success = openInNewTab(url);
          if (!success) break; // Exit the loop if pop-up was blocked
          await delay(500); // Adjust delay as needed
        } catch (error) {
          console.error(
            "An error occurred while trying to open the file:",
            error
          );
        }
      }
    }

    if (role === "Employer") {
      const confirmed = window.confirm(
        "Do you want to purchase these profiles?"
      );
      if (confirmed) {
        for (let user of selectedUsers) {
          try {
            if (!user?.resume) {
              console.warn(
                `User ${user?.name || "unknown"} does not have a resume.`
              );
              continue; // Proceed to the next user if resume is not available
            }
            let res = await CvAccessBalance();
            if (res.success) {
              let finalUrl = resumeUrl + user.resume;
              const success = openInNewTab(finalUrl);
              if (!success) break; // Exit the loop if pop-up was blocked
              await delay(500); // Adjust delay as needed
            } else {
              console.error("Failed to access CV balance.");
            }
          } catch (error) {
            console.error(
              "An error occurred while trying to download the file:",
              error
            );
          }
        }
      }
    }
  };

  // View Resume
  const handleDownload = async (path) => {
    if (role === "Admin") {
      let url = resumeUrl + path;
      window.open(url, "_blank");
    }
    if (role === "Employer") {
      const confirmed = window.confirm(
        "Do you want to purchase these Profile ?"
      );
      if (confirmed) {
        let res = await CvAccessBalance();
        if (res?.success) {
          let finalUrl = resumeUrl + path;
          window.open(finalUrl, "_blank");
        }
      }
    }
  };

  // View Profile
  const handleClick = async (value) => {
    let url = "";

    if (role === "Admin") {
      url = `/Jobseeker/${value}`;
    } else if (role === "Employer") {
      let res = await CvAccessBalance();
      if (res?.success) {
        url = `/employer/Jobseeker/${value}`;
      } else {
        return;
      }
    }

    window.open(url, "_blank");
  };

  // Salary Formate

  function formatSalary(totalSalary) {
    return totalSalary?.$numberDecimal || totalSalary || "";
  }
  return (
    <>
      {role === "Admin" && <AdminNavbar />}

      <div className="featured-job-container">
        <div className="row">
          {/* ----Sidebar --- */}

          <div className="col-md-3">
            <div className="row">
              <div className="col-9">
                <h5>Candidate Search</h5>
              </div>
              <div className="col-3">
                {/* <p className="text-danger mt-1">Clear All</p> */}
              </div>
            </div>
            {/*-------- Search Field----- */}

            <input
              name="keyword"
              value={searchInput}
              onChange={(e) => searchKeywordHandler(e)}
              className="input-search"
              type="text"
              placeholder="search keyword"
            />
            {/* Radio Button */}
            <div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="include_keyword"
                  id="inlineRadio1"
                  onChange={(e) => searchKeywordHandler(e)}
                  checked={selectedOption === "include_keyword"}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  Include Keyword
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exclude_keyword"
                  id="inlineRadio2"
                  onChange={(e) => searchKeywordHandler(e)}
                  checked={selectedOption === "exclude_keyword"}
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  Exclude Keyword
                </label>
              </div>
            </div>
            <br />
            {/* --------- Experience -------- */}

            <div className="filter-box2">
              <p className="search-minihead">Total Experience :</p>
              <form>
                <div className="row">
                  <div className="col">
                    <label
                      htmlFor="formGroupExampleInput"
                      className="label-head"
                    >
                      From (Year)
                    </label>
                    <select
                      name="exp_from"
                      value={searchQuery?.exp_from}
                      onChange={(e) => searchHandler(e)}
                      className="form-select mb-3 two-in-one"
                      aria-label="Default select example"
                    >
                      <option value={""}>All</option>
                      {yearOption?.map((value) => (
                        <option key={value + 2} value={value}>
                          {`${value} Year`}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col">
                    <label
                      htmlFor="formGroupExampleInput"
                      className="label-head"
                    >
                      To (Year)
                    </label>
                    <select
                      name="exp_to"
                      value={searchQuery?.exp_to}
                      onChange={(e) => searchHandler(e)}
                      className="form-select mb-3 two-in-one"
                      aria-label="Default select example"
                    >
                      <option value={""}>All</option>
                      {yearOption?.map((value) => (
                        <option key={value + 2} value={value}>
                          {`${value} Year`}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </form>
            </div>

            {/* --------- Passing (Years) -------- */}

            <div className="filter-box2">
              <form>
                <div className="row">
                  <div className="col">
                    <label
                      htmlFor="formGroupExampleInput"
                      className="label-head"
                    >
                      Passing (Year)
                    </label>
                    <select
                      name="passing_year"
                      value={searchQuery?.passing_year}
                      onChange={(e) => searchHandler(e)}
                      className="form-select mb-3 two-in-one"
                      aria-label="Default select example"
                    >
                      <option value={""}>Select Year</option>
                      {yearNoOption?.map((value) => (
                        <option key={value + 2} value={value}>
                          {`${value}`}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col">
                    <label
                      htmlFor="formGroupExampleInput"
                      className="label-head"
                    >
                      Passing (Month)
                    </label>
                    <select
                      name="passing_month"
                      value={searchQuery?.passing_month}
                      onChange={(e) => searchHandler(e)}
                      className="form-select mb-3 two-in-one"
                      aria-label="Default select example"
                    >
                      <option value={""}>All</option>
                      {monthOption?.map((value) => (
                        <option key={value + 2} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </form>
            </div>
            {/* --------- CTC -------- */}

            <div className="filter-box2">
              <p className="search-minihead">Current CTC (Lakhs Per Annum):</p>
              <form>
                <div className="row">
                  {/* From */}
                  <div className="col">
                    <label
                      htmlFor="formGroupExampleInput"
                      className="label-head"
                    >
                      Range From(Lacs)
                    </label>
                    <select
                      name="ctc_from_lacs"
                      value={searchQuery?.ctc_from_lacs}
                      onChange={(e) => searchHandler(e)}
                      className="form-select mb-3 two-in-one"
                      aria-label="Default select example"
                    >
                      <option value={""}>All</option>
                      <option value={""}>Select</option>
                      {lacOption?.map((value) => (
                        <option key={value + 2} value={value}>
                          {`${value} Lacs`}
                        </option>
                      ))}
                    </select>
                    <select
                      name="ctc_from_thousand"
                      value={searchQuery?.ctc_from_thousand}
                      onChange={(e) => searchHandler(e)}
                      className="form-select mb-3 two-in-one"
                      aria-label="Default select example"
                    >
                      <option value={""}>All</option>
                      <option value={""}>Select</option>
                      {thousandOption?.map((value) => (
                        <option key={value + 2} value={value}>
                          {`${value} Thousands`}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* To */}
                  <div className="col">
                    <label
                      htmlFor="formGroupExampleInput"
                      className="label-head"
                    >
                      To(Lacs)
                    </label>
                    <select
                      name="ctc_to_lacs"
                      value={searchQuery?.ctc_to_lacs}
                      onChange={(e) => searchHandler(e)}
                      className="form-select mb-3 two-in-one"
                      aria-label="Default select example"
                    >
                      <option value={""}>All</option>
                      <option value={""}>Select</option>
                      {lacOption?.map((value) => (
                        <option key={value + 2} value={value}>
                          {`${value} Lacs`}
                        </option>
                      ))}
                    </select>
                    <select
                      name="ctc_to_thousand"
                      value={searchQuery?.ctc_to_thousand}
                      onChange={(e) => searchHandler(e)}
                      className="form-select mb-3 two-in-one"
                      aria-label="Default select example"
                    >
                      <option value={""}>All</option>
                      <option value={""}>Select</option>
                      {lacOption?.map((value) => (
                        <option key={value + 2} value={value}>
                          {`${value} Thousands`}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </form>
            </div>
            {/* --------- Location -------- */}

            {/* <Location sendDataToParent={receiveDataFromChild} /> */}
            <div className="mt-2 filter-box2">
              <p className="search-minihead">City</p>
              <Select
                className="multiple-select"
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={optionsCity}
                onChange={handleSelectChangeCity}
              />
            </div>

            {/* ---Qualification Option --- */}
            <div className="mt-2 filter-box2">
              <p className="search-minihead">Qualification</p>
              <Select
                className="multiple-select"
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={options2}
                onChange={handleSelectChange}
              />
            </div>

            {/* --- Skills Option --- */}
            <div className="filter-box2 mt-3">
              <p className="search-minihead">Skills</p>
              <Select
                className="multiple-select"
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={options}
                onChange={handleSelectChange2}
              />
            </div>

            {/* --- Industry Option --- */}
            <div className="filter-box2 mt-3">
              <p className="search-minihead">Industry</p>
              <Select
                className="multiple-select"
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={options3}
                onChange={handleSelectChange3}
              />
            </div>

            {/* --------- Candidate Age -------- */}

            <div className="filter-box2 mt-3">
              <p className="search-minihead">Candidate Age</p>
              <form>
                <div className="row">
                  <div className="col">
                    <p>Min(Years)</p>
                    <input
                      className="input-search"
                      type="number"
                      placeholder="Enter Min Age"
                      name="min_age"
                      onChange={(e) => searchHandler(e)}
                    />
                  </div>

                  <div className="col">
                    <p>Max(Years)</p>
                    <input
                      className="input-search"
                      type="number"
                      placeholder="Enter Max Age"
                      name="max_age"
                      onChange={(e) => searchHandler(e)}
                    />
                  </div>
                </div>
              </form>

              <div className="sec-box"></div>
              <div className="sec-box"></div>
            </div>

            {/* --------- Notice Period -------- */}
            <p className="search-minihead">Notice Period</p>
            <div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  onChange={() => handleCheckboxChange("0.5")}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox1">
                  15 Days
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox2"
                  onChange={() => handleCheckboxChange("1")}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox2">
                  1 Month
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox2"
                  onChange={() => handleCheckboxChange("2")}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox2">
                  2 Months
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox2"
                  onChange={() => handleCheckboxChange("3")}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox2">
                  3 Months
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox2"
                  onChange={() => handleCheckboxChange(">3")}
                />
                <label className="form-check-label" htmlFor="inlineCheckbox2">
                  More than 3 Months
                </label>
              </div>
              {/* <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox2"
                />
                <label
                  className="form-check-label"
                  htmlFor="inlineCheckbox2"
                ></label>
              </div> */}
            </div>
            {/* --------- Gender -------- */}

            <div className="filter-box"></div>
            <p className="search-minihead">Gender</p>
            <select
              name="gender"
              className="form-select mb-3"
              aria-label="Default example"
              onChange={(e) => searchHandler(e)}
            >
              <option value={""}>Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>

            {/* Company Name (Candidate of these company) */}

            {/* --------- Sort By -------- */}
            <p className="search-minihead">Sort By</p>
            <select
              name="status"
              onChange={(e) => searchHandler(e)}
              className="form-select mb-3"
              aria-label="Default select example"
            >
              <option value={""}>Select</option>
              <option value={"Active"}>Active</option>
              <option value={""}>Any</option>
            </select>

            {/* --------- Active in -------- */}
            <p className="search-minihead">Active in</p>
            <select
              name="lastactive"
              onChange={(e) => searchHandler(e)}
              className="form-select mb-3"
              aria-label="Default select example"
            >
              <option value="">All</option>
              <option value="1">1 day</option>
              <option value="2">3 days</option>
              <option value="7">7 days</option>
              <option value="15">15 days</option>
              <option value="30">30 days</option>
            </select>

            {/* --------- Resume -------- */}
            <p className="search-minihead">Resume</p>
            <select
              name="resume"
              onChange={(e) => searchHandler(e)}
              className="form-select mb-3"
              aria-label="Default select example"
            >
              <option value="">All</option>
              <option value={true}>With Resume</option>
              <option value={false}>Without Resume</option>
            </select>

            {/* ------------ Search Button ----------- */}

            {loading ? (
              <center>
                <ClipLoader color="#36d7b7" />
              </center>
            ) : (
              <>
                <button
                  onClick={searchButtonHandler}
                  className="mb-3 border-0 w-100 rounded-pill"
                >
                  Search
                </button>
                <button
                  onClick={reSetData}
                  className="mb-3 w-100 normal-button"
                >
                  Reset
                </button>
              </>
            )}

            {/* ---------Job List Section-------- */}
          </div>
          {/* ----End of Sidebar --- */}

          {/* --- Candidate List */}
          <div className="col-md-9 border-start">
            <h5 className="job-card">
              {candidates?.pagination?.totalDocs}{" "}
              {candidates?.pagination?.totalDocs && "Profiles Found"}
            </h5>
            {/* --- Select All ------ */}
            {candidates?.docs?.length > 0 && (
              <div className="d-flex justify-content-between">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="allSelect"
                    checked={
                      !candidates?.docs?.some(
                        (user) => user?.isChecked !== true
                      )
                    }
                    onChange={handleChange}
                  />
                  <label className="form-check-label ms-2">All Select</label>
                </div>
                <button
                  className="view-app-btn ms-2"
                  data-bs-toggle="modal"
                  data-bs-target="#addtoFolderModal"
                >
                  Add To Folder
                </button>
              </div>
            )}

            {/* ----Sort --- */}
            {candidates?.docs?.length > 0 && (
              <div className="sec-box">
                <label>
                  Show{" "}
                  <select
                    value={entriesToShow}
                    onChange={handleEntriesChange}
                    name="example_length"
                    aria-controls="example"
                  >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>{" "}
                  entries
                </label>

                <div>
                  <button onClick={selectedExport} className="download-btn2">
                    Excel Download
                  </button>
                  <button
                    onClick={handleMultipleDownload}
                    className="download-btn"
                  >
                    Download
                  </button>{" "}
                </div>
              </div>
            )}

            {/*-------- Card----- */}
            {candidates?.docs?.length > 0 ? (
              candidates?.docs?.map((value, index) => (
                <div key={index + 1} className="joblist-card m-3 p-2 ">
                  <div className="sec-box">
                    <div className="details">
                      <div className="form-check" key={index}>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name={value?.email}
                          checked={value?.isChecked || false}
                          onChange={handleChange}
                        />
                        <h6>{value?.name}</h6>
                      </div>
                      <span className="company-name">
                        <TfiEmail style={{ fontSize: "15px" }} /> {value?.email}
                      </span>{" "}
                      <span className="company-name">
                        <CiMobile1 style={{ fontSize: "15px" }} />{" "}
                        {value?.mobile}
                      </span>
                    </div>
                    <div>
                      {value?.resume ? (
                        <button
                          onClick={async () => {
                            handleDownload(value?.resume);
                          }}
                          className="view-resume"
                        >
                          View Resume
                        </button>
                      ) : (
                        <button className="no-view-resume">
                          No Resume Available
                        </button>
                      )}
                      <img
                        className="img-responsive img-thumbnail rounded-circle"
                        onClick={() => handleClick(value?._id)}
                        src={
                          value?.profile_img
                            ? value?.profile_img
                            : value?.gender === "M" || value?.gender === "Male"
                            ? MaleAvatar
                            : FemaleAvatar
                        }
                        alt="images"
                      />
                    </div>
                  </div>

                  <div className="job-details my-2 ms-2">
                    <span className="text-nowrap details">
                      {" "}
                      <PiBagSimple style={{ fontSize: "19px" }} />{" "}
                      {/* {value?.total_experience} Years */}
                      {value?.total_experience
                        ? formatSalary(value?.total_experience) + " Years"
                        : ""}
                    </span>
                    <span className="text-nowrap details">
                      <LiaRupeeSignSolid style={{ fontSize: "19px" }} />{" "}
                      {value?.current_ctc?.lac || 0}.
                      {value?.current_ctc?.thousand || 0} Lacs
                    </span>
                    {value?.address?.city && (
                      <span className="text-nowrap mr-2">
                        <GoLocation style={{ fontSize: "19px" }} />
                        {value?.address?.city}
                      </span>
                    )}
                    <span>
                      <LiaIndustrySolid style={{ fontSize: "19px" }} />{" "}
                      {skillProvider(value?.career?.skills)}
                    </span>
                  </div>
                  <p>
                    <span className="fw-semibold">Profile Headline :</span>{" "}
                    {value?.career?.profile_headline}
                  </p>

                  {/*----- Table -----*/}
                  {value?.experience?.length > 0 && (
                    <>
                      <p className="fw-semibold">Previous :</p>
                      <table
                        id="example"
                        className="table dataTable no-footer"
                        cellSpacing={0}
                        style={{ width: "100%" }}
                        aria-describedby="example_info"
                      >
                        <thead className="table-headline">
                          <tr>
                            <th
                              className="th_text sorting sorting_asc"
                              tabIndex={0}
                              aria-controls="example"
                              rowSpan={1}
                              colSpan={1}
                              aria-sort="ascending"
                              aria-label="S.no: activate to sort column descending"
                              style={{ width: 209 }}
                            >
                              Company
                            </th>
                            <th
                              className="th_text sorting sorting_asc"
                              tabIndex={0}
                              aria-controls="example"
                              rowSpan={1}
                              colSpan={1}
                              aria-sort="ascending"
                              aria-label="S.no: activate to sort column descending"
                              style={{ width: 209 }}
                            >
                              Designation
                            </th>
                          </tr>
                        </thead>
                        <tbody style={{ backgroundColor: "white !important" }}>
                          {value?.experience?.map((item) => (
                            <tr
                              style={{ backgroundColor: "white !important" }}
                              className="odd"
                            >
                              <td>
                                {item?.company?.name ||
                                  item?.company_name ||
                                  ""}
                              </td>
                              <td>{item?.designation || ""}</td>
                            </tr>
                          ))}
                          {value?.experience?.length === 0 && (
                            <tr>
                              <td
                                valign="top"
                                colSpan="3"
                                className="dataTables_empty p-2"
                              >
                                {" "}
                                <center>No data available</center>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </>
                  )}
                  <p className="fw-semibold">Professional Qualification</p>
                  <table
                    id="example"
                    className="table dataTable no-footer"
                    cellSpacing={0}
                    style={{ width: "100%" }}
                    aria-describedby="example_info"
                  >
                    <thead className="table-headline">
                      <tr>
                        <th
                          className="th_text sorting sorting_asc"
                          tabIndex={0}
                          aria-controls="example"
                          rowSpan={1}
                          colSpan={1}
                          aria-sort="ascending"
                          aria-label="S.no: activate to sort column descending"
                          style={{ width: 209 }}
                        >
                          Qualification
                        </th>
                        <th
                          className="th_text sorting sorting_asc"
                          tabIndex={0}
                          aria-controls="example"
                          rowSpan={1}
                          colSpan={1}
                          aria-sort="ascending"
                          aria-label="S.no: activate to sort column descending"
                          style={{ width: 209 }}
                        >
                          Year of Passing
                        </th>
                        <th
                          className="th_text sorting sorting_asc"
                          tabIndex={0}
                          aria-controls="example"
                          rowSpan={1}
                          colSpan={1}
                          aria-sort="ascending"
                          aria-label="S.no: activate to sort column descending"
                          style={{ width: 209 }}
                        >
                          Marks
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "white !important" }}>
                      {value?.prof_qualification?.map((item) => (
                        <tr
                          style={{ backgroundColor: "white !important" }}
                          className="odd"
                        >
                          <td>{item?.degree?.name || ""}</td>
                          <td>{item?.term_of_passing?.year || ""}</td>
                          <td>{item?.marks || ""}</td>
                        </tr>
                      ))}
                      {value?.prof_qualification?.length === 0 && (
                        <tr>
                          <td
                            valign="top"
                            colSpan="3"
                            className="dataTables_empty p-2"
                          >
                            {" "}
                            <center>No data available</center>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="card-buttom">
                    <button
                      onClick={() => handleClick(value?._id)}
                      className="view-profile"
                    >
                      View Profile
                    </button>
                    <span className="company-name">
                      Updated On: {formatDate(value?.updated_at)}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <>
                <span>No Data</span>
              </>
            )}
          </div>
        </div>
        {/* --- Pagination ----- */}
        {candidates?.pagination && (
          <nav aria-label="Page navigation example" className="p-4">
            <ul className="pagination justify-content-end">
              <li
                className={`page-item ${
                  candidates?.pagination?.prevPage ? "" : "disabled"
                }`}
              >
                <button
                  className="page-link"
                  onClick={() =>
                    handlePageChange(candidates?.pagination?.prevPage)
                  }
                >
                  Previous
                </button>
              </li>
              {Array.from(
                { length: endPage - startPage + 1 },
                (_, i) => startPage + i
              ).map((page) => (
                <li
                  key={page}
                  className={`page-item ${
                    candidates?.pagination?.page === page ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </button>
                </li>
              ))}
              <li
                className={`page-item ${
                  candidates?.pagination?.nextPage ? "" : "disabled"
                }`}
              >
                <button
                  className="page-link"
                  onClick={() =>
                    handlePageChange(candidates?.pagination?.nextPage)
                  }
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        )}
        {/* ----- Pagination End */}
        {/* ----Modal Box --- */}
        <div
          className="modal fade"
          id="addtoFolderModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Select Folder
                </h5>
                {/* Close Modal */}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <select
                      className="form-select mb-3"
                      aria-label="Default select example"
                      onChange={(e) => setSelect(e.target.value)}
                      name="type"
                    >
                      <option>Select Folder</option>
                      {folders?.map((value, index) => (
                        <option key={index + 1} value={value?._id}>
                          {value?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  onClick={handleAddFolder}
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateSearch;
