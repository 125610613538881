import React, { useEffect, useState } from "react";
import { useDispatch} from "react-redux";
import { GetUser, UpdateUser } from "../../../../Redux/Slices/User";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GoPlus } from "react-icons/go";
import AddLanguageModal from "./AddLanguageModal";

const AdditionalDetails = ({userData}) => {
  // ------------- OnChange Validation --------------
  const [editData, setEditData] = useState(userData);
  const [error, setError] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [languages, setLanguages] = useState([]);

  useEffect(()=>{
    setLanguages(userData?.languages)
  },[userData])

  const receiveDataFromChild = (data) => {
    setLanguages(data);
  };
  const dispatch = useDispatch();

  //   Covert Dob
  const dateString = startDate;
  const dateObject = new Date(dateString);

  const month = dateObject.toLocaleString("en-US", { month: "short" });
  const day = dateObject.getDate();
  const year = dateObject.getFullYear();

  const formattedDate = `${day} ${month} ${year}`;

  //----Add ----
  const handleEdit = async () => {
    setEditData(userData);
  };

  let name, value;
  const postUserData = (e) => {
    name = e.target.name;
    value = e.target.value;

    setEditData((prevData) => ({ ...prevData, [name]: value }));
    if (name === "pincode") {
      if (value.length === 0) {
        setEditData({
          ...editData,
          address: { ...editData.address, pincode: "" },
        });
      } else {
        setEditData({
          ...editData,
          address: { ...editData.address, pincode: value },
        });
      }
    }
    if (name === "address1") {
      if (value.length === 0) {
        setEditData({
          ...editData,
          address: { ...editData.address, address1: "" },
        });
      } else {
        setEditData({
          ...editData,
          address: { ...editData.address, address1: value },
        });
      }
    }
  };
  const handleUpdate = async (id) => {
    // Address
    let data = {
      dob: formattedDate || userData?.dob,
      gender: editData?.gender || userData?.gender,
      address: editData?.address || userData?.address,
      linkedin: editData?.linkedin || userData?.linkedin,
      marital_status: editData?.marital_status || userData?.marital_status,
      languages: languages || userData?.languages,
    };
    await UpdateUser(id, data, "application/json");
    dispatch(GetUser(id));
    // setEditData("");
  };

  return (
    <div className="prfile-card my-3">
      <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-center">
          <h3 className="card-head-line">Additional Details</h3>
        </div>
        <div>
          <button
            onClick={(e) => handleEdit()}
            data-bs-toggle="modal"
            data-bs-target="#editadditional "
            className="btnpf4"
          >
            <GoPlus className="fs-2" />
          </button>
        </div>
      </div>
      {/*------ Additional Details ---- */}

      <div className=" mt-2">
        <p>
          <span className="prof-details">Date of Birth</span>: {userData?.dob}{" "}
          {/* <MdDeleteOutline onClick={() => handleDelete(index)} /> */}
        </p>
        <p>
          <span className="prof-details">Gender</span> : {userData?.gender}
        </p>
        <p>
          <span className="prof-details">Marital Status</span> :{" "}
          {userData?.marital_status}
        </p>
        <p>
          <span className="prof-details">Address</span> :{" "}
          {userData?.address?.address1}
        </p>
        <p>
          <span className="prof-details">Pincode</span> :{" "}
          {userData?.address?.pincode}
        </p>
        <table className="table" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Languages</th>
              <th>Proficiency</th>
              <th>Read</th>
              <th>Write</th>
              <th>Speak</th>
            </tr>
          </thead>
          <tbody>
            {userData?.languages?.map((value) => (
              <tr>
                <td>{value?.name}</td>
                <td>{value?.proficiency}</td>
                <td>
                  {value?.read ? (
                    <i className="bi bi-check" />
                  ) : (
                    <i className="bi bi-file-x" />
                  )}
                </td>
                <td>
                  {value?.write ? (
                    <i className="bi bi-check" />
                  ) : (
                    <i className="bi bi-file-x" />
                  )}
                </td>
                <td>
                  {value?.speak ? (
                    <i className="bi bi-check" />
                  ) : (
                    <i className="bi bi-file-x" />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Add  Modal */}
      <div
        className="modal fade modal-lg"
        id="editadditional"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Additional Details
              </h5>
              {/* Close Modal */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form className="experience-modal">
                {/* ------------ Gender ------ */}
                <div>Date of Birth</div>
                <DatePicker
                  className="form-control jobValidity "
                  placeholderText={userData?.dob || "Enter Date of Birth "}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />

                <div>
                  <label className="gender-label">Gender :</label>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input mt-3"
                      type="radio"
                      name="gender"
                      id="inlineRadio1"
                      value="Male"
                      checked={editData?.gender === "Male"}
                      onChange={(e) => postUserData(e)}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio1">
                      Male
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input mt-3"
                      type="radio"
                      name="gender"
                      id="inlineRadio2"
                      value="Female"
                      checked={editData?.gender === "Female"}
                      onChange={(e) => postUserData(e)}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio2">
                      Female
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input mt-3"
                      type="radio"
                      name="gender"
                      id="inlineRadio3"
                      value="Other"
                      checked={editData?.gender === "Other"}
                      onChange={(e) => postUserData(e)}
                    />
                    <label className="form-check-label" htmlFor="inlineRadio3">
                      Others
                    </label>
                  </div>
                </div>

                {/* -------- Pin Code  --------- */}
                <label htmlFor="recipient-name" className="col-form-label">
                  {error.attempt ? (
                    <span style={{ color: "red" }}> {error.attempt}</span>
                  ) : (
                    "Pincode"
                  )}
                </label>
                <input
                  name="pincode"
                  type="text"
                  className="form-control h-25"
                  placeholder="Pincode"
                  value={editData?.address?.pincode}
                  onChange={(e) => postUserData(e)}
                />

                {/* -------- Linkedin  --------- */}
                <div className="">
                  <label htmlFor="basic-url" className="form-label">
                    Linkedin Profile URL
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="basic-url"
                      name="linkedin"
                      value={editData?.linkedin}
                      onChange={(e) => postUserData(e)}
                      aria-describedby="basic-addon3 basic-addon4"
                    />
                  </div>
                </div>

                {/* -------- Martial Status  --------- */}
                <label htmlFor="recipient-name" className="col-form-label">
                  {error.attempt ? (
                    <span style={{ color: "red" }}> {error.attempt}</span>
                  ) : (
                    "Marital Status"
                  )}
                </label>
                <select
                  name="marital_status"
                  onChange={(e) => postUserData(e)}
                  className="form-select me-3"
                  aria-label="Default select example"
                  value={editData?.marital_status || userData?.marital_status}
                >
                  <option value={""} defaultValue>
                    Select
                  </option>
                  <option value={"Married"}>Married</option>
                  <option value={"Unmarried"}>Unmarried</option>
                </select>

                {/* ------------ Address ------------ */}
                <label htmlFor="recipient-name" className="col-form-label">
                  {error.year ? (
                    <span style={{ color: "red" }}> {error.year}</span>
                  ) : (
                    "Address"
                  )}
                </label>
                <div className="mb-3">
                  <textarea
                    id="address"
                    name="address1"
                    rows={5}
                    value={editData?.address?.address1}
                    placeholder="Your Address"
                    className="form-control"
                    onChange={(e) => postUserData(e)}
                  />
                </div>
                <div>
                  {/* Input Language */}
                  <AddLanguageModal sendDataToParent={receiveDataFromChild} />
                </div>
              </form>
            </div>

            {/* Button */}
            <div className="modal-footer">
              <button
                onClick={(e) => handleUpdate(editData?._id)}
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalDetails;
