import React from "react";
import "./about.css";
import Member1 from '../../../asset/new/images/member/member1.png'
import Member2 from '../../../asset/new/images/member/member2.png'
import Member3 from '../../../asset/new/images/member/member3.png'
import Member4 from '../../../asset/new/images/member/member4.png'
const Team = () => {
  return (
    <>
      <h5 className="board">ADVISORY  BOARD</h5>
      <br />
      <br />
      <br />
      <br />
      <div>
        <div className="row">
          <div className="col-md-3 col-sm-6 ">
            <div className="our-team">
              <div className="pic">
                <img
                  src={Member1}
                  alt="Member1"
                />
              </div>

              <div className="team-content ">
                <h3 className="title">Mr. Hari Mundra</h3>
                <p className="description">
                  Mr. Hari Mundra graduated from IIM Ahmedabad in the year 1971.
                  He has had an illustrious career spanning 40 years in CXO
                  roles across HUL, RPG, Wockhardt and Essar Oil. Currently, he
                  is director on the boards of Ceat Ltd, Tata AutoComp Systems
                  Ltd and Allcargo Logistics Ltd.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="our-team">
              <div className="pic">
                <img
                  src={Member2}
                  alt='Member2'
                />
              </div>

              <div className="team-content">
                <h3 className="title">Ms. V N Saroja</h3>
                <p className="description">
                  IIM Ahmedabad alumnus, Co-founder and former COO of naukri.com
                  and currently Chief Strategy Officer at Indian Agribusiness
                  Systems Private Limited
                </p>
              </div>
            </div>
          </div>
          <br />
          <div className="col-md-3 col-sm-6">
            <div className="our-team">
              <div className="pic">
                <img
                  src={Member3}
                  alt='Member3'
                />
              </div>

              <div className="team-content">
                <h3 className="title">CA. Dilip Apte</h3>
                <p className="description">
                  CA. Dilip Apte - Former GM-Corporate Relations at HDFC Ltd.
                  and currently with Purnartha Investment Advisors Pune
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="overflow-hidden our-team">
              <div className="pic">
                <img
                  src={Member4}
                  alt='Member4'
                />
              </div>

              <div className="team-content">
                <h3 className="title">Prof. Arvind Sahay</h3>
                <p className="description">
                  Prof Arvind Sahay has studied at IIT-Kanpur, IIM Ahmedabad and
                  The University of Texas. He has served as Assistant Professor
                  at London Business School from the year 1996-2004. At IIM
                  Ahmedabad, he has served as Dean (Alumni & External Relations)
                  (2013-16) and Chairperson (PGPX) (2007-09).
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
