import React, { useCallback, useEffect, useState } from "react";
import { OtpRequest, OtpVerification } from "../../Redux/Slices/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const ForgetPassword = () => {
  const { redirectTo } = useSelector((state) => state?.Auth);
  const [user, setUser] = useState({});
  const [error, setError] = useState({});
  const [loading, setloading] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let name, value;
  const postUserData = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
    if (name === "email") {
      if (value.length === 0) {
        setError({ ...error, email: "Email is required" });
        setUser({ ...user, email: "" });
      } else {
        setError({ ...error, email: "" });
        setUser({ ...user, email: value });
      }
    }
    if (name === "otp") {
      if (value.length === 0) {
        setError({ ...error, otp: "Otp is Required" });
        setUser({ ...user, otp: "" });
      } else {
        setError({ ...error, otp: "" });
        setUser({ ...user, otp: value });
      }
    }
  };
  // Otp Handler
  const handleOtpRequest = async (e) => {
    e.preventDefault();
    if (user?.email == "" || user?.email == undefined || user?.email == null) {
      setError({ ...error, email: "Email is required" });
    } else {
      let data = { email: user.email, mode: "request" };
      await OtpRequest(data);
      setloading(true);
    }
  };

  // Submit Button
  const SubmitInfo = async (e) => {
    e.preventDefault();
    let data = {
      email: user.email,
      otp: user.otp,
      mode: "verify",
    };
    dispatch(OtpVerification(data));
    setloading(false);
    redirectUser();
  };

  // Redirect
  const redirectUser = useCallback(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    const isInLoginPage = window.location.pathname === "/reset-account";
    if (token && isInLoginPage) {
      navigate("/");
    }
  }, [navigate, redirectTo]);

  useEffect(() => {
    setloading(false);
    redirectUser();
    document.title = "Otp Login | CAJobPortal";
  }, [redirectUser, redirectTo]);

  return (
    <section className="wrapper ">
      <div className="container p-3">
        <div className="col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 text-center">
          <form className="rounded bg-white shadow py-4 px-4">
            {loading ? (
              <>
                <h3 className="text-dark fw-bolder fs-4 mb-2">Verify OTP</h3>
                <span className="otp-message">
                  Your code was sent to you via email
                </span>
                <br />
                <span className="otp-message">
                  After Login Kindly Set New Password From My Profile
                </span>
              </>
            ) : (
              <h3 className="text-dark fw-bolder fs-4 mb-2">Login With OTP</h3>
            )}
            {loading && (
              <div className="form-floating mt-3">
                <input
                  onChange={(e) => postUserData(e)}
                  value={user.otp}
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  name="otp"
                  placeholder="Enter OTP"
                />
                <button
                  onClick={SubmitInfo}
                  type="submit"
                  className="btn btn-primary submit_btn_signIn w-100 my-4"
                >
                  Verify
                </button>
                <label htmlFor="floatingInput">Enter OTP </label>
              </div>
            )}{" "}
            {!loading && (
              <div className="form-floating mt-3">
                <input
                  onChange={(e) => postUserData(e)}
                  value={user.email}
                  type="email"
                  className="form-control"
                  id="floatingInput2"
                  name="email"
                  placeholder="name@example.com"
                />
                <span style={{ color: "red" }}> {error.email} </span>
                <button
                  onClick={handleOtpRequest}
                  type="submit"
                  className="btn btn-primary submit_btn_signIn w-100 my-4"
                >
                  Send OTP
                </button>
                <label htmlFor="floatingInput">Enter Email </label>
              </div>
            )}
          </form>
        </div>
      </div>
    </section>
  );
};

export default ForgetPassword;
