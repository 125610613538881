import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance, config } from "../../Axios/AxiosInstance";
import toast from "react-hot-toast";

//Token
let header = config;

const initialState = {
  usersData: [],
  status: "idel",
  userData: null,
  completionPercentage: null,
  loading: null,
};

// http://localhost:3000/api/users/all
export const FetchUsers = createAsyncThunk("users/all/fetch", async (data) => {
  try {
    let url = "users/all";

    if (data?.role) {
      url += `?role=${data?.role}&$page=${data?.page}&$search=${data?.search}`;
    }
    if (data?.alllist) {
      url = `users/all?role=${data?.role}&alllist=${data?.alllist}`;
    }
    let res = await axiosInstance.get(url, header);
    return res?.data;
  } catch (error) {
    return error.message;
  }
});

export const FetcJobSeekerhUsers = createAsyncThunk("users/all/fetch", async (data) => {
  try {
    let url = `users/all?role=${data?.role}`;

    if (data?.role && !data.search) {
      url += `&$page=${data?.page}`;
    }
    if (data?.alllist) {
      url = `users/all?role=${data?.role}&alllist=${data?.alllist}`;
    }
    if(data?.search && data?.role) {
      url += `&$page=${data?.page}&$search=${data?.search}`;
    }
    let res = await axiosInstance.get(url, header);
    return res?.data;
  } catch (error) {
    return error.message;
  }
});

export const SearchFetchUsers = createAsyncThunk("users/all/fetch", async (data) => {
  try {
    let url = `users/all?role=${data?.role}`;

    if(data?.search) {
      url += `&$page=1&$search=${data?.search}`;
    }
    let res = await axiosInstance.get(url, header);
    return res?.data;
  } catch (error) {
    return error.message;
  }
});

export const UserCount = async () => {
  const token = JSON.parse(localStorage.getItem("token"))
  try {
    const { data } = await axiosInstance.get(`users/count`, header);
    return data;
  } catch (error) {
    return error.message;
  }
};

// http://localhost:3000/api/users/:id
export const GetUser = createAsyncThunk("users/one", async (id) => {
  try {
    let data = await axiosInstance.get(`users/${id}`, header);
    return data?.data;
  } catch (error) {
    return error.message;
  }
});

// http://localhost:3000/api/users/:id
export const UpdateUser = async (id, data, contentType) => {
  try {
    const res = await axiosInstance.put(`users/${id}`, data, {
      ...config,
      headers: {
        ...config.headers,
        "Content-Type": contentType || config.headers["Content-Type"],
      },
    });
    toast.success(res?.data?.message);
    return res?.data;
  } catch (error) {
    return error.message;
  }
};

// http://localhost:3000/users/:userId/qualifications/:qualificationId
export const DeleteQualification = async (userId, qualId, contentType) => {
  try {
    let data = await axiosInstance.get(
      `users/${userId}/qualifications/${qualId}`,
      {
        ...config,
        headers: {
          ...config.headers,
          "Content-Type": contentType || config.headers["Content-Type"],
        },
      }
    );
    return data?.data;
  } catch (error) {
    return error.message;
  }
};

// http://localhost:3000/users/:userId/experience/:expId
export const DeleteExperience = async (userId, expId, contentType) => {
  try {
    let data = await axiosInstance.get(`users/${userId}/experience/${expId}`, {
      ...config,
      headers: {
        ...config.headers,
        "Content-Type": contentType || config.headers["Content-Type"],
      },
    });
    return data?.data;
  } catch (error) {
    return error.message;
  }
};

// http://localhost:3000/users/:userId/academic/:academicId
export const DeleteAcademic = async (userId, academicId, contentType) => {
  try {
    let data = await axiosInstance.get(
      `users/${userId}/academic/${academicId}`,
      {
        ...config,
        headers: {
          ...config.headers,
          "Content-Type": contentType || config.headers["Content-Type"],
        },
      }
    );
    return data?.data;
  } catch (error) {
    return error.message;
  }
};

export const Contact = async (data) => {
  try {
    let res = await axiosInstance.post("contact", data);
    toast.success(res?.data?.message);
    return res?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error.message;
  }
};

export const UserSlice = createSlice({
  name: "usersData",
  initialState,
  reducer: {},
  extraReducers: {
    [FetchUsers.pending]: (state) => {
      state.status = "Loading......";
      //   state.usersData = null;
    },
    [FetchUsers.fulfilled]: (state, { payload }) => {
      state.status = "fullfilled";
      state.usersData = payload;
    },
    [FetchUsers.rejected]: (state) => {
      state.status = "idel";
    },
    [FetcJobSeekerhUsers.pending]: (state) => {
      state.status = "Loading......";
      //   state.usersData = null;
    },
    [FetcJobSeekerhUsers.fulfilled]: (state, { payload }) => {
      state.status = "fullfilled";
      state.usersData = payload;
    },
    [FetcJobSeekerhUsers.rejected]: (state) => {
      state.status = "idel";
    },
    [SearchFetchUsers.pending]: (state) => {
      state.status = "Loading......";
      //   state.usersData = null;
    },
    [SearchFetchUsers.fulfilled]: (state, { payload }) => {
      state.status = "fullfilled";
      state.usersData = payload;
    },
    [SearchFetchUsers.rejected]: (state) => {
      state.status = "idel";
    },

    //Get One User
    [GetUser.pending]: (state) => {
      state.status = "Loading......";
      state.loading = true;
      //   state.usersData = null;
    },
    [GetUser.fulfilled]: (state, { payload }) => {
      state.status = "idle";
      state.userData = payload?.user;
      state.completionPercentage = payload?.completionPercentage;
      state.loading = false;
    },
    [GetUser.rejected]: (state) => {
      state.status = "idel";
      state.loading = false;
    },
  },
});

// http://localhost:3000/users/limit?page=2&pageSize=3
export const Pagination = async (type, page, pageSize) => {
  try {
    let data = await axiosInstance.get(
      `users/limit?userType=${type}&$page=${page}&pageSize=${pageSize}`,
      header
    );
    return data?.data;
  } catch (error) {
    return error.message;
  }
};

// http://localhost:3000//exportUser/:id
export const ExportUser = async (type,payload=[]) => {
  try {
    const { data } = await axiosInstance.post(`exportUser?userType=${type}`,payload, {
      responseType: "blob",
    });
    const url = URL.createObjectURL(new Blob([data]));
    const link = Object.assign(document.createElement("a"), {
      href: url,
      download: "usersData.xlsx",
    });
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    return error.message;
  }
};

// http://localhost:3000//selectexportUser
export const SelectedExport = async (payload) => {
  try {
    const { data } = await axiosInstance.post(`selectexportUser`,payload, {
      responseType: "blob",
    });
    const url = URL.createObjectURL(new Blob([data]));
    const link = Object.assign(document.createElement("a"), {
      href: url,
      download: "usersSelectedData.xlsx",
    });
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    return data;
  } catch (error) {
    return error.message;
  }
};

export const UserStatus = async (id, action) => {
  try {
    const { data } = await axiosInstance.put(
      `users/status/${id}`,
      action,
      header
    );
    return data;
  } catch (error) {
    return error.message;
  }
};

export const CvAccessBalance = async () => {
  let res = {};
  try {
    const { data } = await axiosInstance.post("cvaccessupdate", res, header);
    return data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error.message;
  }
};
