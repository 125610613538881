import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const AddLanguageModal = ({ sendDataToParent }) => {
  const { userData } = useSelector((state) => state?.User);
  const [inputFields, setInputFields] = useState([]);

  const handleChange = (id, e) => {
    const { name, type, checked, value } = e.target;

    setInputFields((prevFields) =>
      prevFields.map((field) =>
        field.id === id
          ? {
              ...field,
              [name]: type === "checkbox" ? checked : value,
            }
          : field
      )
    );
  };

  const handleDeleteInput = (id) => {
    const updatedFields = inputFields.filter((field) => field.id !== id);
    setInputFields(updatedFields);
  };

  const handleAddInput = () => {
    const newField = {
      id: Date.now(),
      language: "",
      proficiency: "",
      read: false,
      write: false,
      speak: false,
    };
    setInputFields([...inputFields, newField]);
  };

  useEffect(() => {
    const dataToSend = inputFields.map(
      ({ language, proficiency, read, write, speak }) => ({
        name: language,
        proficiency,
        read,
        write,
        speak,
      })
    );
    sendDataToParent(dataToSend);
  }, [inputFields]);
  return (
    <div>
      {/* Add Button */}
      <div className="d-flex justify-content-between">
        <label htmlFor="exampleFormControlTextarea1">
          <b> Language</b>
          <span className="mandatory">*</span>
        </label>
        <span onClick={handleAddInput} className="p-2 text-success">
          <i className="bi bi-plus-circle " />
        </span>
      </div>

      {/* Displaying Existing Fields */}
      {inputFields.map((field) => (
        <div key={field.id}>
          {/* Your existing form field code */}
          <label>Language:</label>
          <input
            className="form-control"
            type="text"
            name="language"
            placeholder="Language"
            value={field.language || ""}
            onChange={(e) => handleChange(field.id, e)}
          />
          {/* Proficiency Input */}
          <label>Proficiency:</label>
          <select
            name="proficiency"
            onChange={(e) => handleChange(field.id, e)}
            className="form-select me-3"
            aria-label="Default select example"
            value={field.proficiency || ""}
          >
            <option value="" defaultValue>
              Select
            </option>
            <option value="Beginner">Beginner</option>
            <option value="Proficient">Proficient</option>
            <option value="Expert">Expert</option>
          </select>

          {/* Checkbox for Read */}
          <div className="form-check form-check-inline">
            <input
              className="form-check-input mt-3"
              type="checkbox"
              id={`read-${field.id}`}
              name="read" // Specify the name
              checked={field.read}
              onChange={(e) => handleChange(field.id, e)}
            />
            <label className="form-check-label" htmlFor={`read-${field.id}`}>
              Read
            </label>
          </div>

          {/* Checkbox for Write */}
          <div className="form-check form-check-inline">
            <input
              className="form-check-input mt-3"
              type="checkbox"
              id={`write-${field.id}`}
              name="write" // Specify the name
              checked={field.write}
              onChange={(e) => handleChange(field.id, e)}
            />
            <label className="form-check-label" htmlFor={`write-${field.id}`}>
              Write
            </label>
          </div>

          {/* Checkbox for Speak */}
          <div className="form-check form-check-inline">
            <input
              className="form-check-input mt-3"
              type="checkbox"
              id={`speak-${field.id}`}
              name="speak" // Specify the name
              checked={field.speak}
              onChange={(e) => handleChange(field.id, e)}
            />
            <label className="form-check-label" htmlFor={`speak-${field.id}`}>
              Speak
            </label>
          </div>

          {/* Delete Div */}
          <div className="d-flex justify-content-end">
            <span
              onClick={() => handleDeleteInput(field.id)}
              className="p-2 text-danger"
            >
              <i className="bi bi-dash-circle" />
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AddLanguageModal;
