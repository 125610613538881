import React from "react";
import "./Navbar_and_Footer.css";
import FooterLogo from "../../asset/new/images/jobseeker_footer_logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXTwitter,
  faFacebookF,
  faWhatsapp,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer>
      <div className="footer_css text-center">
        <div className="container-fluid">
          <div className="row p-0">
            <div
              className="align-self-center col-12 col-md-4 col-lg-2 d-none d-lg-flex"
              
            >
              <img
                src={FooterLogo}
                className="logo_img_footer d-none d-md-block"
                alt="our_logo"
              />
            </div>
            <div
              className=" align-self-center col-md-4 d-none d-lg-block pl-lg-4"
              
            >
              <p className="mb-0 copyright">Copyright 2022 CAJobPortal</p>
            </div>
            <div
              className=" align-self-center col-12 col-md-6  mb-2 mb-lg-0 "
              
            >
              <div className="container-fluid p-0">
                <div className="row p-0">
                  <div
                    className=" align-self-center col-12 col-sm-9 mb-2 mb-md-0 text-start"
                    
                  >
                    <div
                      className="footer_div2 text-center"
                      style={{ margin: "auto 0px" }}
                    >
                      <li>
                        <Link to={"/about"}>About</Link>
                      </li>
                      <li>
                        <Link to={"/contact"}>Contact&nbsp;Us</Link>
                      </li>
                      <li>
                        <Link to={"/faq"}>Privacy</Link>
                      </li>
                      <li>
                        <Link to={"/recruiter"}>Subscription</Link>
                      </li>
                    </div>
                  </div>
                  <div className="col-12 col-sm-3 text-center text-end ">
                    <Link
                      to="https://www.facebook.com/cajobportal"
                      target="_blank"
                    >
                      <FontAwesomeIcon
                        className="font-awesome footer-icons"
                        icon={faFacebookF}
                      />
                    </Link>{" "}
                    <Link
                      to="https://www.linkedin.com/in/cajobportal/?originalSubdomain=in"
                      target="_blank"
                    >
                      <FontAwesomeIcon
                        className="font-awesome footer-icons"
                        icon={faLinkedinIn}
                      />
                    </Link>{" "}
                    <Link to="https://twitter.com/cajobportal" target="_blank">
                      <FontAwesomeIcon
                        className="font-awesome footer-icons"
                        icon={faXTwitter}
                      />
                    </Link>{" "}
                    <Link
                      to="https://chat.whatsapp.com/E9MIMEtvZCG9HcW6gfl4Vi"
                      target="_blank"
                    >
                      <FontAwesomeIcon
                        className="font-awesome footer-icons"
                        icon={faWhatsapp}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className=" align-self-center col-12 text-center d-block d-lg-none"
              
            >
              <div className="row">
                <div className="col-12 col-md-6">
                  <img
                    src={FooterLogo}
                    className=" d-inline d-lg-none m-auto"
                    alt="our_logo"
                    width="150" height="75"
                  />
                </div>
                <div className="col-12 col-md-6">
                  <span className="d-inline d-lg-none copyright">
                    Copyright reserved 2022 CAJobPortal
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
