import React, { useState, useEffect } from 'react';
import AdminNavbar from './Common/AdminNavbar';
import AdminSideBar from './Common/AdminSideBar';
import axios from 'axios';
import { axiosInstance ,config} from "../../Axios/AxiosInstance";
import toast from "react-hot-toast";

const ContactUsEdit = () => {
//Token
let header = config;
  const [contactDetails, setContactDetails] = useState({
    email: '',
    phone: '',
    address: ''
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch contact details from API
  useEffect(() => {
    axiosInstance.get('contact-us')
      .then(response => {
        setContactDetails(response?.data?.data[0]);
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    axiosInstance.post('contact-us', contactDetails,header)
      .then(response => {
        toast.success("Contact details updated successfully!");
      })
      .catch(error => {
        setError(error.message);
      });
  };
  console.log(contactDetails)

  if (loading) return <p>Loading...</p>;

  return (
    <div>
      {/* ------- Navbar ------- */}
      <AdminNavbar />
      <div className="row m-0">
        <div className="col-md-2 p-0 nav-content">
          {/* ------- Sidebar ------- */}
          <AdminSideBar />
        </div>
        <div className="main-content col-md-10 p-0">
          {/* ------- Main Content  ------- */}
          <h2 className="headline">Edit Contact Us</h2>
          <div className="main-content-child col-md-4">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label className='mb-1'>Email</label>
                <input 
                  type="email" 
                  name="email" 
                  value={contactDetails?.email} 
                  onChange={handleChange} 
                  className="form-control rounded-3"
                  required 
                />
              </div>
              <div className="form-group rounded-3 mt-2">
                <label className='mb-1' >Phone</label>
                <input 
                  type="tel" 
                  name="phone" 
                  value={contactDetails?.phone} 
                  onChange={handleChange} 
                  className="form-control rounded-3"
                  required 
                />
              </div>
              <div className="form-group mt-2">
                <label className='mb-1'>Address</label>
                <textarea 
                  name="address" 
                  value={contactDetails?.address} 
                  onChange={handleChange} 
                  className="form-control rounded-3"
                  required 
                />
              </div>
              <button type="submit " className="btn btn-primary mt-3 btnpf1">
                Save Changes
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUsEdit;
