import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetUser, UpdateUser } from "../../../Redux/Slices/User";

const EditProfile = () => {
  let id = localStorage.getItem("id");

  const { userData } = useSelector((state) => state?.User);
  const [editData, setEditData] = useState(userData);
  const dispatch = useDispatch();

  let name, value;
  const postUserData = (e) => {
    name = e.target.name;
    value = e.target.value;

    setEditData((prevData) => ({ ...prevData, [name]: value }));
  };
  // Submit Button
  const SubmitInfo = async (e) => {
    e.preventDefault();
    let data = {
      name: editData?.name || userData?.name,
      email: userData?.email,
      mobile: editData?.mobile || userData?.mobile,
      experience: {
        designation:
          editData?.designation || userData?.experience[0]?.designation,
        company_name:
          editData?.companyName || userData?.experience[0]?.company_name,
        company_web:
          editData?.companyWebsite || userData?.experience[0]?.company_web,
      },
      gst: editData?.gst || userData?.gst,
    };
    await UpdateUser(id, data, "application/json");
    dispatch(GetUser(id));
    setEditData("");
  };

  useEffect(() => {
    dispatch(GetUser(id));
    if (userData) {
      setEditData(userData);
    }
    document.title = "Edit Profile | CAJobPortal";
  }, [dispatch]);

  return (
    <>
      <div className="container-fluid  bg-light">
        <div className="row">
          <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
            <div className="card  w-100 mt-5 p-2 p-sm-5">
              <h3 className="card-title mb-4">Profile</h3>
              <form
                className="form"
                method="post"
                encType="multipart/form-data"
              >
                <div className="mb-3">
                  {/* ---Name - */}
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label fw-semibold"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control input_css"
                    name="name"
                    placeholder="Name"
                    value={userData?.name}
                    onChange={(e) => postUserData(e)}
                  />
                  <span className="help-block error_text" role="alert"></span>
                </div>
                <div className="mb-3">
                  {/* ---- Designation ------- */}
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label fw-semibold"
                  >
                    Designation
                  </label>
                  <input
                    type="text"
                    className="form-control input_css"
                    placeholder="Designation*"
                    name="designation"
                    onChange={(e) => postUserData(e)}
                    value={userData?.experience[0]?.designation}
                  />
                  <span className="help-block error_text" role="alert"></span>
                </div>
                {/* ---- Company Name ----- */}
                <div className="mb-3">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label fw-semibold"
                  >
                    Company Name
                  </label>
                  <input
                    type="text"
                    className="form-control input_css"
                    name="companyName"
                    value={userData?.experience[0]?.company_name}
                    onChange={(e) => postUserData(e)}
                    placeholder="Company Name"
                  />
                  <span className="help-block error_text" role="alert"></span>
                </div>
                {/* ----- Company Website---- */}
                <div className="mb-3">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label fw-semibold"
                  >
                    Company Website
                  </label>
                  <input
                    type="text"
                    className="form-control input_css"
                    value={userData?.experience[0]?.company_web}
                    placeholder="Company Website"
                    name="companyWebsite"
                    onChange={(e) => postUserData(e)}
                  />
                  <span className="help-block error_text" role="alert"></span>
                </div>
                {/* ------ Mobile Number ------ */}
                <div className="mb-3">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label fw-semibold"
                  >
                    Mobile Number
                  </label>

                  <input
                    type="text"
                    className="form-control input_css"
                    name="mobile"
                    placeholder="Mobile Number"
                    value={userData?.mobile}
                    onChange={(e) => postUserData(e)}
                    disabled={userData?.mobile ? true : false}
                  />
                </div>
                {/* ---- Email ---- */}
                <div className="mb-3">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label fw-semibold"
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control input_css"
                    name="email"
                    placeholder="Email ID*"
                    value={userData?.email}
                    disabled
                  />
                </div>
                {/* ----- Gst Number ----- */}
                <div className="mb-3">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label fw-semibold"
                  >
                    GST
                  </label>
                  <input
                    type="text"
                    className="form-control input_css"
                    name="gst"
                    placeholder="GST Number"
                    value={userData?.gst}
                    onChange={(e) => postUserData(e)}
                  />
                </div>

                <div className="mb-3 d-flex align-self-center">
                  {/* Company Logo */}
                  <div
                    style={{
                      display: "flex",
                      gap: 10,
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    
                  </div>
                </div>

                <div className="d-flex justify-content-end">
                  
                  <button
                    onClick={SubmitInfo}
                    type="submit"
                    className="save_btn"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
