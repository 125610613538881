import React, { useEffect, useState } from "react";
import AdminNavbar from "../../Admin/Common/AdminNavbar";
import { useDispatch, useSelector } from "react-redux";
import { FetchJobTypes } from "../../../Redux/Slices/JobType";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FetchIndustries } from "../../../Redux/Slices/Industry";
import { FetchSkill } from "../../../Redux/Slices/Skill";
import { FetchQualifications } from "../../../Redux/Slices/Qualification";
import { yearOption,thousands, thousandOption, lacOption } from "../../../Helper/Option";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Location from "../../Admin/Location";
import { PostJob } from "../../../Redux/Slices/JobPost";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { useNavigate } from "react-router-dom";

const JobPost = () => {
  const role = localStorage.getItem("role");
  const [error, setError] = useState({});
  const { jobTypesData } = useSelector((state) => state?.JobType);
  const { industriesData } = useSelector((state) => state?.Industry);
  const { skillsData } = useSelector((state) => state?.Skill);
  const { qualificationsData } = useSelector((state) => state?.Qualification);
  const [startDate, setStartDate] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [postDetails, setPostDetails] = useState({});
  const [applicationMode, setApplicationMode] = useState("");

  //   -----------Multi Select ------
  const [selectedqualification, setselectedqualification] = useState([]);
  const [selectedSkills, setselectedSkills] = useState([]);
  const [selectedIndustries, setselectedIndustries] = useState("");
  const animatedComponents = makeAnimated();

  // Transform API data into the desired format
  const options = skillsData?.docs?.map((item) => ({
    value: item.name,
    label: item.name,
    id: item._id,
  }));
  const options2 = qualificationsData.map((item) => ({
    value: item.name,
    label: item.name,
    id: item._id,
  }));
  const options3 = industriesData?.docs?.map((item) => ({
    value: item.name,
    label: item.name,
    id: item._id,
  }));

  // This function will be called whenever the selected values change
  const handleSelectChange = (qualification) => {
    const values = qualification.map((option) => option.id);
    setselectedqualification(values);
  };
  const handleSelectChange2 = (skills) => {
    const values = skills.map((option) => option.id);
    setselectedSkills(values);
  };
  const handleSelectChange3 = (industry) => {
    const value = industry.id;
    setselectedIndustries(value);
  };

  //---------- Form Validation -------------

  const validation = () => {
    let error = {};

    // Validate job type
    if (!postDetails.jobtype) {
      error.jobtype = "JobType is Required";
    }

    // Validate job title
    if (!postDetails.jobtitle) {
      error.jobtitle = "Job Title is Required";
    }

    // Validate company name
    if (!postDetails.company_name) {
      error.company_name = "Company Name is Required";
    }

    // Validate job description
    if (!text) {
      error.description = "Job Description is Required";
    }
    // Validate industry
    if (!selectedIndustries) {
      error.industry = "Industry is Required";
    }

    // Validate skills
    if (!selectedSkills || selectedSkills.length === 0) {
      error.skills = "At least one Skill is Required";
    }

    // Validate qualification
    if (!selectedqualification || selectedqualification.length === 0) {
      error.qualification = "At least one Qualification is Required";
    }

    // Validate experience
    if (!postDetails.exp_from || !postDetails.exp_to) {
      error.experience = "Experience Range is Required";
    }

    // Validate CTC
    if (postDetails?.ctcdisplay === "true") { 
      if (!postDetails.ctc_from_lacs || !postDetails.ctc_to_lacs) {
        error.ctc = "CTC Range is Required";
      }
    }
  

    // Validate validity date
    if (!startDate) {
      error.validity = "Job Validity Date is Required";
    }

    // Validate apply through
    if (!applicationMode) {
      error.apply_through = "Apply Through is Required";
    }

    // Validate form link if apply through is 'Form'
    if (applicationMode === "Form" && !postDetails.form_link) {
      error.form_link = "Form Link is Required";
    }

    // Validate category
    if (!postDetails.category) {
      error.category = "Job Category is Required";
    }

    return error;
  };

  //Onchange value
  let name, value;
  const postUserData = (e) => {
    name = e.target.name;
    value = e.target.value;
    if (e.target.value) {
      setError("");
    }

    setPostDetails({ ...postDetails, [name]: value });
    if (name === "jobtitle") {
      if (value.length === 0) {
        setError({ ...error, jobtitle: "Job Title is required" });
        setPostDetails({ ...postDetails, jobtitle: "" });
      } else {
        setError({ ...error, jobtitle: "" });
        setPostDetails({ ...postDetails, jobtitle: value });
      }
    }
    if (name === "company_name") {
      if (value.length === 0) {
        setError({ ...error, about_company: "Company Name is required" });
        setPostDetails({ ...postDetails, company_name: "" });
      } else {
        setError({ ...error, about_company: "" });
        setPostDetails({ ...postDetails, company_name: value });
      }
    }

    if (name === "description") {
      if (value.length === 0) {
        setError({ ...error, description: "Description is required" });
        setPostDetails({ ...postDetails, description: "" });
      } else {
        setError({ ...error, description: "" });
        setPostDetails({ ...postDetails, description: value });
      }
    }
  };

  const [text, setText] = useState('');

  const handleChange = (value) => {
    setText(value);
  };

  const editorStyle = {
    height: '180px',
    backgroundColor: 'white',
  };


  // Location

  const [locationData, setLocationData] = useState({
    Country: "",
    State: "",
    Cities: [],
  });
  // Function to receive Location data from the child
  const receiveDataFromChild = (data) => {
    setLocationData(data);
  };

  // Handle Applied Through

  const handleapplied = (e) => {
    setApplicationMode(e.target.value);
  };

  const handleSubmbit = async (e) => {
    e.preventDefault();
    let ErrorList = validation();
    setError(ErrorList);
    if (Object.keys(ErrorList).length === 0) {
      let data = {
        // user_id: id,
        type: postDetails?.jobtype,
        title: postDetails?.jobtitle,
        company_name: postDetails?.company_name,
        about_company: postDetails?.about_company,
        description: text,
        country: locationData?.Country,
        cities: locationData?.Cities,
        state: locationData?.State,
        industry: selectedIndustries,
        skills: selectedSkills,
        qualification: selectedqualification,
        other_skill: postDetails?.other_skills,
        exp: {
          from: postDetails?.exp_from,
          to: postDetails?.exp_to,
        },
        ctc: {
          from_lac: postDetails?.ctc_from_lacs,
          from_thousand: postDetails?.ctc_from_thousand,
          to_lac: postDetails?.ctc_to_lacs,
          to_thousand: postDetails?.ctc_to_thousand,
        },
        ctc_display: postDetails?.ctcdisplay,
        validity: startDate,
        apply_through: applicationMode,
        form_link: postDetails?.form_link,
        website_link: postDetails?.website_link,
        category: postDetails?.category,
      };
      setError({});
      let res = await PostJob(data);

      if (res?.success && role === "Employer") {
        navigate("/employer/posted-jobs");
        return;
      }
      if (res?.success && role === "Admin") {
        navigate("/admin/postedjobs");
        return;
      }
    }
  };
  useEffect(() => {
    let data = {
      page: 1,
      search:"",
      limit: 50
    };
    dispatch(FetchJobTypes());
    dispatch(FetchIndustries(data));
    dispatch(FetchSkill(data));
    dispatch(FetchQualifications());
    document.title = "Post Job | CAJobPortal";
  }, []);

  return (
    <div>
        {role === "Admin" && <AdminNavbar />}

        <div className="container-fluid bg-light">
          <div className="row">
            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 mt-3">
              <h5>Post Job</h5>
              <form
                className="mt-3 form"
                id="userForm"
                method="post"
                name="userForm"
              >
                {/* ------- Job Type --------- */}
                <span style={{ color: "red" }}> {error.jobtype} </span>
                <select
                  name="jobtype"
                  onChange={(e) => postUserData(e)}
                  className="form-select mb-3 two-in-one"
                  aria-label="Default select example"
                >
                  <option value={""}>Select Job Type </option>
                  {jobTypesData?.map((value, index) => (
                    <option key={index + 1} value={`${value?._id}`}>
                      {value?.name}
                    </option>
                  ))}
                </select>
                {/* -------- Job title--------- */}

                <div className="mb-3">
                  <span style={{ color: "red" }}> {error.jobtitle} </span>
                  <input
                    id="jobTitle"
                    name="jobtitle"
                    onChange={(e) => postUserData(e)}
                    type="text"
                    className="form-control"
                    placeholder="Job Title *"
                  />
                </div>
                {/* -------- Company name --------- */}
                <span style={{ color: "red" }}> {error.company_name} </span>

                <div className="mb-3">
                  <input
                    id="company_name"
                    name="company_name"
                    type="text"
                    className="form-control "
                    onChange={(e) => postUserData(e)}
                    placeholder="Company Name *"
                  />
                  {/* -------- About Company --------- */}
                </div>
                <span style={{ color: "red" }}> {error.about_company} </span>

                <div className="mb-3">
                  <textarea
                    id="about_company"
                    name="about_company"
                    onChange={(e) => postUserData(e)}
                    rows={1}
                    placeholder="About Company"
                    className="form-control"
                  />
                  {/* --------Job Description --------- */}
                </div>

                <div className="mb-3">
                  <div>
                    <p>
                      <b>
                        Job Description <span> *</span>
                      </b>
                      <span style={{ color: "red" }}>
                        {" "}
                        {error.description}{" "}
                      </span>
                    </p>
                    <div>
                    <ReactQuill
                      theme="snow"
                      value={text}
                      onChange={handleChange}
                      style={editorStyle}
                    />
                    </div>
                   
                  </div>
                </div>
                {/* -------- Job Category --------- */}
                <p style={{marginTop:"60px"}}>
                  <b>
                    Category<span></span>
                  </b>
                  <span style={{ color: "red" }}>{error.category} </span>
                </p>
                <select
                  name="category"
                  onChange={(e) => postUserData(e)}
                  className="form-select mb-3 two-in-one"
                  aria-label="Default select example"
                >
                  <option value={""}>Select Job Category</option>
                  <option value={"Temporary"}>Temporary</option>
                  <option value={"Permanent"}>Permanent</option>
                  <option value={"Freelance"}>Freelance</option>
                </select>
                {/* --------- Location -------- */}

                <Location sendDataToParent={receiveDataFromChild} />
                {/* ---Industry Option --- */}
                <p>
                  <b>
                    Industry <span> *</span>
                  </b>
                  <span style={{ color: "red" }}>{error.industry} </span>
                </p>
                <Select
                  className="single-select"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={options3}
                  onChange={handleSelectChange3}
                />
                {/* ---Qualification Option --- */}
                <p className="mt-2">
                  <b>
                    Qualification <span> *</span>
                  </b>
                  <span style={{ color: "red" }}>{error.qualification} </span>
                </p>
                <Select
                  className="multiple-select"
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  options={options2}
                  onChange={handleSelectChange}
                />
                {/* --- Skills Option --- */}
                <p className="mt-2">
                  <b>
                    Skills <span> *</span>
                  </b>
                  <span style={{ color: "red" }}>{error.skills} </span>
                </p>
                <div className="container-fluid mb-3 p-0">
                  <div className="row">
                    <div className="col-12 col-sm-6 ">
                      <Select
                        className="multiple-select"
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        options={options}
                        onChange={handleSelectChange2}
                      />
                    </div>
                    {/* Other skills */}
                    <div className="col-12 col-sm-6 mt-2 mt-sm-0">
                      <input
                        type="text"
                        className="form-control"
                        name="other_skills"
                        placeholder="Other Skill name"
                        onChange={(e) => postUserData(e)}
                      />
                    </div>
                  </div>
                </div>
                <p>
                  <b>
                    Experience <span> *</span>
                  </b>
                </p>
                {/* Experience */}

                <div className="container-fluid mb-3 p-0">
                  <span style={{ color: "red" }}>{error.experience} </span>
                  <div className="row">
                    <div className="col-12 col-sm-6 ">
                      <select
                        name="exp_from"
                        onChange={(e) => postUserData(e)}
                        className="form-select mb-3 two-in-one"
                        aria-label="Default select example"
                      >
                        <option defaultValue>All</option>
                        {yearOption?.map((value) => (
                          <option key={value + 2} value={value}>
                            {`${value} Year`}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-sm-6 mt-2 mt-sm-0">
                      <select
                        name="exp_to"
                        onChange={(e) => postUserData(e)}
                        className="form-select mb-3 two-in-one"
                        aria-label="Default select example"
                      >
                        <option defaultValue>All</option>
                        {yearOption?.map((value) => (
                          <option key={value + 2} value={value}>
                            {`${value} Year`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/* Ctc display */}
                <div className="container-fluid mb-3 p-0">
                  <div className="row form-group">
                    <div className="col-md-9 ">
                      <p>CTC will be Displayed or not</p>

                      <div className="col-md-6 pl-0 ">
                        <div
                          className="d-flex radio justify-content-start p-0"
                          style={{ gap: 10 }}
                        >
                          <input
                            type="radio"
                            className="input_radio"
                            name="ctcdisplay"
                            onChange={(e) => postUserData(e)}
                            value={true}
                          />
                          <label className="form-check-label">Yes</label>
                          <input
                            type="radio"
                            className="input_radio"
                            name="ctcdisplay"
                            value={false}
                            onChange={(e) => postUserData(e)}
                          />
                          <label className="form-check-label">No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* --------- CTC ------- */}
                <p>
                  <b>
                    CTC Range (in Lakh)<span> *</span>
                  </b>
                  <span style={{ color: "red" }}>{error.ctc} </span>
                </p>
                <div className="container-fluid mb-3 p-0">
                  <p>From</p>
                  <div className="row mb-2">
                    <div className="col-12 col-sm-6 ">
                      <select
                        name="ctc_from_lacs"
                        onChange={(e) => postUserData(e)}
                        className="form-select mb-3 two-in-one"
                        aria-label="Default select example"
                      >
                        <option defaultValue>All</option>
                        <option value={""}>Select</option>
                        {lacOption?.map((value) => (
                          <option key={value + 2} value={value}>
                            {`${value} Lacs`}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-sm-6 mt-2 mt-sm-0">
                      <select
                        name="ctc_from_thousand"
                        onChange={(e) => postUserData(e)}
                        className="form-select mb-3 two-in-one"
                        aria-label="Default select example"
                      >
                        <option defaultValue>All</option>
                        <option value={""}>Select</option>
                        {thousandOption?.map((value) => (
                          <option key={value + 2} value={value}>
                            {`${value} Thousands`}
                          </option>
                        ))}
                      </select>
                      {/* ---------- to ------- */}
                    </div>
                  </div>
                  <p>To</p>
                  <div className="row mb-2">
                    <div className="col-12 col-sm-6 ">
                      <select
                        name="ctc_to_lacs"
                        onChange={(e) => postUserData(e)}
                        className="form-select mb-3 two-in-one"
                        aria-label="Default select example"
                      >
                        <option defaultValue>All</option>
                        <option value={""}>Select</option>
                        {lacOption?.map((value) => (
                          <option key={value + 2} value={value}>
                            {`${value} Lacs`}
                          </option>
                        ))}
                      </select>
                      <span
                        className="help-block error_text"
                        role="alert"
                      ></span>
                    </div>
                    <div className="col-12 col-sm-6 mt-2 mt-sm-0">
                      <select
                        name="ctc_to_thousand"
                        onChange={(e) => postUserData(e)}
                        className="form-select mb-3 two-in-one"
                        aria-label="Default select example"
                      >
                        <option defaultValue>All</option>
                        <option value={""}>Select</option>
                        {thousandOption?.map((value) => (
                          <option key={value + 2} value={value}>
                            {`${value} Thousands`}
                          </option>
                        ))}
                      </select>
                      <span
                        className="help-block error_text"
                        role="alert"
                      ></span>
                    </div>
                  </div>
                </div>
                {/* Job Validity */}
                <div className="container-fluid mb-3 p-0">
                  <div className="row">
                    <span style={{ color: "red" }}>{error.validity} </span>

                    <div className="col-12 col-sm-6 ">
                      <div
                        className="input-group input-append date"
                        id="datePicker"
                      >
                        <DatePicker
                          className="form-control jobValidity "
                          placeholderText="Job Validity"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />
                        <span className="input-group-addon add-on input-group-text">
                          <i className="bi bi-calendar-plus" />
                        </span>
                      </div>
                      <span
                        className="help-block error_text"
                        role="alert"
                      ></span>
                    </div>
                    {/* ------- Applie Through---- */}
                    <div className="col-12 col-sm-6 mt-2 mt-sm-0">
                      <span style={{ color: "red" }}>
                        {error.apply_through}{" "}
                      </span>

                      <select
                        id="applyThrough"
                        name="applyThrough"
                        className="form-select custom-select"
                        onChange={(e) => handleapplied(e)}
                      >
                        <option value>Apply Through *</option>
                        <option value="Resume">Resume</option>
                        <option value="Form">Form</option>
                        <option value="Website">Website</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="container-fluid p-0">
                  {applicationMode === "Website" && (
                    <div className="row" id="qis">
                      <div className="col-md-6  col-sm-12 col-xs-12 col-lg-6 ">
                        <label htmlFor="q1" className=" col-form-label">
                          Website Link
                        </label>
                        <input
                          id="website_link"
                          name="website_link"
                          type="text"
                          onChange={(e) => postUserData(e)}
                          className="form-control"
                        />
                      </div>
                    </div>
                  )}
                  {applicationMode === "Form" && (
                    <div className="row" id="formLink">
                      <div className="col-md-6  col-sm-12 col-xs-12 col-lg-6 ">
                        <label htmlFor="q1" className=" col-form-label">
                          Form Link <span className="mandatory">*</span>{" "}
                        </label>
                        <span style={{ color: "red" }}>{error.form_link} </span>

                        <input
                          id="link"
                          name="form_link"
                          type="text"
                          className="form-control"
                          onChange={(e) => postUserData(e)}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-grid gap-2 col-6 mx-auto mt-1 my-3 w-25">
                  <button
                    onClick={handleSubmbit}
                    className="submit_btn py-1"
                    id="submit_btn_create_job"
                    type="submit"
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
  );
};

export default JobPost;
