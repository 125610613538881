import React, { useEffect, useState } from "react";
import AdminNavbar from "./Common/AdminNavbar";
import AdminSideBar from "./Common/AdminSideBar";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { BarLoader } from "react-spinners";
import {
  AddJobType,
  FetchJobTypes,
  EditJobType,
  UpdateJobType,
  DeleteJobTypes,
} from "../../Redux/Slices/JobType";

const JobType = () => {
  const [data, setData] = useState("");
  const [editData, setEditData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const { jobTypesData } = useSelector((state) => state?.JobType);

  //Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    let inputData = { name: data };
    await AddJobType(inputData);
    dispatch(FetchJobTypes());
    setData("");
  };

  // Edit
  const handleEdit = async (id) => {
    const data = await EditJobType(id);
    setEditData(data?.data[0]);
  };

  //Update
  const handleUpdate = async (id) => {
    let inputData = { name: editData.name };
    await UpdateJobType(inputData, id);
    dispatch(FetchJobTypes());
  };

  // Delete
  const handleDelete = async (id) => {
    await DeleteJobTypes(id);
    dispatch(FetchJobTypes());
  };

  useEffect(() => {
    if (jobTypesData?.length === 0) {
      dispatch(FetchJobTypes());
    }
    document.title = "JobType | Admin";

  }, [dispatch]);

  // Format the date
  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const formattedDate = dateObject
      .toISOString()
      .replace("T", " ")
      .replace("Z", "");
    return formattedDate.slice(0, -5);
  };
  return (
    <div>
      {/* ------- Navbar ------- */}
      <AdminNavbar />
      <div className="row m-0">
        <div className="col-md-2 p-0 nav-content">
          {/* ------- Sidebar ------- */}
          <AdminSideBar />
        </div>
        <div className="main-content col-md-10 p-0">
          {/* ------- Main Content  ------- */}
          <h2 className="headline">JobType List</h2>
          <div className="main-content-child">
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="add-btn"
                data-bs-toggle="modal"
                data-bs-target="#addNameModal"
              >
                Add JobType
              </button>
              {/* --- Search Input --- */}

              <input
                type="search"
                id="form1"
                placeholder="Search"
                className="search-list"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            {/* ----Modal Box --- */}
            <div
              className="modal fade"
              id="addNameModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Add JobType
                    </h5>
                    {/* Close Modal */}
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="mb-3">
                        <label
                          htmlFor="recipient-name"
                          className="col-form-label"
                        >
                          JobType:
                        </label>
                        <input
                          onChange={(e) => setData(e.target.value)}
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="JobType Name"
                          id="recipient-name"
                        />
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button
                      onClick={handleSubmit}
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ----Edit Modal Box --- */}
            <div
              className="modal fade"
              id="editNameModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Edit JobType
                    </h5>
                    {/* Close Modal */}
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="mb-3">
                        <label
                          htmlFor="recipient-name"
                          className="col-form-label"
                        >
                          Degree:
                        </label>
                        <input
                          value={editData?.name || ""}
                          onChange={(e) =>
                            setEditData({ ...editData, name: e.target.value })
                          }
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="JobType Name"
                        />
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button
                      onClick={(e) => handleUpdate(editData?._id)}
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ------ Table -------- */}

            <div className="main-panel">
              <div className="content-wrapper ">
                <div className="col-md-12 detail-page_margin">
                  <div className="">
                    {
                      jobTypesData.length > 0 ? (
                        <table
                          id="example"
                          className="table table-striped table-bordered datatable responsive"
                        >
                          <thead>
                            <tr>
                              <th>Id </th>
                              <th>JobType</th>
                              <th>Created At</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {jobTypesData
                              .filter((item) =>
                                item.name
                                  .toLowerCase()
                                  .includes(searchQuery.toLowerCase())
                              )
                              .map((item, index) => (
                                <tr key={index + 1}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{item?.name}</td>
                                  <td>{formatDate(item?.created_at)}</td>
                                  <td>
                                    <button
                                      onClick={() => handleEdit(item?._id)}
                                      className="edit-btn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#editNameModal"
                                    >
                                      <FaEdit className="me-1 fs-6 " /> Edit
                                    </button>
                                    <button
                                      type="button"
                                      value={item?._id}
                                      onClick={() => handleDelete(item?._id)}
                                      className="delete-btn"
                                    >
                                      <AiFillDelete className="me-1 fs-6 " />{" "}
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      ) : null /* BarLoader is not rendered here */
                    }
                    {jobTypesData.length === 0 && <BarLoader color="#36d7b7" />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobType;
