import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance, config } from "../../Axios/AxiosInstance";
import toast from "react-hot-toast";

//Token
let header = config;

const initialState = {
  companyData: [],
  status: "idel",
  loading: false,
};

// http://localhost:3000/api/company
export const FetchCompany = createAsyncThunk(
  "company/all/fetch",
  async () => {
    try {
      let res = await axiosInstance.get("company/all");
      return res?.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const CompanySlice = createSlice({
  name: "companyData",
  initialState,
  reducer: {},
  extraReducers: {
    [FetchCompany.pending]: (state) => {
      state.status = "Loading......";
      // state.industriesData = null;
    },
    [FetchCompany.fulfilled]: (state, { payload }) => {
      state.status = "idle";
      state.companyData = payload;
    },
    [FetchCompany.rejected]: (state) => {
      state.status = "idel";
    },
  },
});
