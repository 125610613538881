import React, { useCallback, useEffect, useState } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  lacOption,
  monthOption,
  thousandOption,
  yearNoOption,
} from "../../../../Helper/Option";
import {
  DeleteExperience,
  GetUser,
  UpdateUser,
} from "../../../../Redux/Slices/User";
import { GoPlus } from "react-icons/go";
import { FetchCompany } from "../../../../Redux/Slices/Company";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { CiEdit } from "react-icons/ci";

const Experience = ({userData}) => {
  let id = localStorage.getItem("id");
  const animatedComponents = makeAnimated();
  const { companyData } = useSelector((state) => state?.Company);
  const [editData, setEditData] = useState(userData);
  const [experience, setExperience] = useState([]);
  const [error, setError] = useState({});
  const [isCurrent, setIsCurrent] = useState(false);
  const [noCompany, setNoCompany] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const dispatch = useDispatch();
  const options =
    companyData?.map((item) => ({
      value: item.name,
      label: item.name,
      id: item._id,
    })) || [];

  const handleSelectChange = useCallback((company) => {
    const values = company?.id;
    setSelectedCompany(values);
  }, []);

  //----Add ----
  const handleEdit = async () => {
    setEditData(userData);
  };

  //------------ Edit One Experience ---------

  const handleEditExperience = async (value) => {
    setError("")
    const fromvalues = value?.duration?.from;
    const part1 = fromvalues?.split("-");
    const year = part1[0];
    const month = part1[1];

    const tovalues = value?.duration?.to;

    let year1, month1;

    const part2 = tovalues?.split("-");
    year1 = part2[0];
    month1 = part2[1];

    let data = {
      designation: value?.designation,
      company_name: value?.company_name,
      is_current: value?.is_current,
      notice: value?.notice_period,
      profile_description: value?.profile_description,
      duration: {
        fromyear: year,
        frommonth: month,
        toyear: year1,
        tomonth: month1,
      },
      company: value?.company,
      ctcfromyear: userData?.current_ctc?.lac,
      ctcfrommonth: userData?.current_ctc?.thousand,

      _id: value?._id,
    };
    const company = {
      value: value?.company?._id,
      label: value?.company?.name,
    };

    setSelectedCompany(company);
    setIsCurrent(value?.is_current);
    setExperience(data);
  };

  //----Delete ----

  const handleDelete = async (expId) => {
    let userId = await userData?._id;
    await DeleteExperience(userId, expId, "application/json");
    dispatch(GetUser(id));
  };

  // ------------- OnChange Validation --------------

  let name, value;
  const postUserData = (e) => {
    name = e.target.name;
    value = e.target.value;

    setEditData((prevData) => ({ ...prevData, [name]: value }));
    if (name === "designation") {
      if (value.length === 0) {
        setError({ ...error, designation: "Designation is Required" });
        setEditData({ ...editData, designation: "" });
      } else {
        setError({ ...error, designation: "" });
        setEditData({ ...editData, designation: value });
      }
    }
    if (name === "profile_description") {
      if (value.length === 0) {
        setError({
          ...error,
          profile_description: "Profile Description is Required",
        });
        setEditData({ ...editData, profile_description: "" });
      } else {
        setError({ ...error, profile_description: "" });
        setEditData({ ...editData, profile_description: value });
      }
    }

    if (name === "fromyear") {
      setError((prevError) => ({
        ...prevError,
        fromyear: value === "" ? "Year is Required" : "",
        dateRange:
          editData.toyear && parseInt(value) > parseInt(editData.toyear)
            ? "From year cannot be greater than To year"
            : "",
      }));
    }

    if (name === "toyear") {
      setError((prevError) => ({
        ...prevError,
        toyear: value === "" ? "Year is Required" : "",
        dateRange:
          editData.fromyear && parseInt(editData.fromyear) > parseInt(value)
            ? "From year cannot be greater than To year"
            : "",
      }));
    }
  };
  // ------------- OnChange Edit Validation --------------

  const postNewUserData = (e) => {
    const { name, value } = e.target;
    setExperience((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "designation") {
      if (value.length === 0) {
        setError((prevError) => ({
          ...prevError,
          designation: "Designation is Required",
        }));
      } else {
        setError((prevError) => ({
          ...prevError,
          designation: "",
        }));
      }
    }

    if (name === "profile_description") {
      if (value.length === 0) {
        setError((prevError) => ({
          ...prevError,
          profile_description: "Profile Description is Required",
        }));
      } else {
        setError((prevError) => ({
          ...prevError,
          profile_description: "",
        }));
      }
    }

    if (name === "fromyear") {
      setError((prevError) => ({
        ...prevError,
        fromyear: value === "" ? "Year is Required" : "",
        dateRange:
          experience.toyear && parseInt(value) > parseInt(experience.toyear)
            ? "From year cannot be greater than To year"
            : "",
      }));
    }

    if (name === "toyear") {
      setError((prevError) => ({
        ...prevError,
        toyear: value === "" ? "Year is Required" : "",
        dateRange:
          experience.fromyear && parseInt(experience.fromyear) > parseInt(value)
            ? "From year cannot be greater than To year"
            : "",
      }));
    }
  };

  //  -------------- Update  --------------
  const handleUpdate = async (id) => {
    let data = {
      designation: editData?.designation,
      company: selectedCompany,
      company_name: editData?.company_name,
      duration: {
        from: `${editData?.fromyear}-${editData?.frommonth}`,
        to: isCurrent ? "Present" : `${editData?.toyear}-${editData?.tomonth}`,
      },
      profile_description: editData?.profile_description,
      is_current: isCurrent,
      notice_period: editData?.notice,
    };
    let updatedacademic = [...editData?.experience, data];
    let update = {
      experience: updatedacademic,
      current_ctc: {
        lac: parseInt(editData?.ctcfromyear),
        thousand: parseInt(editData?.ctcfrommonth),
      },
    };
    await UpdateUser(id, update, "application/json");
    dispatch(GetUser(id));
    setEditData("");
  };
  //  --------------Edit Update  --------------
  const handleNewUpdate = async (id) => {
    let userid = localStorage.getItem("id");
    let data = {
      designation: experience?.designation,
      company: experience?.company,
      company_name: experience?.company_name,
      duration: {
        from: `${experience?.fromyear || experience?.duration?.fromyear}-${
          experience?.frommonth || experience?.duration?.frommonth
        }`,
        to: experience?.is_current
          ? "Present"
          : `${experience?.toyear || experience?.duration?.toyear}-${
              experience?.tomonth || experience?.duration?.tomonth
            }`,
      },
      profile_description: experience?.profile_description,
      is_current: experience?.is_current,
      notice_period: experience?.notice,
    };
    let updateExp = [
      ...userData?.experience?.filter((exp) => exp._id !== id),
      data,
    ];
    let update = {
      experience: updateExp,
      current_ctc: {
        lac: experience?.ctcfromyear,
        thousand: experience?.ctcfrommonth,
      },
    };
    await UpdateUser(userid, update, "application/json");
    dispatch(GetUser(userid));
    setEditData("");
  };

  // useEffect(() => {
  //   dispatch(GetUser(id));
  // }, [dispatch]);
  useEffect(() => {
    dispatch(FetchCompany());
  }, []);

  return (
    <div className="prfile-card my-3">
      <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-center">
          <h3 className="card-head-line">Experience Details</h3>
        </div>
        <div>
          <button
            onClick={(e) => handleEdit()}
            data-bs-toggle="modal"
            data-bs-target="#editExperience "
            className="btnpf4"
          >
            <GoPlus className="fs-2" />
          </button>
        </div>
      </div>
      {/*------ Experience Details ---- */}

      {userData?.experience?.map((value, index) => (
        <div className=" mt-2 border-bottom" key={`${index}`}>
          <p>
            <span className="prof-details">Designation</span>:{" "}
            {value?.designation}{" "}
            <span className="float-end">
              <CiEdit
                className="profile-delete-btn"
                onClick={(e) => handleEditExperience(value)}
                data-bs-toggle="modal"
                data-bs-target="#editExperienceModal"
              />
              <MdDeleteOutline
                className="profile-delete-btn"
                onClick={() => handleDelete(value?._id)}
              />
            </span>
          </p>
          <p>
            <span className="prof-details">Company</span> :{" "}
            {value?.company?.name}
          </p>
          <p>
            <span className="prof-details">Duration</span> :{" "}
            {value?.duration?.from} to {value?.duration?.to || "Present"}
          </p>
          {value?.is_current && (
            <p>
              <span className="prof-details">Notice Period</span> :{" "}
              {value?.notice_period} Month
            </p>
          )}

          <p>
            <span className="prof-details">Profile Description</span> :{" "}
            {value?.profile_description}
          </p>
        </div>
      ))}
      {/* Add  Modal */}
      <div
        className="modal fade modal-lg"
        id="editExperience"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Experience
              </h5>
              {/* Close Modal */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form className="experience-modal">
                {/* -------- Designation  --------- */}
                <label htmlFor="recipient-name" className="col-form-label">
                  {error.designation ? (
                    <span style={{ color: "red" }}> {error.designation}</span>
                  ) : (
                    "Your Designation"
                  )}
                </label>
                <input
                  name="designation"
                  type="text"
                  className="form-control h-25"
                  placeholder="Your Designation"
                  onChange={(e) => postUserData(e)}
                />

                {/* -------- Company name  --------- */}
                <label htmlFor="recipient-name" className="col-form-label">
                  {error.attempt ? (
                    <span style={{ color: "red" }}> {error.company_name}</span>
                  ) : (
                    "Your Company:"
                  )}
                </label>
                <Select
                  className="single-select"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={options}
                  onChange={handleSelectChange}
                  isDisabled={!noCompany}
                />

                {/* ------- Company Not In List -------- */}

                <div>
                  <div>
                    Is your company not mentioned in the above list?{" "}
                    <input
                      className="form-check-input mt-3"
                      type="checkbox"
                      id="checkboxNoLabel"
                      name="company_name"
                      defaultValue
                      aria-label="..."
                      onChange={(e) => setNoCompany(!noCompany)}
                    />
                  </div>
                </div>

                {!noCompany && (
                  <input
                    name="to"
                    type="text"
                    className="form-control h-25"
                    placeholder="Enter Company Name"
                    onChange={(e) => postUserData(e)}
                  />
                )}

                {/* ------- Is Current Company -------- */}

                <div>
                  Is this your current company?{" "}
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input mt-3"
                      type="radio"
                      value={true}
                      name="iscurrent"
                      id="flexRadioDefault1"
                      onClick={(e) => setIsCurrent(true)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input mt-3"
                      type="radio"
                      name="iscurrent"
                      id="flexRadioDefault2"
                      onClick={(e) => setIsCurrent(false)}
                      defaultChecked
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault2"
                    >
                      No
                    </label>
                  </div>
                </div>

                {/* --------- Started Working From ------------ */}

                <label htmlFor="recipient-name" className="col-form-label">
                  {error.year ? (
                    <span style={{ color: "red" }}> {error.year}</span>
                  ) : (
                    "Started Working From:"
                  )}
                </label>
                <div className="d-flex align-items-center">
                  {/* ---- Year ---- */}
                  <select
                    name="fromyear"
                    onChange={(e) => postUserData(e)}
                    className="form-select me-3"
                    aria-label="Default select example"
                  >
                    <option value={""}>Select Year</option>
                    {yearNoOption?.map((value) => (
                      <option key={value + 2} value={value}>
                        {`${value}`}
                      </option>
                    ))}
                  </select>
                  {/* ----- Month ----- */}
                  <select
                    name="frommonth"
                    onChange={(e) => postUserData(e)}
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option value={""}>Select Month</option>
                    {monthOption?.map((value) => (
                      <option key={value} value={value}>
                        {`${value}`}
                      </option>
                    ))}
                  </select>
                </div>
                {/* --------- Working Till* ------------ */}

                <label htmlFor="recipient-name" className="col-form-label">
                  {error.toyear || error.tomonth || error.dateRange ? (
                    <span style={{ color: "red" }}>
                      {error.toyear || error.tomonth || error.dateRange}
                    </span>
                  ) : (
                    "Working Till"
                  )}
                </label>
                <div className="d-flex align-items-center">
                  {isCurrent ? (
                    <input
                      name="to"
                      type="text"
                      className="form-control h-25"
                      placeholder="To"
                      value={"Present"}
                      disabled
                      onChange={(e) => postUserData(e)}
                    />
                  ) : (
                    <>
                      {/* ---- Year ---- */}
                      <select
                        name="toyear"
                        onChange={(e) => postUserData(e)}
                        className="form-select me-3"
                        aria-label="Default select example"
                      >
                        <option value={""}>Select Year</option>
                        {yearNoOption?.map((value) => (
                          <option key={value + 2} value={value}>
                            {`${value}`}
                          </option>
                        ))}
                      </select>
                      {/* ----- Month ----- */}
                      <select
                        name="tomonth"
                        onChange={(e) => postUserData(e)}
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option value={""}>Select Month</option>
                        {monthOption?.map((value) => (
                          <option key={value} value={value}>
                            {`${value}`}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                </div>

                {/* ----- Current Ctc ---------- */}

                {isCurrent && (
                  <>
                    <label htmlFor="recipient-name" className="col-form-label">
                      {error.year ? (
                        <span style={{ color: "red" }}> {error.year}</span>
                      ) : (
                        "Current Salary:"
                      )}
                    </label>
                    <div className="d-flex align-items-center">
                      {/* ---- lac ---- */}
                      <select
                        name="ctcfromyear"
                        onChange={(e) => postUserData(e)}
                        className="form-select me-3"
                        aria-label="Default select example"
                      >
                        <option value={""}>Select Lacs</option>
                        {lacOption?.map((value) => (
                          <option key={value + 2} value={value}>
                            {`${value} Lacs`}
                          </option>
                        ))}
                      </select>
                      {/* ----- thousands ----- */}
                      <select
                        name="ctcfrommonth"
                        onChange={(e) => postUserData(e)}
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option value={""}>Select Thousands</option>
                        {thousandOption?.map((value) => (
                          <option key={value} value={value}>
                            {`${value} Thousands`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}

                {/* -------- Describe Profile----- */}

                <label htmlFor="recipient-name" className="col-form-label">
                  {error.profile_description ? (
                    <span style={{ color: "red" }}>
                      {" "}
                      {error.profile_description}
                    </span>
                  ) : (
                    "Describe your Job Profile"
                  )}
                </label>
                <div className="mb-3">
                  <textarea
                    id="about_company"
                    name="profile_description"
                    rows={5}
                    placeholder="About Company"
                    className="form-control"
                    onChange={(e) => postUserData(e)}
                  />
                </div>

                {/* -------- Notice Period  --------- */}
                {isCurrent && (
                  <>
                    <label htmlFor="recipient-name" className="col-form-label">
                      {error.notice ? (
                        <span style={{ color: "red" }}> {error.notice}</span>
                      ) : (
                        "Notice Period:"
                      )}
                    </label>
                    <select
                      name="notice"
                      onChange={(e) => postUserData(e)}
                      className="form-select me-3"
                      aria-label="Default select example"
                    >
                      <option value={""} defaultValue>
                        Select Period
                      </option>
                      <option value={"0.5"}>15 Days</option>
                      <option value={"1"}>1 Month</option>
                      <option value={"2"}>2 Month</option>
                      <option value={"3"}>3 Month</option>
                      <option value={">3"}>More than 3 Month</option>
                    </select>
                  </>
                )}
              </form>
            </div>

            {/* Button */}
            <div className="modal-footer">
              <button
                onClick={(e) => handleUpdate(editData?._id)}
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Edit  Modal */}
      <div
        className="modal fade modal-lg"
        id="editExperienceModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Experience
              </h5>
              {/* Close Modal */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form className="experience-modal">
                {/* -------- Designation  --------- */}
                <label htmlFor="recipient-name" className="col-form-label">
                  {error.designation ? (
                    <span style={{ color: "red" }}>{error.designation}</span>
                  ) : (
                    "Your Designation"
                  )}
                </label>
                <input
                  name="designation"
                  type="text"
                  className="form-control h-25"
                  placeholder="Your Designation"
                  value={experience?.designation}
                  onChange={postNewUserData}
                />

                {/* -------- Company name  --------- */}
                <label htmlFor="recipient-name" className="col-form-label">
                  {error.attempt ? (
                    <span style={{ color: "red" }}>{error.company_name}</span>
                  ) : (
                    "Your Company:"
                  )}
                </label>
                <Select
                  className="single-select"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={options}
                  value={selectedCompany}
                  onChange={handleSelectChange}
                  isDisabled={true}
                />

                {/* ------- Company Not In List -------- */}

                <div>
                  <div>
                    Is your company not mentioned in the above list?{" "}
                    <input
                      className="form-check-input mt-3"
                      type="checkbox"
                      id="checkboxNoLabel"
                      name="company_name"
                      defaultValue
                      aria-label="..."
                      onChange={(e) => setNoCompany(!noCompany)}
                    />
                  </div>
                </div>

                {!noCompany && (
                  <input
                    name="to"
                    type="text"
                    className="form-control h-25"
                    placeholder="Enter Company Name"
                    value={experience?.company_name}
                    onChange={postNewUserData}
                  />
                )}

                {/* ------- Is Current Company -------- */}

                <div>
                  Is this your current company?{" "}
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input mt-3"
                      type="radio"
                      value="true"
                      name="is_current"
                      id="flexRadioDefault1"
                      checked={experience?.is_current === true}
                      onChange={() =>
                        setExperience((prev) => ({ ...prev, is_current: true }))
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input mt-3"
                      type="radio"
                      value="false"
                      name="is_current"
                      id="flexRadioDefault2"
                      checked={experience?.is_current === false}
                      onChange={() =>
                        setExperience((prev) => ({
                          ...prev,
                          is_current: false,
                        }))
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault2"
                    >
                      No
                    </label>
                  </div>
                </div>

                {/* --------- Started Working From ------------ */}

                <label htmlFor="recipient-name" className="col-form-label">
                  {error.year ? (
                    <span style={{ color: "red" }}>{error.year}</span>
                  ) : (
                    "Started Working From:"
                  )}
                </label>
                <div className="d-flex align-items-center">
                  {/* ---- Year ---- */}
                  <select
                    name="fromyear"
                    onChange={postNewUserData}
                    className="form-select me-3"
                    aria-label="Default select example"
                    value={
                      experience?.fromyear || experience?.duration?.fromyear
                    }
                  >
                    <option value={""}>Select Year</option>
                    {yearNoOption?.map((value) => (
                      <option key={value + 2} value={value}>
                        {`${value}`}
                      </option>
                    ))}
                  </select>
                  {/* ----- Month ----- */}
                  <select
                    name="frommonth"
                    onChange={postNewUserData}
                    className="form-select"
                    aria-label="Default select example"
                    value={
                      experience?.frommonth || experience?.duration?.frommonth
                    }
                  >
                    <option value={""}>Select Month</option>
                    {monthOption?.map((value) => (
                      <option key={value} value={value}>
                        {`${value}`}
                      </option>
                    ))}
                  </select>
                </div>
                {/* --------- Working Till* ------------ */}

                <label htmlFor="recipient-name" className="col-form-label">
                {error.toyear || error.tomonth || error.dateRange ? (
                    <span style={{ color: "red" }}>
                      {error.toyear || error.tomonth || error.dateRange}
                    </span>
                  ) : (
                    "Working Till"
                  )}
                </label>
                <div className="d-flex align-items-center">
                  {experience?.is_current ? (
                    <input
                      name="to"
                      type="text"
                      className="form-control h-25"
                      placeholder="To"
                      value={"Present"}
                      disabled
                      onChange={postNewUserData}
                    />
                  ) : (
                    <>
                      {/* ---- Year ---- */}
                      <select
                        name="toyear"
                        onChange={postNewUserData}
                        className="form-select me-3"
                        aria-label="Default select example"
                        value={
                          experience?.toyear || experience?.duration?.toyear
                        }
                      >
                        <option value={""}>Select Year</option>
                        {yearNoOption?.map((value) => (
                          <option key={value + 2} value={value}>
                            {`${value}`}
                          </option>
                        ))}
                      </select>
                      {/* ----- Month ----- */}
                      <select
                        name="tomonth"
                        onChange={postNewUserData}
                        className="form-select"
                        aria-label="Default select example"
                        value={
                          experience?.tomonth || experience?.duration?.tomonth
                        }
                      >
                        <option value={""}>Select Month</option>
                        {monthOption?.map((value) => (
                          <option key={value} value={value}>
                            {`${value}`}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                </div>

                {/* ----- Current Ctc ---------- */}

                {experience?.is_current && (
                  <>
                    <label htmlFor="recipient-name" className="col-form-label">
                      {error.year ? (
                        <span style={{ color: "red" }}>{error.year}</span>
                      ) : (
                        "Current Salary:"
                      )}
                    </label>
                    <div className="d-flex align-items-center">
                      {/* ---- Lac ---- */}
                      <select
                        name="ctcfromyear"
                        onChange={postNewUserData}
                        className="form-select me-3"
                        aria-label="Default select example"
                        value={experience?.ctcfromyear}
                      >
                        <option value={""}>Select Lacs</option>
                        {lacOption?.map((value) => (
                          <option key={value + 2} value={value}>
                            {`${value} Lacs`}
                          </option>
                        ))}
                      </select>
                      {/* ----- Thousands ----- */}
                      <select
                        name="ctcfrommonth"
                        onChange={postNewUserData}
                        className="form-select"
                        aria-label="Default select example"
                        value={experience?.ctcfrommonth}
                      >
                        <option value={""}>Select Thousands</option>
                        {thousandOption?.map((value) => (
                          <option key={value} value={value}>
                            {`${value} Thousands`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}

                {/* -------- Describe Profile----- */}

                <label htmlFor="recipient-name" className="col-form-label">
                  {error.profile_description ? (
                    <span style={{ color: "red" }}>
                      {" "}
                      {error.profile_description}
                    </span>
                  ) : (
                    "Describe your Job Profile"
                  )}
                </label>
                <div className="mb-3">
                  <textarea
                    id="about_company"
                    name="profile_description"
                    rows={5}
                    placeholder="About Company"
                    className="form-control"
                    value={experience?.profile_description}
                    onChange={postNewUserData}
                  />
                </div>

                {/* -------- Notice Period  --------- */}
                {experience?.is_current && (
                  <>
                    <label htmlFor="recipient-name" className="col-form-label">
                      {error.notice ? (
                        <span style={{ color: "red" }}>{error.notice}</span>
                      ) : (
                        "Notice Period:"
                      )}
                    </label>
                    <select
                      name="notice"
                      onChange={postNewUserData}
                      className="form-select me-3"
                      aria-label="Default select example"
                      value={experience?.notice}
                    >
                      <option value={""} defaultValue>
                        Select Period
                      </option>
                      <option value={"0.5"}>15 Days</option>
                      <option value={"1"}>1 Month</option>
                      <option value={"2"}>2 Month</option>
                      <option value={"3"}>3 Month</option>
                      <option value={">3"}>More than 3 Month</option>
                    </select>
                  </>
                )}
              </form>
            </div>

            {/* Button */}
            <div className="modal-footer">
              <button
                onClick={() => handleNewUpdate(experience?._id)}
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
