import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance, config } from "../../Axios/AxiosInstance";
import toast from "react-hot-toast";

//Token
let header = config;

// http://localhost:3000/api/degrees/all
export const FetchDegrees = createAsyncThunk("degrees/all/fetch", async () => {
  try {
    let res = await axiosInstance.get("degrees/all", header);
    return res?.data;
  } catch (error) {
    return error.message;
  }
});

// http://localhost:3000/api/degrees
export const AddDegree = async (data) => {
  try {
    let res = await axiosInstance.post("degrees", data, header);
    toast.success(res?.data?.message);
    return res?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error.message;
  }
};

// http://localhost:3000/api/degrees/:id
export const EditDegree = async (id) => {
  try {
    let data = await axiosInstance.get(`degrees/${id}`, header);
    return data?.data;
  } catch (error) {
    return error.message;
  }
};

// http://localhost:3000/api/degrees/:id
export const UpdateDegree = async (data, id) => {
  try {
    const res = await axiosInstance.put(`degrees/${id}`, data, header);
    toast.success(res?.data?.message);
    return res?.data;
  } catch (error) {
    if (
      error?.response?.data?.message.split(" ").splice(0, 3).join(" ") ===
      "E11000 duplicate key"
    ) {
      toast.error("Name Already Exists");
    }
    return error.message;
  }
};

// http://localhost:3000/api/degrees/:id
export const DeleteDegree = async (id) => {
  try {
    let data = await axiosInstance.delete(`degrees/${id}`, header);
    toast.success(data?.data?.message);
    return data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error.message;
  }
};
const initialState = {
  degreesData: [],
  status: "idel",
  loading: false,
};

export const DegreeSlice = createSlice({
  name: "DegreesData",
  initialState,
  reducer: {},
  extraReducers: {
    //Get Degree
    [FetchDegrees.pending]: (state) => {
      state.status = "Loading......";
      // state.degreesData = null;
    },
    [FetchDegrees.fulfilled]: (state, { payload }) => {
      state.status = "success";
      state.degreesData = payload;
    },
    [FetchDegrees.rejected]: (state) => {
      state.status = "idel";
    },
  },
});
