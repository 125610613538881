import React, { useState } from 'react'
import { RxHome } from "react-icons/rx";
import { PiBag } from "react-icons/pi";
import { AiOutlineFolder ,AiOutlineUser } from "react-icons/ai";
import { AiOutlineFile } from "react-icons/ai";
import { PiPackageLight } from "react-icons/pi";
import { NavLink ,useLocation } from 'react-router-dom';

const Sidebar = () => {
  const {pathname} =useLocation();
  return (
    <div className="col-md-2 border-end">
          
          <div className="sidebars">
            <NavLink to={'/employer/dashboard'} className={` ${pathname === '/employer/dashboard' ? 'das-sidenav-active':'das-sidenav'}`} >
              <RxHome /> Home
            </NavLink>
            <NavLink to={'/employer/profile'} className={` ${pathname === '/employer/profile' ? 'das-sidenav-active':'das-sidenav'}`} >
              <AiOutlineUser/> Profile
            </NavLink>
            <NavLink to={'/employer/posted-jobs'} className={` ${pathname === '/employer/posted-jobs' ? 'das-sidenav-active':'das-sidenav'}`} >
              <PiBag /> Posted Job
            </NavLink>
            <NavLink to={'/employer/folders'} className={` ${pathname === '/employer/folders' ? 'das-sidenav-active':'das-sidenav'}`}>
              <AiOutlineFolder /> My Folders
            </NavLink>
            <NavLink to={'/employer/cvaccess'} className={` ${pathname === '/employer/cvaccess' ? 'das-sidenav-active':'das-sidenav'}`} >
              <AiOutlineFile /> CV Access
            </NavLink>
            <NavLink to={'/employer/package'} className={` ${pathname === '/employer/package' ? 'das-sidenav-active':'das-sidenav'}`} >
              <PiPackageLight /> My Package
            </NavLink>
          </div>
        </div>
  )
}

export default Sidebar