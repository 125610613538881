import React, { useEffect, useState } from "react";
import Sidebar from "../../Common/Sidebar";
import { useNavigate } from "react-router-dom";
import NoSubscriptionAlert from "./NoSubscriptionAlert";
import { GetUserSubscriptions } from "../../../Redux/Slices/Package";
import { BeatLoader } from "react-spinners";

const CvAccess = () => {
  const navigate = useNavigate();
  const [salesHistory, setSalesHistory] = useState(null);

  const salesHistoryHandler = async () => {
    let data = await GetUserSubscriptions();
    setSalesHistory(data?.subscriptions);
  };
  useEffect(() => {
    salesHistoryHandler();
  }, []);
  useEffect(() => {
    document.title = "Cv Access | CAJobPortal";
    let data = salesHistory?.filter(
      (item) =>item?.avail_cvsearch > 0
    )?.length;
    if (data) {
      navigate("/employer/candidatesearch");
    }
  }, [salesHistory]);
  return (
    <div className="m-5">
      <div className="row ">
        {/* ------------ SideBar ------------- */}
        <Sidebar />
        {!salesHistory ? (
          <div className="col-md-10">
            <h1>Cv Access</h1>
            <BeatLoader color="#0cffdf" size={15} />
          </div>
        ) : (
          <div className="col-md-10">
            <h1>Cv Access</h1>
            {/* Alert */}
            <NoSubscriptionAlert salesHistory={salesHistory} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CvAccess;
