import React, { useEffect, useState } from "react";
import AdminNavbar from "./Common/AdminNavbar";
import AdminSideBar from "./Common/AdminSideBar";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import {
  AddDegree,
  FetchDegrees,
  DeleteDegree,
  EditDegree,
  UpdateDegree,
} from "../../Redux/Slices/Degree";
import { useDispatch, useSelector } from "react-redux";
import { BarLoader } from "react-spinners";

const Degrees = () => {
  const [data, setData] = useState("");
  const [editData, setEditData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const { degreesData } = useSelector((state) => state?.Degrees);

  //Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    let inputData = { name: data };
    await AddDegree(inputData);
    dispatch(FetchDegrees());
    setData("");
  };

  // Edit
  const handleEdit = async (id) => {
    const data = await EditDegree(id);
    setEditData(data?.data[0]);
  };

  //Update
  const handleUpdate = async (id) => {
    let inputData = { name: editData.name };
    await UpdateDegree(inputData, id);
    dispatch(FetchDegrees());
  };

  // Delete
  const handleDelete = async (id) => {
    await DeleteDegree(id);
    dispatch(FetchDegrees());
  };

  useEffect(() => {
    if (degreesData?.length === 0) {
      dispatch(FetchDegrees());
    }
    document.title = "Degrees | Admin";

  }, [dispatch]);

  // Format the date
  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const formattedDate = dateObject
      .toISOString()
      .replace("T", " ")
      .replace("Z", "");
    return formattedDate.slice(0, -5);
  };

  return (
    <div>
      {/* ------- Navbar ------- */}
      <AdminNavbar />
      <div className="row m-0">
        <div className="col-md-2 p-0 nav-content">
          {/* ------- Sidebar ------- */}
          <AdminSideBar />
        </div>
        <div className="main-content col-md-10 p-0">
          {/* ------- Main Content  ------- */}
          <h2 className="headline">Degree List</h2>
          <div className="main-content-child">
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="add-btn"
                data-bs-toggle="modal"
                data-bs-target="#addNameModal"
              >
                Add Degree
              </button>
              {/* --- Search Input --- */}

              <input
                type="search"
                id="form1"
                placeholder="Search"
                className="search-list"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            {/* ----Modal Box --- */}
            <div
              className="modal fade"
              id="addNameModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Add Degree
                    </h5>
                    {/* Close Modal */}
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="mb-3">
                        <label
                          htmlFor="recipient-name"
                          className="col-form-label"
                        >
                          Degree:
                        </label>
                        <input
                          onChange={(e) => setData(e.target.value)}
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Degree Name"
                          id="recipient-name"
                        />
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button
                      onClick={handleSubmit}
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ----Edit Modal Box --- */}
            <div
              className="modal fade"
              id="editNameModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Edit Degree
                    </h5>
                    {/* Close Modal */}
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="mb-3">
                        <label
                          htmlFor="recipient-name"
                          className="col-form-label"
                        >
                          Degree:
                        </label>
                        <input
                          value={editData?.name || ""}
                          onChange={(e) =>
                            setEditData({ ...editData, name: e.target.value })
                          }
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Degree Name"
                        />
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button
                      onClick={(e) => handleUpdate(editData?._id)}
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ------ Table -------- */}

            <div className="main-panel">
              <div className="content-wrapper ">
                <div className="col-md-12 detail-page_margin">
                  <div className="">
                    {degreesData.length > 0 ? (
                      <table
                        id="example"
                        className="table table-striped table-bordered datatable responsive"
                      >
                        <thead>
                          <tr>
                            <th>Id </th>
                            <th>Degrees</th>
                            <th>Created At</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {degreesData
                            .filter((item) =>
                              item.name
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            )
                            .map((item, index) => (
                              <tr key={index + 1}>
                                <th scope="row">{index + 1}</th>
                                <td>{item?.name}</td>
                                <td>{formatDate(item?.created_at)}</td>
                                <td>
                                  <button
                                    onClick={(e) => handleEdit(item?._id)}
                                    className="edit-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#editNameModal"
                                  >
                                    <FaEdit className="me-1 fs-6 " /> Edit
                                  </button>
                                  <button
                                    type="button"
                                    value={item?._id}
                                    onClick={(e) => handleDelete(item?._id)}
                                    className="delete-btn"
                                  >
                                    <AiFillDelete className="me-1 fs-6 " />{" "}
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    ) : (
                      <BarLoader color="#36d7b7" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Degrees;
