import React, { useEffect} from "react";
import { FaRegCircleUser } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { GetUser} from "../../../../Redux/Slices/User";
import ProfileImg from "./ProfileImg";
import Resume from "./Resume";
import { useNavigate } from "react-router-dom";
import PasswordReset from "./PasswordReset";
import toast from "react-hot-toast";
const Jobseekerprofile = () => {
  const { userData, completionPercentage, loading } = useSelector(
    (state) => state?.User
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let id = localStorage.getItem("id");

  // Last Update
  const timestamp = userData?.updated_at;
  const date = new Date(timestamp);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  useEffect(() => {
    dispatch(GetUser(id));
    document.title = "Profile | CAJobPortal";
  }, []);

  useEffect(() => {
    // if ((userData?.resume===null || userData?.resume===undefined) ) {
    if ((userData?.resume===undefined ||userData?.resume==="") && !loading ) {
      toast("Please Upload Resume");
    }
  }, []);

  return (
    <div className="">
      <div className="jobseeker-profile-container">
        {/* Profile Img - Compeletion Bar */}
        <ProfileImg
          userData={userData}
          completionPercentage={completionPercentage}
          loading={loading}
        />
        {/* ---Profile Card ---- */}
        <div className="prfile-card my-4">
          <div className="d-flex justify-content-between">
            <div className="d-flex justify-content-center">
              <h3 className="card-head-line">CAJobPortal Profile</h3>
            </div>
            <div>
              <button className="btnpf4">
                {" "}
                <CiEdit
                  onClick={() => navigate("/jobseeker/profile/details")}
                  className="fs-2"
                />
              </button>
            </div>
          </div>

          <div className="btn-profilecard"></div>
          <span>
            <FaRegCircleUser className="fs-5" /> Last Updated: {formattedDate}
            {/* {formatUpdatedAt(userData?.updated_at)} */}
          </span>
        </div>
        {/* ----- Resume --- */}
        <Resume userData={userData} />

        {/* Basic Details */}
        <div className="prfile-card">
          <div className="d-flex justify-content-between">
            <div className="d-flex justify-content-center">
              <h3 className="card-head-line">Basic Details</h3>
            </div>
            <div>
              <button
                onClick={() => navigate("/jobseeker/profile/details")}
                className="btnpf4"
              >
                <CiEdit className="fs-2" />
              </button>
            </div>
          </div>
          <div className="card-profile-details mt-2">
            <p>
              {" "}
              <span className="prof-details">Name</span>: {userData?.name}
            </p>
            <p>
              <span className="prof-details">Email</span> : {userData?.email}
            </p>
            <p>
              <span className="prof-details">Phone</span> : {userData?.mobile}
            </p>
          </div>
          <div className="btn-profilecard"></div>
        </div>

        {/* Passowrd Reset */}
        <PasswordReset />
      </div>
    </div>
  );
};

export default Jobseekerprofile;
