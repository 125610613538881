import React, { useEffect, useState } from "react";
import ProfileImg from "./ProfileImg";
import { useDispatch, useSelector } from "react-redux";
import { GetUser, UpdateUser } from "../../../../Redux/Slices/User";
import { yearOption, monthOption } from "../../../../Helper/Option";
import ProfessionalQualification from "./ProfessionalQualification";
import AcademicDetails from "./AcademicDetails";
import Experience from "./Experience";
import AdditionalDetails from "./AdditionalDetails";
import { GoPlus } from "react-icons/go";
import CareerProfile from "./CareerProfile";
import Location from "../../../Admin/Location";

const ProfileDetails = () => {
  let id = localStorage.getItem("id");
  const { userData, completionPercentage, loading} = useSelector((state) => state?.User);
  const [editData, setEditData] = useState(userData);
  const [error, setError] = useState({});
  const [locationData, setLocationData] = useState({
    Country: "",
    State: "",
    Cities: "",
  });
  const dispatch = useDispatch();

  // --------User Details Handler --------
  // Edit
  const handleEdit = async (id) => {
    setEditData(userData);
  };

  // Form Validation
  const validation = () => {
    let error = {};
    if (!editData.name) {
      error.name = "Name is Required";
    }
    if (!editData.email) {
      error.email = "Email is Required";
    } else if (
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        editData.email
      )
    ) {
      error.email = "Enter a Valid Email";
    }
    if (!editData.mobile) {
      error.mobile = "Mobile is Required";
    }
    return error;
  };

  // ------------- OnChange Validation --------------
  let name, value;
  const postUserData = (e) => {
    name = e.target.name;
    value = e.target.value;

    setEditData((prevData) => ({ ...prevData, [name]: value }));
    if (name === "name") {
      if (value.length === 0) {
        setError({ ...error, name: "Name is Required" });
        setEditData({ ...editData, name: "" });
      } else {
        setError({ ...error, name: "" });
        setEditData({ ...editData, name: value });
      }
    }
    if (name === "email") {
      if (value.length === 0) {
        setError({ ...error, email: "Email is required" });
        setEditData({ ...editData, email: "" });
      } else {
        setError({ ...error, email: "" });
        setEditData({ ...editData, email: value });
      }
    }
    if (name === "mobile") {
      if (value.length === 0) {
        setError({ ...error, mobile: "Phone is Required" });
        setEditData({ ...editData, mobile: "" });
      } else {
        setError({ ...error, mobile: "" });
        setEditData({ ...editData, mobile: value });
      }
    }
  };
  // Location

  const receiveDataFromChild = (data) => {
    setLocationData(data);
  };

  //Update
  const handleUpdate = async (id) => {
    let ErrorList = validation();
    setError(ErrorList);
    const years = parseFloat(editData?.year) || 0;
    const months = parseFloat(editData?.month) || 0;

    const totalMonths = years * 12 + months;
    const remainingMonths = totalMonths % 12;
    const formattedMonths =
      remainingMonths < 10 ? `0${remainingMonths}` : remainingMonths;
    const totalExperience = `${Math.floor(
      totalMonths / 12
    )}.${formattedMonths}`;

    if (Object.keys(ErrorList).length === 0) {
      let updateData = {
        name: editData?.name || userData?.name,
        email: editData?.email || userData?.email,
        mobile: editData?.mobile || userData?.mobile,
        total_experience: totalExperience || userData?.total_experience,
        experience_level:
          editData?.experience_level || userData?.experience_level,
        address: {
          country: locationData?.Country || userData?.address?.country,
          state: locationData?.State || userData?.address?.state,
          city: locationData?.Cities[0] || userData?.address?.city,
        },
      };
      await UpdateUser(id, updateData, "application/json");
      dispatch(GetUser(id));
      setEditData("");
    }
  };

  useEffect(() => {
    dispatch(GetUser(id));
    document.title = "Profile Detail | CAJobPortal";
  }, [dispatch]);

  return (
    <>
      <div className="jobseeker-profile-container">
        {/* Profile Img || Compeletion Bar */}
        <ProfileImg userData={userData}
          completionPercentage={completionPercentage}
          loading={loading} />

        {/* Personal Info */}
        <div className="prfile-card my-4">
          <div className="d-flex justify-content-between">
            <div className="d-flex justify-content-center">
              <h3 className="card-head-line">Personal Info</h3>
            </div>
            <div>
              <button
                onClick={(e) => handleEdit(id)}
                data-bs-toggle="modal"
                data-bs-target="#editProfileModal"
                className="btnpf4"
              >
                <GoPlus className="fs-2" />
              </button>
            </div>
          </div>
          {/*------ Profile Details ------  */}
          <div className="mt-2">
            <p>
              {" "}
              <span className="prof-details">Name</span>:{" "}
              {userData?.name || "Not Available"}
            </p>
            <p>
              <span className="prof-details">Email</span> :{" "}
              {userData?.email || "Not Available"}
            </p>
            <p>
              <span className="prof-details">Phone</span> :{" "}
              {userData?.mobile || "Not Available"}
            </p>
            <p>
              <span className="prof-details">Experience</span> :{" "}
              {userData?.experience_level || "Not Available"}
            </p>
            <p>
              <span className="prof-details">Current Location</span> :{" "}
              {userData?.address?.city}, {userData?.address?.state},{" "}
              {userData?.address?.country}
            </p>
          </div>

          {/* Edit Profile Modal */}
          <div
            className="modal fade modal-lg"
            id="editProfileModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Basic Details
                  </h5>
                  {/* Close Modal */}
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <form className="experience-modal">
                    {/* Name */}
                    <div className="">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        {error.name ? (
                          <span style={{ color: "red" }}>{error.name}</span>
                        ) : (
                          "Name:"
                        )}
                      </label>
                      <input
                        value={editData?.name}
                        onChange={(e) => postUserData(e)}
                        name="name"
                        type="text"
                        className="form-control h-25"
                        placeholder="Name"
                      />
                    </div>
                    {/* Name */}
                    <div className="">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        {error.name ? (
                          <span style={{ color: "red" }}>{error.name}</span>
                        ) : (
                          "Experince Level:"
                        )}
                      </label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => postUserData(e)}
                        name="experience_level"
                        value={
                          editData?.experience_level ||
                          userData?.experience_level
                        }
                      >
                        <option>Experience Level</option>
                        <option value={"Fresher"}>Fresher</option>
                        <option value={"Experienced"}>Experienced</option>
                      </select>
                    </div>
                    {/* Total Experience */}
                    <label htmlFor="recipient-name" className="col-form-label">
                      {error.year ? (
                        <span style={{ color: "red" }}> {error.year}</span>
                      ) : (
                        "Total Experience:"
                      )}
                    </label>
                    <div className="d-flex align-items-center">
                      {/* ---- Year ---- */}
                      <select
                        name="year"
                        onChange={(e) => postUserData(e)}
                        className="form-select me-3"
                        aria-label="Default select example"
                      >
                        {yearOption?.map((value) => (
                          <option key={value} value={value}>
                            {`${value} Year`}
                          </option>
                        ))}
                      </select>
                      {/* ----- Month ----- */}
                      <select
                        name="month"
                        onChange={(e) => postUserData(e)}
                        className="form-select"
                        aria-label="Default select example"
                      >
                        {monthOption?.map((value) => (
                          <option key={value} value={value}>
                            {`${value} Month`}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Current Location */}

                    <Location sendDataToParent={receiveDataFromChild} />

                    {/* Phone */}
                    <label htmlFor="recipient-name" className="col-form-label">
                      {error.mobile ? (
                        <span style={{ color: "red" }}> {error.mobile}</span>
                      ) : (
                        "Phone:"
                      )}
                    </label>
                    <input
                      name="mobile"
                      type="text"
                      className="form-control h-25"
                      placeholder="Phone No."
                      value={editData?.mobile}
                      onChange={(e) => postUserData(e)}
                    />
                    {/* Email */}
                    <label htmlFor="recipient-name" className="col-form-label">
                      {error.email ? (
                        <span style={{ color: "red" }}> {error.email}</span>
                      ) : (
                        "Email:"
                      )}
                    </label>
                    <input
                      name="email"
                      type="text"
                      className="form-control h-25"
                      placeholder="Email"
                      value={editData?.email}
                      onChange={(e) => postUserData(e)}
                      disabled
                    />
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    onClick={(e) => handleUpdate(editData?._id)}
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ------ Experience ----- */}
        <Experience userData={userData} />

        {/*------ Additional Details ------  */}
        <ProfessionalQualification userData={userData} />

        {/*------ Academic Details ------  */}
        <AcademicDetails userData={userData} />

        {/*------ Additional Details ------  */}
        <AdditionalDetails userData={userData} />

        {/*------ Career Profile ------  */}
        <CareerProfile userData={userData} />
      </div>
    </>
  );
};

export default ProfileDetails;
