import React, { useEffect, useState } from "react";
import jsonData from "../../Helper/CountryStateCity.json";
import Select from "react-select";
import { useSelector } from "react-redux";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const Location = ({ sendDataToParent }) => {

  const { jobdetails } = useSelector((state) => state?.Jobs);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCities, setSelectedCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
 
  useEffect(() => {
    const countryOptions = jsonData.map((country) => ({
      value: country.name,
      label: country.name,
    }));

      sendDataToParent({
        Country: selectedCountry,
        State: selectedState,
        Cities: selectedCities,
      });



    setCountries([
      { value: "", label: "Select Country", isDisabled: true },
      ...countryOptions,
    ]);

 
  }, [selectedCountry, selectedState, selectedCities]);

  useEffect(() => {

    if(jobdetails !== null) {
      setSelectedCountry(jobdetails?.country);
      setSelectedState(jobdetails?.state);
      setSelectedCities(jobdetails?.cities);
    
      updateStates(jobdetails?.country);
      updateCities(jobdetails?.state);

    }

 
  }, [jobdetails]);

  const updateStates = (countryName) => {
    const selectedCountryData = jsonData.find(
      (country) => country.name === countryName
    );
    setSelectedCountry(countryName);

    const stateOptions = selectedCountryData
      ? selectedCountryData.states.map((state) => ({
          value: state.name,
          label: state.name,
        }))
      : [];

    const defaultStateOption = {
      value: "",
      label: "Select State",
      isDisabled: true,
    };

    setStates([defaultStateOption, ...stateOptions]);
  };

  const updateCities = (stateName) => {
    const selectedStateData = jsonData
      .find((country) => country.name === selectedCountry)
      ?.states.find((state) => state.name === stateName);
    setSelectedState(stateName);

    const cityOptions = selectedStateData
      ? selectedStateData.cities.map((city) => ({
          value: city.name,
          label: city.name,
        }))
      : [];

    const defaultCityOption = {
      value: "",
      label: "Select City",
      isDisabled: true,
    };

    setCities([defaultCityOption, ...cityOptions]);
  };

  const handleCountryChange = (selectedOption) => {
    const countryName = selectedOption.value;
    setSelectedState("");
    setSelectedCities([]);
    updateStates(countryName);
  };

  const handleStateChange = (selectedOption) => {
    const stateName = selectedOption.value;
    setSelectedCities([]);
    updateCities(stateName);
  };

  const handleCityChange = (selectedOptions) => {
    const cityValues = selectedOptions.map((option) => option.value);
    setSelectedCities(cityValues);
  };

  return (
    <div className="filter-box2">
      <form>
        <div className="row">
      <label className="col-form-label">Location : </label>
          <div className="col">
            <label htmlFor="countrySelect" className="col-form-label">
              Country
            </label>
            <Select
              id="countrySelect"
              className=""
              value={countries.find(
                (option) => option.value === selectedCountry
              )}
              onChange={handleCountryChange}
              options={countries}
            />
          </div>
        </div>
        {/* State */}
        <div className="row">
          <div className="col">
            <label htmlFor="stateSelect" className="col-form-label">
              State
            </label>
            <Select
              id="stateSelect"
              className=""
              value={states.find((option) => option.value === selectedState)}
              onChange={handleStateChange}
              options={states}
            />
          </div>
        </div>

        {/* City */}
        <div className="row mb-3">
          <div className="col">
            <label htmlFor="citySelect" className="col-form-label">
              City
            </label>
            <Select
              id="citySelect"
              className="react-select "
              value={cities.filter((option) =>
                selectedCities?.includes(option.value)
              )}
              onChange={handleCityChange}
              options={cities}
              isMulti
              components={animatedComponents}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Location;
