import React, { useEffect, useState } from "react";
import AdminNavbar from "./Common/AdminNavbar";
import AdminSideBar from "./Common/AdminSideBar";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  AddSkill,
  DeleteSkills,
  EditSkill,
  FetchSkill,
  UpdateSkill,
} from "../../Redux/Slices/Skill";

const Skill = () => {
  const [skillName, setSkillName] = useState("");
  const [editData, setEditData] = useState(null);
  const[select,setSelect]=useState("")
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const { skillsData } = useSelector((state) => state?.Skill);

  //Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    let inputData = { name: skillName,type:select };
    await AddSkill(inputData);
    let data = {
      page: 1,
      search:searchQuery
    };
    dispatch(FetchSkill(data));
    setSkillName("");
  };

  // Edit
  const handleEdit = async (id) => {
    const data = await EditSkill(id);
    setEditData(data?.data[0]);
  };

  //Update
  const handleUpdate = async (id,typeData) => {
    let inputData = { name: editData.name ,type:typeData};
    await UpdateSkill(inputData, id);
    let data = {
      page: 1,
      search:searchQuery
    };
    dispatch(FetchSkill(data));
  };

  // Delete
  const handleDelete = async (id) => {
    await DeleteSkills(id);
    let data = {
      page: 1,
      search:searchQuery
    };
    dispatch(FetchSkill(data));
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    let data = {
      page:1,
      search:e.target.value
    };
    // searchSalesHistory(data)
    dispatch(FetchSkill(data));
    
  };

  useEffect(() => {
    if (skillsData?.length === 0) {
      let data = {
        page: 1,
        search:searchQuery
      };
      dispatch(FetchSkill(data));
    }
    document.title = "Skills | Admin";

  }, [dispatch]);

  // Format the date
  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const formattedDate = dateObject
      .toISOString()
      .replace("T", " ")
      .replace("Z", "");
    return formattedDate.slice(0, -5);
  };

  const handlePageChange = async (page) => {
    let data = {
      page: page,
      search:searchQuery
    };
    dispatch(FetchSkill(data));
  };

  // Calculate visible page range
  let startPage = 1;
  let endPage = 1;

  if (skillsData?.pagination) {
    startPage = Math.max(1, skillsData.pagination.page - 2);
    endPage = Math.min(
      skillsData.pagination.totalPages,
      skillsData.pagination.page + 2
    );

    // Adjust start and end page if close to beginning or end
    if (skillsData.pagination.page <= 3) {
      endPage = Math.min(5, skillsData.pagination.totalPages);
    } else if (
      skillsData.pagination.page >=
      skillsData.pagination.totalPages - 2
    ) {
      startPage = Math.max(1, skillsData.pagination.totalPages - 4);
    }
  }

  return (
    <div>
      {/* ------- Navbar ------- */}
      <AdminNavbar />
      <div className="row m-0">
        <div className="col-md-2 p-0 nav-content">
          {/* ------- Sidebar ------- */}
          <AdminSideBar />
        </div>
        <div className="main-content col-md-10 p-0">
          {/* ------- Main Content  ------- */}
          <h2 className="headline">Skill List</h2>
          <div className="main-content-child">
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="add-btn"
                data-bs-toggle="modal"
                data-bs-target="#addNameModal"
              >
                Add Skill
              </button>
              {/* --- Search Input --- */}

              <input
                type="search"
                id="form1"
                placeholder="Search"
                className="search-list"
                onChange={(e) => handleSearch(e)}
              />
            </div>
            {/* ----Modal Box --- */}
            <div
              className="modal fade"
              id="addNameModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Add Skill
                    </h5>
                    {/* Close Modal */}
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="mb-3">
                        <label
                          htmlFor="recipient-name"
                          className="col-form-label"
                        >
                          Skill:
                        </label>
                        <select
                          className="form-select mb-3"
                          aria-label="Default select example"
                          onChange={(e)=>setSelect(e.target.value)}
                          name="type"
                        >
                          <option>Skill Type</option>
                          <option value={"Functional"}>Functional</option>
                          <option value={"IT"}>IT</option>
                        </select>
                        <input
                          onChange={(e) => setSkillName(e.target.value)}
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Skill Name"
                          id="recipient-name"
                        />
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button
                      onClick={handleSubmit}
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ----Edit Modal Box --- */}
            <div
              className="modal fade"
              id="editNameModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Edit Skill
                    </h5>
                    {/* Close Modal */}
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="mb-3">
                        <label
                          htmlFor="recipient-name"
                          className="col-form-label"
                        >
                          Skill:
                        </label>
                        <input
                          value={editData?.name || ""}
                          onChange={(e) =>
                            setEditData({ ...editData, name: e.target.value })
                          }
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Skill Name"
                        />
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button
                      onClick={(e) => handleUpdate(editData?._id)}
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ------ Table -------- */}

            <div className="main-panel">
              <div className="content-wrapper ">
                <div className="col-md-12 detail-page_margin">
                  <div className="">
                    {skillsData?.docs?.length > 0 ? (
                      <table
                        id="example"
                        className="table table-striped table-bordered datatable responsive"
                      >
                        <thead>
                          <tr>
                            <th>Id </th>
                            <th>Skill</th>
                            <th>Type</th>
                            <th>Created At</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {skillsData?.docs.map((item, index) => (
                              <tr key={index + 1}>
                                <th scope="row">{index + 1}</th>
                                <td>{item?.name}</td>
                                <td>{item?.type}</td>
                                <td>{formatDate(item?.created_at)}</td>
                                <td>
                                  <button
                                    onClick={(e) => handleEdit(item?._id,item?.type)}
                                    className="edit-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#editNameModal"
                                  >
                                    <FaEdit className="me-1 fs-6 " /> Edit
                                  </button>
                                  <button
                                    type="button"
                                    value={item?._id}
                                    onClick={(e) => handleDelete(item?._id)}
                                    className="delete-btn"
                                  >
                                    <AiFillDelete className="me-1 fs-6 " />{" "}
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    ) : (
                      <center>No Data Available In Table</center>
                      // <BarLoader color="#36d7b7" />
                    )}
                  </div>
                </div>
              </div>
            </div>
             {/* --- Pagination ----- */}
             {skillsData?.pagination && skillsData?.docs?.length > 0 && (
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li
                      className={`page-item ${
                        skillsData?.pagination?.prevPage ? "" : "disabled"
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() =>
                          handlePageChange(skillsData?.pagination?.prevPage)
                        }
                      >
                        Previous
                      </button>
                    </li>
                    {Array.from(
                      { length: endPage - startPage + 1 },
                      (_, i) => startPage + i
                    ).map((page) => (
                      <li
                        key={page}
                        className={`page-item ${
                          skillsData?.pagination?.page === page ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(page)}
                        >
                          {page}
                        </button>
                      </li>
                    ))}
                    <li
                      className={`page-item ${
                        skillsData?.pagination?.nextPage ? "" : "disabled"
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() =>
                          handlePageChange(skillsData?.pagination?.nextPage)
                        }
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skill;
