import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetOneJob, UpdateJob } from "../../../Redux/Slices/JobPost";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from 'react-quill';
import { FetchJobTypes } from "../../../Redux/Slices/JobType";
import { FetchIndustries } from "../../../Redux/Slices/Industry";
import { FetchSkill } from "../../../Redux/Slices/Skill";
import { FetchQualifications } from "../../../Redux/Slices/Qualification";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { yearOption } from "../../../Helper/Option";
import { formatDate } from "../../../Helper/CoreHelper";
import Location from "../../Admin/Location";
import DatePicker from "react-datepicker";

const EditJobPost = () => {
  //---------  Redux state -------------
  const { jobdetails } = useSelector((state) => state?.Jobs);
  const { jobTypesData } = useSelector((state) => state?.JobType);
  const { industriesData } = useSelector((state) => state?.Industry);
  const { skillsData } = useSelector((state) => state?.Skill);
  const { qualificationsData } = useSelector((state) => state?.Qualification);
  const { id } = useParams(); // Fetch JobId

  const role = localStorage.getItem("role");
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [startDate, setStartDate] = useState();
  const navigate = useNavigate();
  const [postDetails, setPostDetails] = useState({});
  const [applicationMode, setApplicationMode] = useState("");
  const animatedComponents = makeAnimated();
  const [ctcDisplay, setCtcDisplay] = useState(false);
  useEffect(() => {
    setCtcDisplay(jobdetails?.ctc_display || false);
  }, [jobdetails]);
  //   -----------Multi Select ------
  const [selectedQualifications, setSelectedQualifications] = useState([]);
  const [selectedQualificationIds, setSelectedQualificationIds] = useState([]);

  const options2 = qualificationsData.map((item) => ({
    value: item.name,
    label: item.name,
    id: item._id,
  }));

  const handleSelectChange = (qualifications) => {
    setSelectedQualifications(qualifications);
    const qualificationIds = qualifications.map(
      (qualification) => qualification.id
    );
    setSelectedQualificationIds(qualificationIds);
  };

  useEffect(() => {
    if (jobdetails?.qualification) {
      const defaultSelectedQualifications = jobdetails.qualification.map(
        (qualification) => ({
          value: qualification.name,
          label: qualification.name,
          id: qualification._id,
        })
      );
      setSelectedQualifications(defaultSelectedQualifications);

      const defaultSelectedQualificationIds = jobdetails.qualification.map(
        (qualification) => qualification._id
      );
      setSelectedQualificationIds(defaultSelectedQualificationIds);
    }
  }, [jobdetails]);

  // ----------- Skills -------------

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedSkillIds, setSelectedSkillIds] = useState([]);
  const options = skillsData?.docs?.map((item) => ({
    value: item.name,
    label: item.name,
    id: item._id,
  }));

  const handleSelectChange1 = async (skills) => {
    setSelectedSkills(skills);
    const skillIds = skills.map((skill) => skill.id);
    setSelectedSkillIds(skillIds);
  };

  useEffect(() => {
    if (jobdetails?.skills) {
      const defaultSelectedSkills = jobdetails.skills.map((skill) => ({
        value: skill.name,
        label: skill.name,
        id: skill._id,
      }));
      setSelectedSkills(defaultSelectedSkills);
      const skillIds = jobdetails.skills.map((skill) => skill._id);
      setSelectedSkillIds(skillIds);
    }
  }, [jobdetails]);
  // -----------End of Selected Skills -------------

  //---------- Form Validation -------------

  const validation = () => {
    let error = {};

    // Validate job type
    if (!postDetails.jobtype && !jobdetails?.type) {
      error.jobtype = "JobType is Required";
    }

    // Validate job title
    if (!postDetails.jobtitle && !jobdetails?.title) {
      error.jobtitle = "Job Title is Required";
    }

    // Validate company name
    if (!postDetails.company_name && !jobdetails?.company_name) {
      error.company_name = "Company Name is Required";
    }



    // Validate job description
    if (!postDetails.description && !jobdetails?.description) {
      error.description = "Job Description is Required";
    }

    // Validate experience
    if ((postDetails.exp_from === "All" || !postDetails.exp_to === "All")
    ) {
      error.experience = "Experience Range is Required";
    }

    if ((postDetails.ctc_from_lacs === "All" || !postDetails.ctc_to_lacs === "All")
    ) {
      error.ctc = "CTC Range is Required";
    }

    // Validate CTC
    if (ctcDisplay || ctcDisplay === "true") {
      if(jobdetails?.ctc?.from_lac || jobdetails?.ctc?.from_lac) {
        // error.ctc = "CTC Range is Required";
      }else if (!postDetails.ctc_from_lacs || !postDetails.ctc_to_lacs) {
        error.ctc = "CTC Range is Required";
      }
    }


    // Validate validity date
    if (!startDate && !jobdetails?.validity) {
      error.validity = "Job Validity Date is Required";
    }

    // Validate apply through
    if (!postDetails?.apply_through && !jobdetails?.apply_through) {
      error.apply_through = "Apply Through is Required";
    }

    // Validate category
    if (!postDetails.category && !jobdetails?.category) {
      error.category = "Job Category is Required";
    }

    return error;
  };

  const editorStyle = {
    height: '180px',
    backgroundColor: 'white',
  };

  const handleTextChange = (value) => {
    setPostDetails({ ...postDetails, description: value });
    // setText(value);
  };

  //Onchange value
  let name, value;
  const postUserData = (e) => {
    name = e.target.name;
    value = e.target.value;
   
    if (e.target.value) {
      setError("");
    }
    setPostDetails({ ...postDetails, [name]: value });
    if (name === "jobtitle") {
      if (value.length === 0) {
        setError({ ...error, jobtitle: "Job Title is required" });
        setPostDetails({ ...postDetails, jobtitle: "" });
      } else {
        setError({ ...error, jobtitle: "" });
        setPostDetails({ ...postDetails, jobtitle: value });
      }
    }
    if(name === 'ctcdisplay') {
      setCtcDisplay(e.target.value === "true");
    }
    if (name === "company_name") {
      if (value.length === 0) {
        setError({ ...error, about_company: "Company Name is required" });
        setPostDetails({ ...postDetails, company_name: "" });
      } else {
        setError({ ...error, about_company: "" });
        setPostDetails({ ...postDetails, company_name: value });
      }
    }
    if (name === "about_company") {
      if (value.length === 0) {
        setError({ ...error, about_company: "About Company is required" });
        setPostDetails({ ...postDetails, about_company: "" });
      } else {
        setError({ ...error, about_company: "" });
        setPostDetails({ ...postDetails, about_company: value });
      }
    }
    if (name === "description") {
      if (value.length === 0) {
        setError({ ...error, description: "Description is required" });
        setPostDetails({ ...postDetails, description: "" });
      } else {
        setError({ ...error, description: "" });
        setPostDetails({ ...postDetails, description: value });
      }
    }
  };
  // Location

  const [locationData, setLocationData] = useState({
    Country: "",
    State: "",
    Cities: [],
  });
  // Function to receive Location data from the child
  const receiveDataFromChild = (data) => {
    setLocationData(data);
  };
  // Handle Applied Through
  const handleapplied = (e) => {
    setApplicationMode(e.target.value);
  };

  const handleSubmbit = async (e) => {
    e.preventDefault();
    let ErrorList = validation();
    setError(ErrorList);
    if (Object.keys(ErrorList).length === 0) {
      let data = {
        type: postDetails?.jobtype || jobdetails?.type?._id,
        title: postDetails?.jobtitle || jobdetails?.title,
        company_name: postDetails?.company_name || jobdetails?.company_name,
        about_company: postDetails?.about_company || jobdetails?.about_company,
        description: postDetails?.description || jobdetails?.description,
        country: locationData?.Country || jobdetails?.country,
        state: locationData?.State || jobdetails?.state,
        cities:
          locationData?.Cities?.length > 0
            ? locationData?.Cities
            : jobdetails?.cities,
        industry: postDetails?.industry || jobdetails?.industry?._id,
        skills: selectedSkillIds,
        qualification: selectedQualificationIds,
        other_skill: postDetails?.other_skills || jobdetails?.other_skill,
        exp: {
          from: postDetails?.exp_from || jobdetails?.exp?.from,
          to: postDetails?.exp_to || jobdetails?.exp?.to,
        },
        ctc: {
          from_lac:postDetails?.ctc_from_lacs || jobdetails?.ctc?.from_lac,
          from_thousand: postDetails?.ctc_from_thousand || jobdetails?.ctc?.from_thousand,
          to_lac: postDetails?.ctc_to_lacs || jobdetails?.ctc?.to_lac,
          to_thousand: postDetails?.ctc_to_thousand || jobdetails?.ctc?.to_thousand,
        },
        ctc_display: ctcDisplay,
        validity: startDate || jobdetails?.validity,
        apply_through: postDetails?.apply_through || jobdetails?.apply_through,
        form_link: postDetails?.form_link || jobdetails?.form_link,
        website_link: postDetails?.website_link || jobdetails?.website_link,
        category: postDetails?.category || jobdetails?.category,
      };
      setError({});
      let res = await UpdateJob(id, data);

      if (res?.success && role === "Employer") {
        navigate("/employer/posted-jobs");
        return;
      }
      if (res?.success && role === "Admin") {
        navigate("/admin/postedjobs");
        return;
      }
    }
  };
  useEffect(() => {
    let data = {
      page: 1,
      search:"",
      limit:50
    };
    dispatch(GetOneJob(id));
    dispatch(FetchJobTypes());
    dispatch(FetchIndustries(data));
    dispatch(FetchSkill(data));
    dispatch(FetchQualifications());
    document.title = "Edit Job ";
  }, []);

  return (
    <div>
      {/* {role === "Admin" && <AdminNavbar />} */}
      <div className="container-fluid bg-light">
        <div className="row">
          <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 mt-3">
            <h5>Edit Job</h5>
            <form
              className="mt-3 form"
              id="userForm"
              method="post"
              name="userForm"
            >
              {/* ------- Job Type --------- */}
              <span style={{ color: "red" }}> {error.jobtype} </span>
              <select
                name="jobtype"
                onChange={(e) => postUserData(e)}
                className="form-select mb-3 two-in-one"
                aria-label="Default select example"
                value={postDetails?.jobtype || jobdetails?.type?._id}
              >
                <option value={""}>Select Job Type </option>
                {jobTypesData?.map((value, index) => (
                  <option key={index + 1} value={`${value?._id}`}>
                    {value?.name}
                  </option>
                ))}
              </select>
              {/* -------- Job title--------- */}

              <div className="mb-3">
                <span style={{ color: "red" }}> {error.jobtitle} </span>
                <input
                  id="jobTitle"
                  name="jobtitle"
                  defaultValue={postDetails?.jobtitle || jobdetails?.title}
                  onChange={(e) => postUserData(e)}
                  type="text"
                  className="form-control"
                  placeholder="Job Title *"
                />
              </div>
              {/* -------- Company name --------- */}
              <span style={{ color: "red" }}> {error.company_name} </span>

              <div className="mb-3">
                <input
                  id="company_name"
                  name="company_name"
                  type="text"
                  defaultValue={postDetails?.company_name || jobdetails?.company_name}
                  className="form-control "
                  onChange={(e) => postUserData(e)}
                  placeholder="Company Name *"
                />
                {/* -------- About Company --------- */}
              </div>
              <span style={{ color: "red" }}> {error.about_company} </span>

              <div className="mb-3">
                <textarea
                  id="about_company"
                  name="about_company"
                  defaultValue={
                    postDetails?.about_company || jobdetails?.about_company
                  }
                  onChange={(e) => postUserData(e)}
                  rows={5}
                  placeholder="About Company"
                  className="form-control"
                />
                {/* --------Job Description --------- */}
              </div>

              <div className="mb-3">
                <div>
                  <p>
                    <b>
                      Job Description <span> *</span>
                    </b>
                    <span style={{ color: "red" }}> {error.description} </span>
                  </p>
                  <div>
                    <ReactQuill
                      theme="snow"
                      value={postDetails?.description || jobdetails?.description}
                      onChange={handleTextChange}
                      style={editorStyle}
                    />
                    </div>
                  
                </div>
              </div>
              {/* -------- Job Category --------- */}
              <p className="mt-5">
                <b>
                  Category<span></span>
                </b>
                <span style={{ color: "red" }}>{error.category} </span>
              </p>
              <select
                name="category"
                onChange={(e) => postUserData(e)}
                value={postDetails?.category || jobdetails?.category}
                className="form-select mb-3 two-in-one"
                aria-label="Default select example"
              >
                <option value={""}>Select Job Category</option>
                <option value={"Temporary"}>Temporary</option>
                <option value={"Permanent"}>Permanent</option>
                <option value={"Freelance"}>Freelance</option>
              </select>
              {/* --------- Location -------- */}

              <Location sendDataToParent={receiveDataFromChild} />

              {/* ---Industry Option --- */}
              <p>
                <b>
                  Industry <span> *</span>
                </b>
                <span style={{ color: "red" }}>{error.industry} </span>
              </p>
              <select
                name="industry"
                onChange={(e) => postUserData(e)}
                className="form-select mb-3 two-in-one"
                aria-label="Default select example"
                value={postDetails?.industry || jobdetails?.industry?._id}
              >
                <option value={""}>Select Job Type </option>
                {industriesData?.docs?.map((value, index) => (
                  <option key={index + 1} value={`${value?._id}`}>
                    {value?.name}
                  </option>
                ))}
              </select>

              {/* ---Qualification Option --- */}
              <p className="mt-2">
                <b>
                  Qualification <span> *</span>
                </b>
                <span style={{ color: "red" }}>{error.qualification} </span>
              </p>

              <Select
                className="multiple-select"
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={options2}
                onChange={handleSelectChange}
                value={selectedQualifications}
              />
              {/* --- Skills Option --- */}
              <p className="mt-2">
                <b>
                  Skills <span> *</span>
                </b>
                <span style={{ color: "red" }}>{error.skills} </span>
              </p>
              <div className="container-fluid mb-3 p-0">
                <div className="row">
                  <div className="col-12 col-sm-6 ">
                    <Select
                      className="multiple-select"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={options}
                      onChange={handleSelectChange1}
                      value={selectedSkills}
                    />
                  </div>
                  {/* Other skills */}
                  <div className="col-12 col-sm-6 mt-2 mt-sm-0">
                    <input
                      type="text"
                      className="form-control"
                      name="other_skills"
                      placeholder="Other Skill name"
                      onChange={(e) => postUserData(e)}
                      defaultValue={
                        postDetails?.other_skill || jobdetails?.other_skill
                      }
                    />
                  </div>
                </div>
              </div>

              {/*------  Experience ------ */}

              <p>
                <b>
                  Experience <span> *</span>
                </b>
              </p>

              <div className="container-fluid mb-3 p-0">
                <span style={{ color: "red" }}>{error.experience} </span>
                <div className="row">
                  <div className="col-12 col-sm-6 ">
                    <select
                      name="exp_from"
                      onChange={(e) => postUserData(e)}
                      className="form-select mb-3 two-in-one"
                      aria-label="Default select example"
                      value={postDetails?.exp_from || jobdetails?.exp?.from}
                    >
                      <option defaultValue>All</option>
                      {yearOption?.map((value) => (
                        <option key={value + 2} value={value}>
                          {`${value} Year`}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-sm-6 mt-2 mt-sm-0">
                    <select
                      name="exp_to"
                      value={postDetails?.exp_to || jobdetails?.exp?.to}
                      onChange={(e) => postUserData(e)}
                      className="form-select mb-3 two-in-one"
                      aria-label="Default select example"
                    >
                      <option defaultValue>All</option>
                      {yearOption?.map((value) => (
                        <option key={value + 2} value={value}>
                          {`${value} Year`}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              {/* Ctc display */}
              <div className="container-fluid mb-3 p-0">
                <div className="row form-group">
                  <div className="col-md-9">
                    <p>CTC will be Displayed or not</p>

                    <div className="col-md-6 pl-0 ">
                      <div
                        className="d-flex radio justify-content-start p-0"
                        style={{ gap: 10 }}
                      >
                        <input
                          type="radio"
                          className="input_radio"
                          name="ctcdisplay"
                          value={true}
                          onChange={(e) => postUserData(e)}
                          checked={ctcDisplay === true}
                        />
                        <label className="form-check-label">Yes</label>
                        <input
                          type="radio"
                          className="input_radio"
                          name="ctcdisplay"
                          value={false}
                          onChange={(e) => postUserData(e)}
                          checked={ctcDisplay === false}
                        />
                        <label className="form-check-label">No</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* --------- CTC ------- */}
              <p>
                <b>
                  CTC Range (in Lakh)<span> *</span>
                </b>
                <span style={{ color: "red" }}>{error.ctc} </span>
              </p>
              <div className="container-fluid mb-3 p-0">
                <p>From</p>
                <div className="row mb-2">
                  <div className="col-12 col-sm-6 ">
                    <select
                      name="ctc_from_lacs"
                      value={postDetails?.ctc_from_lacs || jobdetails?.ctc?.from_lac}
                      onChange={(e) => postUserData(e)}
                      className="form-select mb-3 two-in-one"
                      aria-label="Default select example"
                    >
                      <option defaultValue>All</option>
                      <option value={""}>Select</option>
                      {yearOption?.map((value) => (
                        <option key={value + 2} value={value}>
                          {`${value} Lacs`}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-sm-6 mt-2 mt-sm-0">
                    <select
                      name="ctc_from_thousand"
                      value={postDetails?.ctc_from_thousand || jobdetails?.ctc?.from_thousand}
                      onChange={(e) => postUserData(e)}
                      className="form-select mb-3 two-in-one"
                      aria-label="Default select example"
                    >
                      <option defaultValue>All</option>
                      <option value={""}>Select</option>
                      {yearOption?.map((value) => (
                        <option key={value + 2} value={value}>
                          {`${value} Thousands`}
                        </option>
                      ))}
                    </select>
                    {/* ---------- to ------- */}
                  </div>
                </div>
                <p>To</p>
                <div className="row mb-2">
                  <div className="col-12 col-sm-6 ">
                    <select
                      name="ctc_to_lacs"
                      value={
                        postDetails?.ctc_to_lacs ||
                        jobdetails?.ctc?.to_lac
                      }
                      onChange={(e) => postUserData(e)}
                      className="form-select mb-3 two-in-one"
                      aria-label="Default select example"
                    >
                      <option defaultValue>All</option>
                      <option value={""}>Select</option>
                      {yearOption?.map((value) => (
                        <option key={value + 2} value={value}>
                          {`${value} Lacs`}
                        </option>
                      ))}
                    </select>
                    <span className="help-block error_text" role="alert"></span>
                  </div>
                  <div className="col-12 col-sm-6 mt-2 mt-sm-0">
                    <select
                      name="ctc_to_thousand"
                      value={
                        postDetails?.ctc_to_thousand ||
                        jobdetails?.ctc?.to_thousand
                      }
                      onChange={(e) => postUserData(e)}
                      className="form-select mb-3 two-in-one"
                      aria-label="Default select example"
                    >
                      <option defaultValue>All</option>
                      <option value={""}>Select</option>
                      {yearOption?.map((value) => (
                        <option key={value + 2} value={value}>
                          {`${value} Thousands`}
                        </option>
                      ))}
                    </select>
                    <span className="help-block error_text" role="alert"></span>
                  </div>
                </div>
              </div>
              {/* Job Validity */}
              <div className="container-fluid mb-3 p-0">
                <div className="row">
                  <span style={{ color: "red" }}>{error.validity} </span>

                  <div className="col-12 col-sm-6 ">
                    <div
                      className="input-group input-append date"
                      id="datePicker"
                    >
                      <DatePicker
                        className="form-control jobValidity "
                        placeholderText="Job Validity"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        value={startDate || formatDate(jobdetails?.validity)}
                      />
                      <span className="input-group-addon add-on input-group-text">
                        <i className="bi bi-calendar-plus" />
                      </span>
                    </div>
                    <span className="help-block error_text" role="alert"></span>
                  </div>
                  {/* ------- Applie Through---- */}
                  <div className="col-12 col-sm-6 mt-2 mt-sm-0">
                    <span style={{ color: "red" }}>{error.apply_through} </span>

                    <select
                      id="apply_through"
                      name="apply_through"
                      value={
                        postDetails?.apply_through || jobdetails?.apply_through
                      }
                      className="form-select custom-select"
                      onChange={(e) => postUserData(e)}
                    >
                      <option value>Apply Through *</option>
                      <option value="Resume">Resume</option>
                      <option value="Form">Form</option>
                      <option value="Website">Website</option>
                    </select>
                  </div>
                </div>
              </div>
              {postDetails?.apply_through ? (
                 <div className="container-fluid p-0">
                 {postDetails?.apply_through === "Website"  && (
                   <div className="row" id="qis">
                     <div className="col-md-6  col-sm-12 col-xs-12 col-lg-6 ">
                       <label htmlFor="q1" className=" col-form-label">
                         Website Link
                       </label>
                       <input
                         id="website_link"
                         name="website_link"
                         defaultValue={postDetails?.website_link}
                         type="text"
                         onChange={(e) => postUserData(e)}
                         className="form-control"
                       />
                     </div>
                   </div>
                 )}
                 {postDetails?.apply_through === "Form"  && (
                   <div className="row" id="formLink">
                     <div className="col-md-6  col-sm-12 col-xs-12 col-lg-6 ">
                       <label htmlFor="q1" className=" col-form-label">
                         Form Link
                       </label>
 
                       <input
                         id="link"
                         name="form_link"
                         type="text"
                         defaultValue={postDetails?.form_link}
                         className="form-control"
                         onChange={(e) => postUserData(e)}
                       />
                     </div>
                   </div>
                 )}
               </div>
              ): (
                <div className="container-fluid p-0">
                {jobdetails?.apply_through === "Website"  && (
                  <div className="row" id="qis">
                    <div className="col-md-6  col-sm-12 col-xs-12 col-lg-6 ">
                      <label htmlFor="q1" className=" col-form-label">
                        Website Link
                      </label>
                      <input
                        id="website_link"
                        name="website_link"
                        type="text"
                        defaultValue={jobdetails?.website_link}
                        onChange={(e) => postUserData(e)}
                        className="form-control"
                      />
                    </div>
                  </div>
                )}
                {jobdetails?.apply_through === "Form"  && (
                  <div className="row" id="formLink">
                    <div className="col-md-6  col-sm-12 col-xs-12 col-lg-6 ">
                      <label htmlFor="q1" className=" col-form-label">
                        Form Link
                      </label>

                      <input
                        id="link"
                        name="form_link"
                        defaultValue={jobdetails?.form_link}
                        type="text"
                        className="form-control"
                        onChange={(e) => postUserData(e)}
                      />
                    </div>
                  </div>
                )}
              </div>
              )}
             
              <div className="d-grid gap-2 col-6 mx-auto mt-1 my-3 w-25">
                <button
                  onClick={handleSubmbit}
                  className="submit_btn py-1"
                  id="submit_btn_create_job"
                  type="submit"
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditJobPost;
