import toast from "react-hot-toast";
import { axiosInstance, config } from "../../Axios/AxiosInstance";
let header = config;

// http://localhost:3000/api/users/candidate
export const SearchCandidate = async (data) => {
  try {
    let res = await axiosInstance.post("users/candidate", data, header);
    return res?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error?.message;
  }
};
