import React, { useEffect, useState } from "react";
import AdminNavbar from "./Common/AdminNavbar";
import AdminSideBar from "./Common/AdminSideBar";
import {
  CreatePackage,
  DeletePackage,
  GetPackage,
} from "../../Redux/Slices/Package";

const Subscription = () => {
  const [packageDetails, setPackageDetails] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [formdata, setFormdata] = useState({});
  const [formErrors, setFormErrors] = useState({});
  // Onchange
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormdata({ ...formdata, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  // Validation function
  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // Validate Package Name
    if (!formdata.name) {
      errors.type = "Package Name is required";
      isValid = false;
    }
    if (!formdata.type) {
      errors.type = "Package Type is required";
      isValid = false;
    }

    // Validate Validity Day
    if (!formdata.validity_day) {
      errors.validity_day = "Validity Day should be filled";
      isValid = false;
    }

    // Validate Offer
    // if (!formdata.offer) {
    //   errors.offer = "Offer Text is required";
    //   isValid = false;
    // }

    // Validate Cost
    if (!formdata.cost) {
      errors.cost = "Package Price should be filled";
      isValid = false;
    }

    // Validate No of Job Posting
    if (!formdata.no_job_posting) {
      errors.no_job_posting = "No of Job Posting should be filled";
      isValid = false;
    }

    // Validate No of CV Search
    if (!formdata.no_cv_search) {
      errors.no_cv_search = "No Of CV Search should be filled";
      isValid = false;
    }

    // Validate Discount Percentage
    // if (
    //   formdata.discount &&
    //   (formdata.discount < 0 || formdata.discount > 100)
    // ) {
    //   errors.discount = "Discount Percentage should be between 0 and 100";
    //   isValid = false;
    // }

    setFormErrors(errors);
    return isValid;
  };

  // Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      name: formdata?.name,
      type: formdata?.type,
      no_job_posting: Number(formdata?.no_job_posting),
      no_cv_search: Number(formdata?.no_cv_search),
      validity_day: Number(formdata?.validity_day),
      offer: formdata?.offer,
      discount: Number(formdata?.discount),
      cost: Number(formdata?.cost),
      gst: Number((parseInt(formdata?.cost) * 18) / 100),
    };
    // console.log(data);
    if (validateForm()) {
      CreatePackage(data);
      packageData();
      //   document.getElementById("addNameModal").click();
    } else {
      // console.log("Form validation failed");
    }
  };

  //Update
  const handleUpdate = async (id, typeData) => {};

  //Delete Package
  const handleDelete = async (id) => {
    await DeletePackage(id);
    packageData();
  };

  const packageData = async () => {
    try {
      let data = await GetPackage();
      setPackageDetails(data?.docs);
    } catch (error) {
      console.error("Error fetching package data:", error);
    }
  };
  useEffect(() => {
    packageData();
    document.title = "Subscriptions | Admin";
  }, []);

  //Gst Calculation

  function gstCal(purchase) {
    const gstPercentage = purchase?.gst || 18; // GST percentage
    const gstAmount = (purchase?.cost * gstPercentage) / 100; // Calculate GST amount
    const totalAmount = purchase?.cost + gstAmount; // Add GST to the total amount
    return totalAmount;
  }

  // Discount Percentage
  function discountedPrice(purchase) {
    const gstPercentage = purchase?.gst || 18; // GST percentage
    const discountPercentage = purchase?.discount || 0; // Discount percentage

    // Calculate GST amount
    const gstAmount = (purchase?.cost * gstPercentage) / 100;

    // Calculate discounted amount
    const discountAmount = (purchase?.cost * discountPercentage) / 100;

    // Calculate total amount after discount and GST
    const discountedTotalAmount = purchase?.cost - discountAmount + gstAmount;

    return discountedTotalAmount;
  }

  function calculateGst(formdata) {
    const cost = parseFloat(formdata) || 0;
    const gstAmount = (cost * 18) / 100;
    const totalCost = cost + gstAmount;
    return totalCost;
}



  return (
    <div>
      {/* ------- Navbar ------- */}
      <AdminNavbar />
      <div className="row m-0">
        <div className="col-md-2 p-0 nav-content">
          {/* ------- Sidebar ------- */}
          <AdminSideBar />
        </div>
        <div className="main-content col-md-10 p-0">
          {/* ------- Main Content  ------- */}
          <h2 className="headline">Subscription List</h2>
          <div className="main-content-child">
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="add-btn"
                data-bs-toggle="modal"
                data-bs-target="#addNameModal"
              >
                Add Subscription
              </button>
              {/* --- Search Input --- */}

              <input
                type="search"
                id="form1"
                placeholder="Search"
                className="search-list"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            {/* ----Modal Box --- */}
            <div
              className="modal fade"
              id="addNameModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Add Subscription
                    </h5>
                    {/* Close Modal */}
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <form>
                      {/* Name */}
                      <div className="mb-3">
                        <input
                          onChange={handleInputChange}
                          name="name"
                          type="text"
                          className={`form-control mt-2 ${
                            formErrors?.name ? "is-invalid" : ""
                          }`}
                          placeholder="Package Name"
                          id="recipient-offer"
                        />
                        {formErrors?.name && (
                          <div className="invalid-feedback">
                            {formErrors.name}
                          </div>
                        )}
                      </div>
                      <div className="mb-3">
                        <select
                          className={`form-select mb-3 ${
                            formErrors.type ? "is-invalid" : ""
                          }`}
                          aria-label="Default select example"
                          onChange={handleInputChange}
                          name="type"
                        >
                          <option value="">Subscription Type</option>
                          {["CV search", "Job post", "Both"].map(
                            (item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            )
                          )}
                        </select>

                        {formErrors.type && (
                          <div className="invalid-feedback">
                            {formErrors.type}
                          </div>
                        )}
                      </div>
                      <div className="mb-3">
                        <input
                          onChange={handleInputChange}
                          name="validity_day"
                          type="number"
                          className={`form-control mt-2 ${
                            formErrors.validity_day ? "is-invalid" : ""
                          }`}
                          placeholder="Validity Day"
                          id="recipient-name"
                        />
                        {formErrors.validity_day && (
                          <div className="invalid-feedback">
                            {formErrors.validity_day}
                          </div>
                        )}
                      </div>
                      <div className="mb-3">
                        <input
                          onChange={handleInputChange}
                          name="offer"
                          type="text"
                          className={`form-control mt-2 ${
                            formErrors.offer ? "is-invalid" : ""
                          }`}
                          placeholder="Offer Text"
                          id="recipient-offer"
                        />
                        {formErrors.offer && (
                          <div className="invalid-feedback">
                            {formErrors.offer}
                          </div>
                        )}
                      </div>
                      <div className="mb-3">
                        <input
                          onChange={handleInputChange}
                          name="cost"
                          type="text"
                          className={`form-control mt-2 ${
                            formErrors.cost ? "is-invalid" : ""
                          }`}
                          placeholder="Package Price"
                          id="recipient-price"
                        />
                        {formErrors.cost && (
                          <div className="invalid-feedback">
                            {formErrors.cost}
                          </div>
                        )}
                      </div>
                      <div className="mb-3">
                        <input
                          onChange={handleInputChange}
                          name="no_job_posting"
                          type="number"
                          className={`form-control mt-2 ${
                            formErrors.no_job_posting ? "is-invalid" : ""
                          }`}
                          placeholder="No of Job Posting"
                          id="recipient-price"
                        />
                        {formErrors.no_job_posting && (
                          <div className="invalid-feedback">
                            {formErrors.no_job_posting}
                          </div>
                        )}
                      </div>
                      <div className="mb-3">
                        <input
                          onChange={handleInputChange}
                          name="no_cv_search"
                          type="number"
                          className={`form-control mt-2 ${
                            formErrors.no_cv_search ? "is-invalid" : ""
                          }`}
                          placeholder="No Of CV Search"
                          id="recipient-price"
                        />
                        {formErrors.no_cv_search && (
                          <div className="invalid-feedback">
                            {formErrors.no_cv_search}
                          </div>
                        )}
                      </div>
                        
                      <div className="mb-3">
                        <span className="ms-1">Total Cost</span>
                        <input
                          onChange={handleInputChange}
                          name="gst"
                          type="number"
                          className={`form-control mt-2 ${
                            formErrors.discount ? "is-invalid" : ""
                          }`}
                          value={calculateGst(formdata?.cost)}
                          placeholder="Gst %"
                          id="recipient-price"
                          disabled={true}
                        />
                        {formErrors.discount && (
                          <div className="invalid-feedback">
                            {formErrors.discount}
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button
                      onClick={handleSubmit}
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* ------ Table -------- */}

            <div className="main-panel">
              <div className="content-wrapper ">
                <div className="col-md-12 detail-page_margin">
                  <div className="">
                    {packageDetails.length > 0 ? (
                      <table
                        id="example"
                        className="table table-striped table-bordered datatable responsive"
                        style={{ width: "100%", fontSize: "11px" }}
                      >
                        <thead>
                          <tr>
                            <th>S.No </th>
                            <th>Package Name</th>
                            {/* <th>Type</th> */}
                            <th>Offer Text</th>
                            <th>Price</th>
                            <th>Discount(%)</th>
                            <th>GST</th>
                            <th>Total Price</th>
                            <th>CV Search</th>
                            <th>Job Post</th>
                            <th>Validity</th>
                            <th className="no-wrap">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          { packageDetails
                            ?.filter((item) =>
                              item?.type
                                ?.toLowerCase()
                                ?.includes(searchQuery?.toLowerCase())
                            )
                            ?.map((item, index) => (
                              <tr key={index + 1}>
                                <th scope="row">{index + 1}</th>
                                <td>{item?.name}</td>
                                <td>{item?.offer}</td>
                                <td>{item?.cost}</td>
                                <td>{item?.discount || 0}</td>
                                <td>{item?.gst || "NA"}</td>
                                <td>{(item?.cost+item?.gst)}</td>
                                <td>{item?.no_cv_search || 0}</td>
                                <td>{item?.no_job_posting || 0}</td>
                                <td>{item?.validity_day}</td>
                                <td className="text-nowrap">
                                  {item?.is_active ? (
                                    <button
                                      type="button"
                                      value={item?._id}
                                      className="delete-btn"
                                      onClick={() => handleDelete(item?._id)}
                                    >
                                      Deactivate
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="edit-btn"
                                      onClick={() => handleDelete(item?._id)}
                                    >
                                      Activate
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    ) : (
                      // <BarLoader color="#36d7b7" />
                      <center>No Data Available In Table</center>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
