import React, { useEffect, useState } from "react";
import "./recruiter.css";
import HomeCarousel from "../Home/HomeCarousel";
import PackageCard from "./PackageCard";
import RecuiterBanner from "../../../asset/new/images/recruiter_page_banner.png";
import { Helmet } from "react-helmet";
const Recuiter = () => {
  const [toggleState, setToggleState] = useState();


  return (
    <>
      <Helmet>
        <title>Recruiter</title>
        <meta
          name="description"
          content="Join CAJobPortal as a recruiter and access a vast pool of talent. Post jobs, connect with professionals, and build your team."
        />
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <img
              className="rec-banner1"
              src={RecuiterBanner}
              alt="jobseeker_banner"
            />
          </div>
        </div>
      </div>

      {/* <!-- Carousel Section --> */}

      <HomeCarousel />

      {/* <!-- Card Section --> */}
      <br />
      <PackageCard />
      <br />
    </>
  );
};

export default Recuiter;
