import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance, config } from "../../Axios/AxiosInstance";
import toast from "react-hot-toast";

let header = config;

// http://localhost:3000/api/skills/all
export const FetchSkill = createAsyncThunk("skills/all/fetch", async (data) => {
  try {
    let res = await axiosInstance.get(`skills/all?$page=${data.page}&$search=${data?.search}&$limit=${data?.limit ? data.limit : 10}`, header);
    return res?.data;
  } catch (error) {
    return error.message;
  }
});

export const SearchSkills = createAsyncThunk("skills/all/fetch", async (data) => {
  try {
    let res = await axiosInstance.get(`skills/all?$page=1&$search=${data?.search}`, header);
    return res?.data;
  } catch (error) {
    return error.message;
  }
});

// http://localhost:3000/api/skills
export const AddSkill = async (data) => {
  try {
    let res = await axiosInstance.post("skills", data, header);
    toast.success(res?.data?.message);
    return res?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error.message;
  }
};

// http://localhost:3000/api/skills/:id
export const EditSkill = async (id) => {
  try {
    let data = await axiosInstance.get(`skills/${id}`, header);
    return data?.data;
  } catch (error) {
    return error.message;
  }
};

// http://localhost:3000/api/skills/:id
export const UpdateSkill = async (data, id) => {
  try {
    const res = await axiosInstance.put(`skills/${id}`, data, header);
    toast.success(res?.data?.message);
    return res?.data;
  } catch (error) {
    if (
        error?.response?.data?.message.split(" ").splice(0, 3).join(" ") ===
        "E11000 duplicate key"
      ) {
        toast.error("Name Already Exists");
      }
      return error.message;}
};

// http://localhost:3000/api/skills/:id
export const DeleteSkills = async (id) => {
  try {
    let data = await axiosInstance.delete(`skills/${id}`, header);
    toast(data?.data?.message);
    return data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error.message;}
};
const initialState = {
  skillsData: [],
  status: "idel",
};

export const SkillSlice = createSlice({
  name: "skillsData",
  initialState,
  reducer: {},
  extraReducers: {
    [FetchSkill.pending]: (state) => {
      state.status = "Loading......";
    //   state.skills_data = null;
    },
    [FetchSkill.fulfilled]: (state, { payload }) => {
      state.status = "idle";
      state.skillsData = payload;
    },
    [FetchSkill.rejected]: (state) => {
      state.status = "idel";
    },
    [SearchSkills.pending]: (state) => {
      state.status = "Loading......";
    //   state.skills_data = null;
    },
    [SearchSkills.fulfilled]: (state, { payload }) => {
      state.status = "idle";
      state.skillsData = payload;
    },
    [SearchSkills.rejected]: (state) => {
      state.status = "idel";
    },
  },
});
