import React, { useEffect, useState } from "react";
import AdminSideBar from "./Common/AdminSideBar";
import AdminNavbar from "./Common/AdminNavbar";
import { GetAllSubscription,searchAllSubscription } from "../../Redux/Slices/Package";

const PackageSalesHistory = () => {
  const [salesHistory, setSalesHistory] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchSalesHistory = async (page) => {
    try {
      setLoading(true);
      const res = await GetAllSubscription({ page });
      setSalesHistory(res);
    } catch (error) {
      console.error("Error fetching sales history:", error);
    } finally {
      setLoading(false);
    }
  };

  const searchSalesHistory = async (data) => {
    try {
      setLoading(true);
      const res = await searchAllSubscription({ data });
      setSalesHistory(res);
    } catch (error) {
      console.error("Error fetching sales history:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    let data = {
      page:1,
      search:e.target.value
    };
    searchSalesHistory(data)
    
  };

  useEffect(() => {
    fetchSalesHistory(1);
    document.title = "Sales History | Admin";
  }, []);

  const handlePageChange = async (page) => {
    fetchSalesHistory(page);
  };

  const formatTimestamp = (utcTimestamp) => {
    const dateObject = new Date(utcTimestamp);
    return dateObject.toLocaleString(); // Adjust the format as needed
  };

  // Calculate visible page range
  let startPage = 1;
  let endPage = 1;

  if (salesHistory?.pagination) {
    startPage = Math.max(1, salesHistory.pagination.page - 2);
    endPage = Math.min(
      salesHistory.pagination.totalPages,
      salesHistory.pagination.page + 2
    );

    // Adjust start and end page if close to beginning or end
    if (salesHistory.pagination.page <= 3) {
      endPage = Math.min(5, salesHistory.pagination.totalPages);
    } else if (salesHistory.pagination.page >= salesHistory.pagination.totalPages - 2) {
      startPage = Math.max(1, salesHistory.pagination.totalPages - 4);
    }
  }
  return (
    <div>
      <AdminNavbar />
      <div className="row m-0">
        <div className="col-md-2 p-0 nav-content">
          <AdminSideBar />
        </div>
        <div className="main-content col-md-10 p-0">
          <h2 className="headline">Package Sales History</h2>
          <div className="d-flex justify-content-between">
            <p></p>
            <input
              type="search"
              id="form1"
              placeholder="Search"
              className="search-list"
              onChange={(e) => handleSearch(e)}
            />
          </div>
          <div className="main-content-child">
            <div className="main-panel">
              <div className="content-wrapper ">
                <table style={{ width: "100%", fontSize: "12px" }} className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>So.No</th>
                      <th>Employer Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>GST no.</th>
                      <th>Package name</th>
                      <th>Price</th>
                      <th>Discount(%)</th>
                      <th>GST %</th>
                      <th>Total Price</th>
                      <th>Offer Text</th>
                      <th>Purchased on</th>
                      <th>Order Id</th>
                      <th>Validity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="14">Loading...</td>
                      </tr>
                    ) : (
                      salesHistory?.docs?.map((value, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{value?.employer?.name}</td>
                            <td>{value?.employer?.email}</td>
                            <td>{value?.employer?.mobile}</td>
                            <td>{value?.employer?.gst || "NA"}</td>
                            <td>{value?.package?.name}</td>
                            <td>{value?.package?.cost}</td>
                            <td>{value?.package?.discount || 0}</td>
                            <td>{value?.package?.gst}</td>
                            <td>{parseInt(value?.package?.gst) + parseInt(value?.package?.cost) }</td>
                            <td>{value?.package?.offer}</td>
                            <td>{formatTimestamp(value?.created_at)}</td>
                            <td>{value?.payment_id}</td>
                            <td>{value?.package?.validity_day} Days</td>
                          </tr>
                        ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* Pagination */}
            {salesHistory?.pagination && (
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li className={`page-item ${salesHistory?.pagination?.prevPage ? "" : "disabled"}`}>
                    <button className="page-link" onClick={() => handlePageChange(salesHistory?.pagination?.prevPage)}>
                      Previous
                    </button>
                  </li>
                  {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((page) => (
                    <li key={page} className={`page-item ${salesHistory?.pagination?.page === page ? "active" : ""}`}>
                      <button className="page-link" onClick={() => handlePageChange(page)}>
                        {page}
                      </button>
                    </li>
                  ))}
                  <li className={`page-item ${salesHistory?.pagination?.nextPage ? "" : "disabled"}`}>
                    <button className="page-link" onClick={() => handlePageChange(salesHistory?.pagination?.nextPage)}>
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageSalesHistory;
