import React, { useEffect } from "react";
import { FaIndustry, FaRupeeSign } from "react-icons/fa";
import { FcGraduationCap } from "react-icons/fc";
import { GoLocation } from "react-icons/go";
import { PiBagSimple } from "react-icons/pi";
import { ImEye } from "react-icons/im";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateMyJobs,
  GetOneJob,
} from "../../../Redux/Slices/JobPost";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TelegramIcon,
  TelegramShareButton,
} from "react-share";
import { GetUser } from "../../../Redux/Slices/User";
import toast from "react-hot-toast";
import { formatTimeAgo } from "../../../Helper/FromatTime";

const JobDescription = () => {
  let userId = localStorage.getItem("id");
  let role = localStorage.getItem("role");
  const { userData } = useSelector((state) => state?.User);
  const shareUrl = window.location.href;
  const { id } = useParams();
  const dispatch = useDispatch();
  const { jobdetails, similarjobs } = useSelector((state) => state?.Jobs);
  const navigate = useNavigate();
  const handleapplybutton = async (id, action, link) => {
    if (!userId && role !== "Jobseeker") {
      toast.error("You must Login as Job Seeker to Process");
      return;
    }
    if (link) {
      window.open(link, "_blank");
    }
    let data = {
      type: action,
      jobid: id,
    };
    await CreateMyJobs(userId, data);
  };

  useEffect(() => {
    if(userId){
      dispatch(GetUser(userId));
    }
    dispatch(GetOneJob(id));
    document.title = "Job Detail | CAJobPortal";
  }, [id]);

  let qulaificationName = jobdetails?.qualification
    ?.map((value) => value.name)
    .join(", ");

  // Job Visit
  const viewHandler = async (id, count) => {
   
    navigate(`/job-description/${id}`);
  };

  const handleResume = async (e) => {
    navigate(`/jobseeker/profile`);
  };

  const handleJobSeekertoApply = () => {
    navigate("/signin");
  };

  return (
    <div className="container mt-2 mb-5">
      <div className="row">
        <div className="border-0 border-end col-12 col-md-8">
          <div className="joblist-card mt-5 m-2">
            <h4 className="job-card fs-5">{jobdetails?.title}</h4>
            <p className="company-name">{jobdetails?.company_name}</p>
            <div className="job-details my-2 ms-2">
              {jobdetails?.exp?.from !== null &&
                jobdetails?.exp?.from !== undefined &&
                jobdetails?.exp?.to !== null &&
                jobdetails?.exp?.to !== undefined && (
                  <span>
                    <PiBagSimple style={{ fontSize: "19px" }} />{" "}
                    {jobdetails.exp.from} - {jobdetails.exp.to} Years
                  </span>
                )}
              {jobdetails?.ctc?.from_lac !== null &&
                jobdetails?.ctc?.from_lac !== undefined &&
                jobdetails?.ctc?.from_thousand !== null &&
                jobdetails?.ctc?.from_thousand !== undefined &&
                jobdetails?.ctc?.to_lac !== null &&
                jobdetails?.ctc?.to_lac !== undefined &&
                jobdetails?.ctc?.to_thousand !== null &&
                jobdetails?.ctc?.to_thousand !== undefined && (
                  <span>
                    <FaRupeeSign style={{ fontSize: "19px" }} />{" "}
                    {jobdetails.ctc.from_lac}.{jobdetails.ctc.from_thousand}{" "}
                    Lacs - {jobdetails.ctc.to_lac}.{jobdetails.ctc.to_thousand}{" "}
                    Lacs
                  </span>
                )}
              {jobdetails?.cities?.length > 0 &&
                jobdetails.cities[0] !== undefined && (
                  <span>
                    <GoLocation style={{ fontSize: "19px" }} />{" "}
                    {jobdetails.cities[0]}
                  </span>
                )}
              {jobdetails?.industry?.name !== null &&
                jobdetails?.industry?.name !== undefined && (
                  <span>
                    <FaIndustry style={{ fontSize: "19px" }} />{" "}
                    {jobdetails?.industry?.name}
                  </span>
                )}
            </div>

            <p className="ms-2">
              <FcGraduationCap style={{ fontSize: "19px" }} />{" "}
              {qulaificationName}
            </p>
            {/* <p className="ms-2">
              <FcGraduationCap style={{ fontSize: "19px" }} /> Chartered
              Accountant
            </p> */}
            <div className="card-buttom d-flex flex-row-reverse">
              <button className="btn btn-primary card-buttom2">
                {formatTimeAgo(new Date(jobdetails?.admin_approval_date))}
              </button>
              {/* Share Button */}

              <p className="job-type mt-2">Job Type : {jobdetails?.category}</p>
            </div>
          </div>
          <div className="job-description-details ms-2 ">
            <p className="fs-5 details-head ">Job description</p>
            <p
              className="fs-6"
              dangerouslySetInnerHTML={{ __html: jobdetails?.description }}
            ></p>
            <p className="fs-6">
              Location: {jobdetails?.cities && jobdetails?.cities[0]}
            </p>
          </div>
          <div className="job-description-details ms-2">
            <p className="fs-5 details-head">Company Details :</p>
            <p className="fs-6">{jobdetails?.about_company}</p>
          </div>
          <div className="job-description-details ms-2">
            <p className="fs-5 details-head">
              Posted By : {jobdetails?.created_by?.name==="Admin" ? <p className="fs-6">
              {jobdetails?.created_by?.name}
            </p> : <p className="fs-6">
              {jobdetails?.created_by?.name} at {jobdetails?.company_name}
            </p> }
            </p>    
          </div>
          {/* Share Button */}
          <div className="ms-2">
            <p className="fs-5 details-head">Share This Job</p>
            <p className="b">
              <FacebookShareButton url={shareUrl}>
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>{" "}
              <WhatsappShareButton url={shareUrl}>
                <WhatsappIcon size={32} round={true} />
              </WhatsappShareButton>{" "}
              &nbsp;
              <TwitterShareButton url={shareUrl}>
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>{" "}
              <LinkedinShareButton url={shareUrl}>
                <LinkedinIcon size={32} round={true} />
              </LinkedinShareButton>{" "}
              <TelegramShareButton url={shareUrl}>
                <TelegramIcon size={32} round={true} />
              </TelegramShareButton>{" "}
              
            </p>
          </div>
        </div>

        {/* ----------- Side-Bar --------- */}

        <div className="border-0  col-12 col-md-4 mt-3 mt-sm-0">
          <div className="mt-5 m-2  ">
            {/* Apply Job */}
            {userData?.resume? (
              <button
                onClick={() =>
                  handleapplybutton(
                    jobdetails?._id,
                    "applied",
                    jobdetails?.form_link
                  )
                }
                type="button"
                className="apply_1 apply_now_btn"
              >
                Apply Now
              </button>
            ) : userId ? (
              role === "Jobseeker" ? (
                <button
                  type="button"
                  className="apply_1 apply_now_btn"
                  onClick={handleResume}
                >
                  Upload Resume to Apply
                </button>
              ) : (
                <button type="button" className="apply_1 apply_now_btn">
                  Login as Job Seeker to Apply
                </button>
              )
            ) : (
              <button
                type="button"
                className="apply_1 apply_now_btn"
                onClick={handleJobSeekertoApply}
              >
                Login to Apply
              </button>
            )

            // <button type="button" className="apply_1 apply_now_btn">
            //   {userId ? role === "Jobseeker" ? "Upload Resume to Apply" : "Login as Job Seeker to Apply" : " Login to Apply"}
            // </button>
            }
            {role === "Jobseeker" && (
              <button
                onClick={() => handleapplybutton(jobdetails?._id, "saved")}
                type="button"
                className="save_btn1"
              >
                Save
              </button>
            )}

            <h6 className="mt-3">Job Analytics</h6>
            <div className="border-bottom d-flex justify-content-between">
              <p>
                <ImEye /> {jobdetails?.views} View
              </p>
              <p> Applicants : {jobdetails?.applications?.length || 0}</p>
            </div>

            <h6 className="mt-3">Similar jobs</h6>

            {/* -------- Min Job Description Card ------ */}

            {similarjobs?.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  viewHandler(item._id);
                }}
                className="border-bottom job-minicard mt-3"
              >
                <p>{item?.title}</p>
                <span className="job-minicard-des">{item?.industry?.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDescription;
