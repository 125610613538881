import React, { useEffect, useState } from "react";
import { UserCount } from "../../../Redux/Slices/User";

const BlueStrip = () => {
  const [jobcount, setJobcount] = useState(6500);
  const [employerCount, setEmployerCount] = useState(2500); 

  const myjobhnadler = async () => {
    try {
      let { employerCount, jobcount } = await UserCount(); 
      setJobcount(jobcount);
      setEmployerCount(employerCount);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    myjobhnadler();
  }, []);

  return (
    <>
      <div className="container-fluid blue-strip-container">
        <div className="row p-sm-3 custom-row">
          <div className="col-4 p-2 text-center custom-col">
            <span className="numbers_size">{jobcount}+</span>
            <span className="description">Jobs</span>
          </div>
          <div className="col-4 p-2 text-center custom-col">
            <span className="numbers_size">{employerCount}+</span>
            <span className="description">Recruiters</span>
          </div>
          <div className="col-4 p-2 text-center custom-col">
            <span className="numbers_size">4321+</span>
            <span className="description">Brands</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlueStrip;
