import React, { useEffect, useState } from "react";
import DimondImg from "../../../asset/new/images/icons/diamond.svg";
import { GetPackage } from "../../../Redux/Slices/Package";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const YesNoModal = ({ show, handleClose, handleYes }) => {
  return (
    <>
      {/* Backdrop */}
      <div
        className={`modal-backdrop fade ${show ? "show" : ""}`}
        style={{ display: show ? "block" : "none" }}
      ></div>

      {/* Modal */}
      <div
        className={`modal ${show ? "show" : ""}`}
        tabIndex="-1"
        style={{ display: show ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Confirmation</h5>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>Do you want to proceed?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleYes}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const PackageCard = () => {
  const [packageDetails, setPackageDetails] = useState({});
  const [packageId, setPackageId] = useState("");
  const navigate = useNavigate();
  const role = localStorage.getItem("role");

  const packageData = async () => {
    try {
      let data = await GetPackage();
      setPackageDetails(data);
    } catch (error) {
      console.error("Error fetching package data:", error);
    }
  };

  const [showModal, setShowModal] = useState(false);

  const confirmationHandler = (id) => {
    setShowModal(true);
    setPackageId(id);
  };

  const handleYes = () => {
    setShowModal(false);
    navigate(`/employer/payment/${packageId}`);
  };

  const handleClose = () => {
    setShowModal(false);
    setPackageId("");
  };


  useEffect(() => {
    packageData();
  }, []);

  return (
    <div className="container" style={{ marginTop: 50 }}>
      <div className="row">
        <h4 className="mb-4">Our Packages</h4>
        <div className="col-12">
          <div className="container-fluid p-0">
            <div className="row">
              {packageDetails?.docs
                ?.filter((item) => item?.is_active === true)
                .map((value, index) => (
                  <div
                    key={index}
                    className="col-12 col-md-6 col-lg-3 mt-3 mt-md-0 mb-4 packages"
                  >
                    <div className="card h-100 ">
                      <div className="card-body h-100 d-flex flex-column justify-content-between">
                        {/* Body */}
                        <div>
                          <h5 className="text-start card-title-packages text-center">
                            {value?.name}
                          </h5>
                          <p className="price-details card-text text-start text-center">
                            {value?.offer}
                            <br />
                            Validity: <b>{value?.validity_day} Days</b>
                          </p>
                          <p className="text-center price_css text-center">
                            <b>₹{value?.cost}</b>
                          </p>
                        </div>

                        {/* Buy Button */}
                        <div className="text-center">
                          <button
                            className="btn_buy_now text-center"
                            onClick={() =>
                              role === "Employer"
                                ? confirmationHandler(value?._id)
                                : toast.error("Login as Recruiter to Buy")
                            }
                          >
                            Buy now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              <div className="col-12 col-md-6 col-lg-3 mt-3 mt-md-0 mb-4 packages">
                <div className="card card3">
                  <div className="card-body">
                    <h5 className="text-start card-title-packages2">
                      Premium Pack
                    </h5>
                    <p className="price3-details card-text text-start">
                      Get Unlimited CV access and job posting
                    </p>
                    <div className="text-center">
                      <img
                        style={{ maxWidth: 40 }}
                        src={DimondImg}
                        alt="imgs"
                      />
                      <br />
                      <a
                        href="tel:+91-9088026253"
                        className="phone text-center mb-0"
                      >
                        <span>+91-90880 26253</span>
                      </a>
                      <br />
                      <a className="email" href="mailto:sonia@cajobportal.com">
                        <span>sonia@cajobportal.com</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Yes/No Modal */}
            <YesNoModal
              show={showModal}
              handleClose={handleClose}
              handleYes={handleYes}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageCard;
