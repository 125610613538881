import React from "react";
import Logo from "../../../asset/new/images/cajobportal_logo.svg";
import Avtar from "../../../asset/new/images/user_login.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../../Redux/Slices/AuthSlice";
import { IoExitOutline } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { TfiHelpAlt } from "react-icons/tfi";
const AdminNavbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const name = localStorage.getItem("name");

  const log = () => {
    dispatch(logout());
    navigate("/signin");
  };
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light"
      style={{ alignSelf: "center", background: "#F6F6F6" }}
    >
      <div className="container-fluid ">
        <Link className="navbar-brand ms-2" to={"/"}>
          <img
            className="logo_img"
            style={{ width: 135 }}
            src={Logo}
            alt="logo"
          />
        </Link>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="navbar-collapse bg-transparent collapse"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
          <div className="d-md-flex align-self-center me-5">
            <div className="btn-group me-3 mt-1">
              <button
                type="button"
                className="btn dropdown-toggle border-0 "
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span style={{ marginLeft: 5, marginRight: 5 }}>
                  <img src={Avtar} style={{ width: 22 }} alt="lock" />
                </span>
                <span className="fs-6">{name}</span>
              </button>

              <ul className="dropdown-menu shadow-sm bg-body rounded ">
                <li>
                  <Link to={"/admin/profile"} className="dropdown-item ">
                    <FaUser /> My Profile
                  </Link>
                  <Link to={"/admin/dashboard"} className="dropdown-item ">
                    <FaUser /> Dashboard
                  </Link>
                </li>
                <li>
                  <Link to={"/contact"} className="dropdown-item ">
                    <TfiHelpAlt /> Help Center
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <button
                    onClick={log}
                    to={"/jobseeker/applied-jobs"}
                    className="dropdown-item "
                  >
                    <IoExitOutline /> Log Out
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default AdminNavbar;