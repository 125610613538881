import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { GetUser } from "../../../Redux/Slices/User";
import {
  CreateSubscriptionOrder,
  GetOnePackage,
} from "../../../Redux/Slices/Package";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const Payment = () => {
  let userId = localStorage.getItem("id");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state?.User);
  const { id } = useParams();
  const [purchase, setPuchase] = useState(null);

  // Gst Cal
  const gstAmount = parseInt(purchase?.gst); // GST percentage
  const totalAmount = parseInt(purchase?.cost) + gstAmount;

  // Display Razor

    async function displayRazorpay() {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      try {
        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY_ID,
          currency: "INR",
          amount: totalAmount * 100, // Amount in paisa
          name: "CAJobPortal",
          description: "Thank you for Subscription.",
          handler: async function (response) {
            let data = await CreateSubscriptionOrder({
              paymentId: response?.razorpay_payment_id,
              package: purchase?._id,
              employer: userId,
              validity_day: purchase?.validity_day,
              avail_jobposting: purchase?.no_job_posting,
              avail_cvsearch: purchase?.no_cv_search,
            });
            if (data?.success) {
              navigate("/employer/dashboard");
            }
          },
          prefill: {
            contact: "",
            email:userData?.email,
          },
        };


        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } catch (error) {
        console.error("Error creating package:", error);
      }
    }

  const packagedetails = async () => {
    let data = await GetOnePackage(id);
    setPuchase(data);
  };

  useEffect(() => {
    dispatch(GetUser(userId));
    packagedetails();
    document.title = "Payment";
  }, [dispatch]);

  return (
    <div className="container mt-5 mb-5">
      <div className="row">
        <div className="col-md-6 mb-3 ">
          <div className="card">
            <div className="card-header-payment text-center">
              <span>
                <b>Package Details</b>
              </span>
            </div>
            <div className="table-responsive">
              <table
                className="table table-striped"
                style={{ width: "100%", fontSize: "12px", fontWeight: "500" }}
              >
                <tbody>
                  <tr>
                    <td>Package name</td>
                    <td>
                      <div>{purchase?.name}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>Package Details</td>
                    <td>
                      <div>{purchase?.offer}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>Validity</td>
                    <td>{purchase?.validity_day} Days</td>
                  </tr>
                  <tr>
                    <td>Price</td>
                    <td>{purchase?.cost}</td>
                  </tr>
                  <tr>
                    <td>Offer</td>
                    <td>{purchase?.offer}</td>
                  </tr>
                  <tr>
                    <td>Discount(%)</td>
                    <td>{purchase?.discount || "0"}</td>
                  </tr>
                  <tr>
                    <td>Total Cost</td>
                    <td>{purchase?.cost}</td>
                  </tr>
                  <tr>
                    <td>GST (18%)</td>
                    <td>{purchase?.gst}</td>
                  </tr>
                  <tr>
                    <td>Total Price</td>
                    <td>{totalAmount}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <div className="card ">
            <div className="card-header-payment text-center">
              <span>
                <b>Customer Details</b>
              </span>
            </div>
            <div className="table-responsive">
              <table
                className="table table-striped "
                style={{ width: "100%", fontSize: "12px", fontWeight: "500" }}
              >
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td> {userData?.name}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td> {userData?.email}</td>
                  </tr>
                  <tr>
                    <td>Phone Number</td>
                    <td> {userData?.mobile}</td>
                  </tr>
                  <tr>
                    <td>Designation</td>
                    <td> {userData?.experience[0]?.designation}</td>
                  </tr>
                  <tr>
                    <td>Company Name</td>
                    <td>{userData?.experience[0]?.company_name}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-md-12 text-center mb-5">
          <button onClick={() => navigate(-1)} className="btn btn-info">
            Back
          </button>{" "}
          <button
            onClick={displayRazorpay}
            className="btn btn-warning"
            id="submit"
          >
            Proceed to Pay
          </button>
        </div>
      </div>
    </div>
  );
};

export default Payment;
