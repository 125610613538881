import React, { useEffect, useState } from "react";
import Sidebar from "../../Common/Sidebar";
import PackageCard from "./PackageCard";
import NoSubscriptionAlert from "./NoSubscriptionAlert";
import EmployerSubscriptions from "./EmployerSubscriptions";
import { GetUserSubscriptions } from "../../../Redux/Slices/Package";
const MyPackage = () => {
  useEffect(() => {
    document.title = "Package | CAJobPortal";
    salesHistoryHandler();
  }, []);

  const [salesHistory, setSalesHistory] = useState(null);

  const salesHistoryHandler = async () => {
    let data = await GetUserSubscriptions();
    setSalesHistory(data?.subscriptions);
  };

  return (
    <div className="m-5">
      <div className="row ">
        {/* ------------ SideBar ------------- */}
        <Sidebar />
        <div className="col-md-10">
          <h1>My Package</h1>

          {/* Alert */}
          <NoSubscriptionAlert salesHistory={salesHistory} />

          {/* Subscription */}
          {salesHistory &&  <EmployerSubscriptions salesHistory={salesHistory} />}
          {/* <EmployerSubscriptions/> */}

          {/* Package Plan */}
          <PackageCard />
        </div>
      </div>
    </div>
  );
};

export default MyPackage;
