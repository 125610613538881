import React, { useEffect, useState } from "react";
import { useDispatch} from "react-redux";
import { FetchDegrees } from "../../../../Redux/Slices/Degree";
import {
  DeleteAcademic,
  GetUser,
  UpdateUser,
} from "../../../../Redux/Slices/User";
import { MdDeleteOutline } from "react-icons/md";
import { yearNoOption } from "../../../../Helper/Option";
import { GoPlus } from "react-icons/go";
import { CiEdit } from "react-icons/ci";

const AcademicDetails = ({userData}) => {
  let id = localStorage.getItem("id");
  const [editData, setEditData] = useState(userData);
  const [academic, setAcademic] = useState([]);

  const [error, setError] = useState({});
  const dispatch = useDispatch();

  //----Add ----
  const handleEdit = async (id) => {
    setEditData(userData);
  };
  //----Delete ----

  const handleDelete = async (academicId) => {
    let userId = await userData?._id;
    await DeleteAcademic(userId, academicId, "application/json");
    dispatch(GetUser(id));
  };

  const handleEditAcademic = async (value) => {
    setAcademic(value);
  };
  // ------------- OnChange Validation --------------

  let name, value;
  const postUserData = (e) => {
    name = e.target.name;
    value = e.target.value;

    setEditData((prevData) => ({ ...prevData, [name]: value }));
    if (name === "college") {
      if (value.length === 0) {
        setError({ ...error, college: "Name is Required" });
        setEditData({ ...editData, college: "" });
      } else {
        setError({ ...error, college: "" });
        setEditData({ ...editData, college: value });
      }
    }
    if (name === "marks") {
      if (value.length === 0) {
        setError({ ...error, marks: "Marks is Required" });
        setEditData({ ...editData, marks: "" });
      } else {
        setError({ ...error, marks: "" });
        setEditData({ ...editData, marks: value });
      }
    }
  };

  // ------------- OnChange Edit Validation --------------

  const postEditUserData = (e) => {
    const { name, value } = e.target;

    setAcademic((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "college") {
      if (value.length === 0) {
        setError((prevError) => ({
          ...prevError,
          college: "Name is Required",
        }));
        setAcademic((prevData) => ({
          ...prevData,
          college: "",
        }));
      } else {
        setError((prevError) => ({
          ...prevError,
          college: "",
        }));
        setAcademic((prevData) => ({
          ...prevData,
          college: value,
        }));
      }
    }

    if (name === "marks") {
      if (value.length === 0) {
        setError((prevError) => ({
          ...prevError,
          marks: "Marks is Required",
        }));
        setAcademic((prevData) => ({
          ...prevData,
          marks: "",
        }));
      } else {
        setError((prevError) => ({
          ...prevError,
          marks: "",
        }));
        setAcademic((prevData) => ({
          ...prevData,
          marks: value,
        }));
      }
    }
  };

  //  -------------- Update  --------------

  const handleUpdate = async (id) => {
    let data = {
      graduation: editData?.graduation,
      grading_system: editData?.grading_system,
      marks: editData?.marks,
      college: editData?.college,
      year_of_passing: editData?.year,
    };
    let updatedacademic = [...editData?.academic, data];
    let update = { academic: updatedacademic };
    await UpdateUser(id, update, "application/json");
    dispatch(GetUser(id));
    // setEditData("");
  };
  //  -------------- Edit Update  --------------

  const handleNewUpdate = async (id) => {
    let userId = localStorage.getItem("id");

    let data = {
      graduation: academic?.graduation,
      grading_system: academic?.grading_system,
      marks: academic?.marks,
      college: academic?.college,
      year_of_passing: academic?.year || academic?.year_of_passing,
    };
    let updatedacademic = [
      ...userData?.academic?.filter((acc) => acc._id !== id),
      data,
    ];
    let update = { academic: updatedacademic };
    await UpdateUser(userId, update, "application/json");
    dispatch(GetUser(userId));
    setEditData("");
  };

  useEffect(() => {
    dispatch(FetchDegrees());
  }, [dispatch]);
  return (
    <div className="prfile-card my-3">
      <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-center">
          <h3 className="card-head-line">Academic Details</h3>
        </div>
        <div>
          <button
            onClick={(e) => handleEdit()}
            data-bs-toggle="modal"
            data-bs-target="#editAcademic "
            className="btnpf4"
          >
            <GoPlus className="fs-2" />
          </button>
        </div>
      </div>
      {/*------ Additional Details ---- */}

      {userData?.academic?.map((value, index) => (
        <div className=" mt-2 border-bottom" key={`${index}`}>
          <p>
            <span className="prof-details">Education</span>: {value?.graduation}{" "}
            <span className="float-end">
            <CiEdit
              className="profile-delete-btn"
              onClick={(e) => handleEditAcademic(userData?.academic[index])}
              data-bs-toggle="modal"
              data-bs-target="#editAcademicDet"
            />
            <MdDeleteOutline
              className="profile-delete-btn"
              onClick={() => handleDelete(value?._id)}
            />
            </span>
           
          </p>
          <p>
            <span className="prof-details">Institution</span> : {value?.college}
          </p>
          <p>
            <span className="prof-details">Grade Score</span> : {value?.marks}
          </p>
          <p>
            <span className="prof-details">Year of Passing</span> :{" "}
            {value?.year_of_passing}
          </p>
        </div>
      ))}

      {/* Edit Profile Modal */}
      <div
        className="modal fade modal-lg"
        id="editAcademic"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Academic Details
              </h5>
              {/* Close Modal */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form className="experience-modal">
                <div className="">
                  {/* Primary Degree */}
                  <label htmlFor="recipient-name" className="col-form-label">
                    {error.graduation ? (
                      <span style={{ color: "red" }}>{error?.name}</span>
                    ) : (
                      "Education :"
                    )}
                  </label>
                  <select
                    name="graduation"
                    onChange={(e) => postUserData(e)}
                    className="form-select me-3"
                    aria-label="Default select example"
                  >
                    <option value={""} defaultValue>
                      Select
                    </option>
                    <option value={"12th"}>12th Grade</option>
                    <option value={"Graduation"}>Graduation</option>
                    <option value={"Post Graduation"}>Post Graduation</option>
                  </select>
                </div>
                {/* Name of Institue */}
                <label htmlFor="recipient-name" className="col-form-label">
                  {error.college ? (
                    <span style={{ color: "red" }}> {error.college}</span>
                  ) : (
                    "Name of Institution:"
                  )}
                </label>
                <input
                  name="college"
                  type="text"
                  className="form-control h-25"
                  placeholder="Name of Institution"
                  onChange={(e) => postUserData(e)}
                />
                {/* Term of Passing */}
                <label htmlFor="recipient-name" className="col-form-label">
                  {error.year ? (
                    <span style={{ color: "red" }}> {error.year}</span>
                  ) : (
                    "Term of Passing:"
                  )}
                </label>
                <div className="d-flex align-items-center">
                  {/* ---- Year ---- */}
                  <select
                    name="year"
                    onChange={(e) => postUserData(e)}
                    className="form-select me-3"
                    aria-label="Default select example"
                  >
                    <option value={""}>Select Year</option>
                    {yearNoOption?.map((value) => (
                      <option key={value + 2} value={value}>
                        {`${value}`}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Grading System* */}
                <label htmlFor="recipient-name" className="col-form-label">
                  {error.grade ? (
                    <span style={{ color: "red" }}> {error.grade}</span>
                  ) : (
                    "Grading System*:"
                  )}
                </label>
                <div className="d-flex align-items-center">
                  {/* Grading System */}
                  <select
                    name="grading_system"
                    className="form-select me-3"
                    aria-label="Default select example"
                    onChange={(e) => postUserData(e)}
                  >
                    <option value={""}>Select</option>
                    <option value={"Grade"}>Scale 10 Grading System</option>
                    <option value={"Percentage"}>% Marks of 100 Maximum</option>
                  </select>
                </div>
                {/* Marks */}
                <label htmlFor="recipient-name" className="col-form-label">
                  {error.marks ? (
                    <span style={{ color: "red" }}> {error.marks}</span>
                  ) : (
                    "Marks / Grade :"
                  )}
                </label>
                <input
                  name="marks"
                  type="text"
                  className="form-control h-25"
                  placeholder="Marks"
                  onChange={(e) => postUserData(e)}
                />
              </form>
            </div>
            <div className="modal-footer">
              <button
                onClick={(e) => handleUpdate(editData?._id)}
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Edit Profile */}
      <div
        className="modal fade modal-lg"
        id="editAcademicDet"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Academic Details
              </h5>
              {/* Close Modal */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form className="experience-modal">
                <div className="">
                  {/* Primary Degree */}
                  <label htmlFor="recipient-name" className="col-form-label">
                    {error.graduation ? (
                      <span style={{ color: "red" }}>{error?.name}</span>
                    ) : (
                      "Education :"
                    )}
                  </label>
                  <select
                    name="graduation"
                    onChange={(e) => postEditUserData(e)}
                    className="form-select me-3"
                    aria-label="Default select example"
                    value={academic?.graduation}
                  >
                    <option value={""} defaultValue>
                      Select
                    </option>
                    <option value={"12th"}>12th Grade</option>
                    <option value={"Graduation"}>Graduation</option>
                    <option value={"Post Graduation"}>Post Graduation</option>
                  </select>
                </div>
                {/* Name of Institue */}
                <label htmlFor="recipient-name" className="col-form-label">
                  {error.college ? (
                    <span style={{ color: "red" }}> {error.college}</span>
                  ) : (
                    "Name of Institution:"
                  )}
                </label>
                <input
                  name="college"
                  type="text"
                  className="form-control h-25"
                  placeholder="Name of Institution"
                  value={academic?.college}
                  onChange={(e) => postEditUserData(e)}
                />
                {/* Term of Passing */}
                <label htmlFor="recipient-name" className="col-form-label">
                  {error.year ? (
                    <span style={{ color: "red" }}> {error.year}</span>
                  ) : (
                    "Term of Passing:"
                  )}
                </label>
                <div className="d-flex align-items-center">
                  {/* ---- Year ---- */}
                  <select
                    name="year"
                    onChange={(e) => postEditUserData(e)}
                    className="form-select me-3"
                    aria-label="Default select example"
                    value={academic?.year || academic?.year_of_passing}
                  >
                    <option value={""}>Select Year</option>
                    {yearNoOption?.map((value) => (
                      <option key={value + 2} value={value}>
                        {`${value}`}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Grading System* */}
                <label htmlFor="recipient-name" className="col-form-label">
                  {error.grade ? (
                    <span style={{ color: "red" }}> {error.grade}</span>
                  ) : (
                    "Grading System*:"
                  )}
                </label>
                <div className="d-flex align-items-center">
                  {/* Grading System */}
                  <select
                    name="grading_system"
                    className="form-select me-3"
                    aria-label="Default select example"
                    onChange={(e) => postEditUserData(e)}
                    value={academic?.grading_system}
                  >
                    <option value={""}>Select</option>
                    <option value={"Grade"}>Scale 10 Grading System</option>
                    <option value={"Percentage"}>% Marks of 100 Maximum</option>
                  </select>
                </div>
                {/* Marks */}
                <label htmlFor="recipient-name" className="col-form-label">
                  {error.marks ? (
                    <span style={{ color: "red" }}> {error.marks}</span>
                  ) : (
                    "Marks / Grade :"
                  )}
                </label>
                <input
                  name="marks"
                  type="text"
                  className="form-control h-25"
                  placeholder="Marks"
                  value={academic?.marks}
                  onChange={(e) => postEditUserData(e)}
                />
              </form>
            </div>
            <div className="modal-footer">
              <button
                onClick={(e) => handleNewUpdate(academic?._id)}
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcademicDetails;
