import React, { useCallback, useEffect, useState } from "react";
import AdminNavbar from "./Common/AdminNavbar";
import AdminSideBar from "./Common/AdminSideBar";
import { useDispatch, useSelector } from "react-redux";
import { FetchUsers } from "../../Redux/Slices/User";
import Select from "react-select";
import {
  CreateSubscriptionByAdmin,
  GetOnePackage,
  GetPackage,
} from "../../Redux/Slices/Package";

const AddAccess = () => {
  const { usersData } = useSelector((state) => state?.User);
  const [user, setUser] = useState(null);

  const [selectedUser, setSelectedUser] = useState(null);
  const [packageDetails, setPackageDetails] = useState({});
  const [purchase, setPurchase] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    let data = {
      role: "Employer",
      alllist: "all",
    };
    dispatch(FetchUsers(data));
    document.title = "Add Access | Admin";
  }, []);

  const packageData = async () => {
    try {
      let data = await GetPackage();
      setPackageDetails(data);
    } catch (error) {
      console.error("Error fetching package data:", error);
    }
  };

  const jobRecruiterUsers = usersData?.docs?.filter(
    (user) => user?.role_id?.name === "Employer"
  );
  const userOptions = jobRecruiterUsers?.map((value) => ({
    value: value.email,
    label: value.email,
    id: value._id,
  }));

  let name, value;
  const postUserData = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
  };

  const packagedetails = useCallback(async () => {
    try {
      let data = await GetOnePackage(user.package);
      setPurchase(data);
    } catch (error) {
      console.error("Error fetching package details:", error);
    }
  }, [user?.package]);

  useEffect(() => {
    if (user?.package) {
      packagedetails();
    }
  }, [user?.package, packagedetails]);

  useEffect(() => {
    document.title = "Payment";
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      payment_id: user?.remarks,
      package: user?.package,
      employer: selectedUser?.id,
      avail_jobposting: purchase?.no_job_posting,
      avail_cvsearch: purchase?.no_cv_search,
      validity_day:purchase?.validity_day,
    };
    await CreateSubscriptionByAdmin(data);
  };

  useEffect(() => {
    packageData();
  }, []);
  return (
    <div>
      <div>
        {/* ------- Navbar ------- */}
        <AdminNavbar />
        <div className="row m-0">
          <div className="col-md-2 p-0 nav-content">
            {/* ------- Sidebar ------- */}
            <AdminSideBar />
          </div>
          <div className="main-content col-md-10 p-0">
            {/* ------- Main Content  ------- */}
            <h2 className="headline">Add Access</h2>
            <div className="main-content-child">
              <div className="card mt-3">
                <div className="card-header">Add Access</div>
                <form action="addPackageAccess" method="post" id="accessForm">
                  <div className="row m-0">
                    <div className="add-acess-child col-md-3">
                      <label>Employer</label>
                      <Select
                        value={selectedUser}
                        onChange={(selectedOption) =>
                          setSelectedUser(selectedOption)
                        }
                        options={userOptions}
                        isSearchable
                      />
                    </div>
                    <div className="add-acess-child col-md-3">
                      <label>Package</label>
                      <select
                        className="form-select mb-3"
                        aria-label="Default select example"
                        onChange={(e) => postUserData(e)}
                        name="package"
                      >
                        <option>Select Plan</option>
                        {packageDetails?.docs
                          ?.filter((item) => item?.is_active === true)
                          ?.map((value, index) => (
                            <option key={index + 1} value={value?._id}>
                              {value?.name}
                            </option>
                          ))}
                      </select>
                    </div>

                    <span className="card-header-package">Package Details</span>

                    <div className="add-acess-child col-md-3">
                      <label htmlFor="sel1">Package Type</label>
                      <input
                        type="text"
                        name="package_type"
                        id="package_type"
                        className="form-control"
                        value={purchase?.name}
                        disabled={true}
                      />
                    </div>
                    <div className="add-acess-child col-md-3">
                      <label htmlFor="sel1">No of Job Post</label>
                      <input
                        type="text"
                        name="no_of_jp"
                        id="no_of_jp"
                        className=" form-control"
                        value={purchase?.no_job_posting}
                        disabled={true}
                      />
                    </div>
                    <div className="add-acess-child col-md-3">
                      <label htmlFor="sel1">No of CV Search</label>
                      <input
                        type="text"
                        name="no_of_cv"
                        id="no_of_cv"
                        className=" form-control"
                        value={purchase?.no_cv_search}
                        disabled={true}
                      />
                    </div>
                    <div className=" add-acess-child col-md-3">
                      <label htmlFor="sel1">Validity Days</label>
                      <input
                        type="text"
                        name="validity"
                        id="validity"
                        className=" form-control"
                        value={purchase?.validity_day}
                        disabled={true}
                      />
                    </div>
                    <div className=" add-acess-child col-md-3">
                      <label htmlFor="sel1">Remarks</label>
                      <input
                        type="text"
                        name="remarks"
                        onChange={(e) => postUserData(e)}
                        id="validity"
                        className=" form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 text-center">
                    <button
                      onClick={handleSubmit}
                      type="submit"
                      id="button"
                      className="add-acess-submit"
                      style={{ background: "#56be2c", color: "#fff" }}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAccess;
