import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance, config } from "../../Axios/AxiosInstance";
import toast from "react-hot-toast";

//Token
let header = config;

// http://localhost:3000/api/qualifications/all
export const FetchQualifications = createAsyncThunk(
  "qualifications/all/fetch",
  async () => {
    try {
      let res = await axiosInstance.get("qualifications/all", header);
      return res?.data;
    } catch (error) {
      return error.message;
    }
  }
);

// http://localhost:3000/api/qualifications
export const AddQualification = async (data) => {
  try {
    let res = await axiosInstance.post("qualifications", data, header);
    toast.success(res?.data?.message);
    return res?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error.message;
  }
};

// http://localhost:3000/api/qualifications/:id
export const EditQualification = async (id) => {
  try {
    let data = await axiosInstance.get(`qualifications/${id}`, header);
    return data?.data;
  } catch (error) {
    return error.message;
  }
};

// http://localhost:3000/api/qualifications/:id
export const UpdateQualification = async (data, id) => {
  try {
    const res = await axiosInstance.put(`qualifications/${id}`, data, header);
    toast.success(res?.data?.message);
    return res?.data;
  } catch (error) {
    if (
      error?.response?.data?.message.split(" ").splice(0, 3).join(" ") ===
      "E11000 duplicate key"
    ) {
      toast.error("Name Already Exists");
    }
    return error.message;
  }
};

// http://localhost:3000/api/qualifications/:id
export const DeleteQualification = async (id) => {
  try {
    let data = axiosInstance.delete(`qualifications/${id}`, header);
    toast.success(data?.data?.message);
    return data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error.message;
  }
};
const initialState = {
  qualificationsData: [],
  status: "idel",
};

export const QualificationSlice = createSlice({
  name: "QualificationsData",
  initialState,
  reducer: {},
  extraReducers: {
    [FetchQualifications.pending]: (state) => {
      state.status = "Loading......";
      //   state.qualificationsData = null;
    },
    [FetchQualifications.fulfilled]: (state, { payload }) => {
      state.status = "idle";
      state.qualificationsData = payload;
    },
    [FetchQualifications.rejected]: (state) => {
      state.status = "idel";
    },
  },
});
