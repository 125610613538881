import React, { useEffect, useState } from "react";
import AdminSideBar from "./Common/AdminSideBar";
import AdminNavbar from "./Common/AdminNavbar";
import { Link } from "react-router-dom";
import { CreateFolder, GetFolder, searchAllFolders} from "../../Redux/Slices/Folder";

const AdminFolder = () => {
  const [data, setData] = useState("");
  const [folders, setFolders] = useState([]);
  const [limit, setLimit] = useState(10);


  //Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    let inputData = { name: data };
    await CreateFolder(inputData);
    handlefolder();
  };

  // Folder List
  const handlefolder = async () => {
    let data = await GetFolder();   
    setFolders(data);
  };


  const searchFolders = async (data) => {
    try {
      const res = await searchAllFolders({ data });
      setFolders(res);
    } catch (error) {
      console.error("Error fetching sales history:", error);
    } 
  };

  const handlePageChange = async (page) => {
    let data = {
      page:page,
      search:"",
      limit:limit
    };
    searchFolders(data);
  };

  const handleSearch = (e) => {
    let data = {
      page:1,
      search:e.target.value,
      limit:limit
    };
    searchFolders(data)
    
  };

  const selectFoldersLength = (e) => {
    setLimit(e.target.value);
    let data = {
      page:1,
      search:"",
      limit:e.target.value
    };
    searchFolders(data)
  };


  useEffect(() => {
    handlefolder();
    document.title = "Folder | Admin";

  }, []);

  // Calculate visible page range
  let startPage = 1;
  let endPage = 1;

  if (folders?.pagination) {
    startPage = Math.max(1, folders.pagination.page - 2);
    endPage = Math.min(
      folders.pagination.totalPages,
      folders.pagination.page + 2
    );

    // Adjust start and end page if close to beginning or end
    if (folders.pagination.page <= 3) {
      endPage = Math.min(5, folders.pagination.totalPages);
    } else if (folders.pagination.page >= folders.pagination.totalPages - 2) {
      startPage = Math.max(1, folders.pagination.totalPages - 4);
    }
  }

  return (
    <div>
      {/* ------- Navbar ------- */}
      <AdminNavbar />
      <div className="row m-0">
        <div className="col-12 col-md-2 nav-content p-0">
          {/* ------- Sidebar ------- */}
          <AdminSideBar />
        </div>
        <div className="main-content col-md-10 col-12 p-0">
          <h2 className="headline">My Folder</h2>
          {/* ------- Main Content  ------- */}
          <div className="main-content-child">
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="add-btn"
                data-bs-toggle="modal"
                data-bs-target="#addFolderModal"
              >
                Add Folder
              </button>
              {/* --- Search Input --- */}
            </div>
            <div className="">
              <div
                style={{ backgroundColor: "white", padding: "14px" }}
                className="table-responsive px-2"
              >
                <div
                  id="example_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div className="dataTables_length" id="example_length">
                    <label>
                      Show{" "}
                      <select
                        name="example_length"
                        aria-controls="example"
                        onChange={(e) => selectFoldersLength(e)}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>{" "}
                      entries
                    </label>
                    <input
                      className="search-table"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      onChange={(e) => handleSearch(e)}
                    ></input>
                  </div>
                  <br />
                  <table
                    id="example"
                    className="table dataTable no-footer"
                    cellSpacing={0}
                    style={{ width: "100%" }}
                    aria-describedby="example_info"
                  >
                    <thead className="table-headline">
                      <tr>
                        <th
                          className="th_text sorting sorting_asc"
                          tabIndex={0}
                          aria-controls="example"
                          rowSpan={1}
                          colSpan={1}
                          aria-sort="ascending"
                          aria-label="S.no: activate to sort column descending"
                          style={{ width: 209 }}
                        >
                          S.no
                        </th>
                        <th
                          className="th_text sorting"
                          tabIndex={0}
                          aria-controls="example"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Folder: activate to sort column ascending"
                          style={{ width: 253 }}
                        >
                          Folder
                        </th>
                        <th
                          className="th_text sorting"
                          tabIndex={0}
                          aria-controls="example"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Go To: activate to sort column ascending"
                          style={{ width: 315 }}
                        >
                          Go To
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "white !important" }}>
                      {folders?.docs?.length > 0 ? (
                        folders.docs.map((item,index) => (
                          <tr
                            style={{ backgroundColor: "white !important" }}
                            className="odd"
                          >
                            <td className="tb_text sorting_1">{index+1}</td>
                            <td className="tb_text">{item?.name}</td>
                            <td className="tb_text">
                              <Link to={`/admin/subfolder/${item?._id}`}>
                                View Folder
                              </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <td
                          valign="top"
                          colspan="3"
                          className="dataTables_empty p-2"
                        >
                          {" "}
                          <center>No Folder available</center>
                        </td>
                      )}
                    </tbody>
                  </table>
                  <br />
                </div>
              </div>
               {/* Pagination */}
            {folders?.pagination && (
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li className={`page-item ${folders?.pagination?.prevPage ? "" : "disabled"}`}>
                    <button className="page-link" onClick={() => handlePageChange(folders?.pagination?.prevPage)}>
                      Previous
                    </button>
                  </li>
                  {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((page) => (
                    <li key={page} className={`page-item ${folders?.pagination?.page === page ? "active" : ""}`}>
                      <button className="page-link" onClick={() => handlePageChange(page)}>
                        {page}
                      </button>
                    </li>
                  ))}
                  <li className={`page-item ${folders?.pagination?.nextPage ? "" : "disabled"}`}>
                    <button className="page-link" onClick={() => handlePageChange(folders?.pagination?.nextPage)}>
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            )}
            </div>
          </div>

          {/* ----Modal Box --- */}
          <div
            className="modal fade"
            id="addFolderModal"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Add Folder
                  </h5>
                  {/* Close Modal */}
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <form>
                    <div className="">
                      <input
                        onChange={(e) => setData(e.target.value)}
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder="Folder Name"
                        id="recipient-name"
                      />
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    onClick={handleSubmit}
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminFolder;
