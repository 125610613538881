import React, { useEffect, useState } from "react";
import { DeleteJob, GetMyJobs } from "../../../Redux/Slices/JobPost";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatDate, formatTimeAgo } from "../../../Helper/FromatTime";

const AppliedJobs = () => {
  const [toggle, setToggle] = useState("applied");
  const { myjobs } = useSelector((state) => state?.Jobs);
  let id = localStorage.getItem("id");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handledelete = async (id) => {
    await DeleteJob(id);
    dispatch(GetMyJobs(id));
  };

  const viewHandler = async (id) => {
    navigate(`/job-description/${id}`);
  };

  useEffect(() => {
    dispatch(GetMyJobs(id));
    document.title = "Applied Job | CAJobPortal";
  }, []);
  return (
    <div className="container mt-5">
      <ul className="tabs">
        <li
          className={toggle === "applied" ? "active" : ""}
          onClick={() => setToggle("applied")}
        >
          Applied
        </li>
        <li
          className={toggle === "saved" ? "active" : ""}
          onClick={() => setToggle("saved")}
        >
          Saved
        </li>
      </ul>
      <div className="content">
        {toggle === "applied" && (
          <div className="container-fluid">
            <div className="row">
              {myjobs
                ?.filter((item) => item.type === "applied")
                .map((item) => (
                  <div
                    onClick={() => viewHandler(item?.job_id?._id)}
                    className=" col-12 col-sm-10 offset-sm-1"
                  >
                    <div className="title_div">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h6 className="job_title">{item?.job_id?.title}</h6>
                      </div>
                      <p style={{ marginBottom: 0 }}>
                        <span className="company_name mb-1">
                          {item?.job_id?.company_name}
                        </span>
                      </p>
                      <span className="applied_on_text">
                        Saved on {formatDate(item?.created_at)}
                      </span>
                      <br />
                      <br />
                      <p className="company_name">
                        Employer last active:{" "}
                        <span>
                          {formatTimeAgo(
                            new Date(item?.updated_by?.last_login)
                          )}
                        </span>
                        
                      </p>
                      <p className="company_name">
                        People applied:{" "}
                        <span>
                          {item?.job_id?.applications
                            ? item?.job_id?.applications?.length
                            : 0}
                        </span>
                      </p>
                      <p className="company_name">
                        Status:{" "}
                        <span className="fs-6">
                          <b>{item?.action_status || "Not Available"}</b>
                        </span>
                      </p>
                      <div
                        style={{
                          display: "flex",
                          marginRight: 10,
                          alignSelf: "center",
                          alignItems: "center",
                        }}
                      >
                        <button className="applied_tag">Applied</button>
                      </div>
                    </div>
                  </div>
                ))}

              {/* Add your 'foreach' logic or other content here */}
            </div>
          </div>
        )}

        {toggle === "saved" && (
          <div className="container-fluid">
            <div className="row">
              {myjobs
                ?.filter(
                  (item) => item.type === "saved" && item.is_active === true
                )
                .map((item) => (
                  <div
                    onClick={() => viewHandler(item?.job_id?._id)}
                    className="col-12 col-sm-10 offset-sm-1"
                  >
                    <div className="title_div">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h6 className="job_title">{item?.job_id?.title}</h6>
                      </div>
                      <p style={{ marginBottom: 0 }}>
                        <span className="company_name mb-1">
                          {item?.job_id?.company_name}
                        </span>
                      </p>
                      <span className="applied_on_text">
                        Saved on {formatDate(item?.created_at)}
                      </span>
                      <br />
                      <br />
                      <p className="company_name">
                        Employer last active:{" "}
                        <span>
                          {formatTimeAgo(
                            new Date(item?.created_by?.last_login)
                          )}
                        </span>
                        
                      </p>
                      <p className="company_name">
                        People applied:{" "}
                        <span>
                          {item?.job_id?.applications
                            ? item?.job_id?.applications?.length
                            : 0}
                        </span>
                      </p>
                      <div
                        style={{
                          display: "flex",
                          marginRight: 10,
                          alignSelf: "center",
                          alignItems: "center",
                        }}
                      >
                        
                        <button
                          onClick={() => handledelete(item?.job_id?._id)}
                          className="delete_btn"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                ))}

            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppliedJobs;
