import React from "react";
import { useNavigate } from "react-router-dom";
import "./home.css";
import HomeCarousel from "./HomeCarousel";
import BlueStrip from "./BlueStrip";
import Newsletter from "../Newsletter";
import jobseeker from "../../../asset/new/images/jobseeker.webp";
import recruiter from "../../../asset/new/images/recruiter.webp";
import { Helmet } from "react-helmet";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Helmet>
        <title>CAJobPortal</title>
        <meta
          name="description"
          content="Find your dream job with CAJobPortal, India's first recruitment portal exclusive for CA, CS, and CMA professionals. Explore 6390+ job opportunities from 4321+ top brands and connect with 2500+ recruiters."
        />
        {/* Preload the LCP images */}
        <link rel="preload" href={jobseeker} as="image" />
        <link rel="preload" href={recruiter} as="image" />
      </Helmet>
      <div className="mt-5 container-fluid">
        <div className="row">
          <div
            onClick={() => navigate("/job-seeker")}
            className="col-12 col-sm-6 p-0 mb-2"
          >
            
            <div className="img-container left-img">
            <div className="text-overlay">
                <h2>
                  <span>I am a Job Seeker</span>
                </h2>
              </div>
              <img
                src={jobseeker}
                className="img1"
                // loading="lazy"
                alt="job seeker"
                width="600"
                height="400"
              />
              
            </div>
          </div>
          <div
            onClick={() => navigate("/recruiter")}
            className="col-12 col-sm-6 p-0 mb-2"
          >
            <div className="img-container right-img">
            <div className="text-overlay">
                <h2>
                  <span>I am a Recruiter</span>
                </h2>
              </div>
              <img
                src={recruiter}
                className="img1"
                alt="recruiter"
                width="600"
                height="400"
              />
              
            </div>
          </div>
        </div>
      </div>

      <BlueStrip />
      <HomeCarousel />
      <Newsletter />
    </div>
  );
};

export default Home;
