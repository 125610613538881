import toast from "react-hot-toast";
import { axiosInstance, config } from "../../Axios/AxiosInstance";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

let header = config;

const initialState = {
  loading: null,
  allJobs: null,
  jobdetails: null,
  similarjobs: null,
  myjobs: null,
  jobapplicants: null,
};

export const PostJob = async (payloads) => {
  try {
    const { data } = await axiosInstance.post("postjob", payloads, header);
    toast.success(data?.message);
    return data;
  } catch (error) {
    toast.error(error?.response?.data?.message, {
      duration: 6000,
    });
    return error;
  }
};

export const GetOneJob = createAsyncThunk("/jobs/:id", async (id) => {
  try {
    const { data } = await axiosInstance.get(`jobs/${id}`);
    return data;
  } catch (error) {
    return error;
  }
});

export const ViewsOnJob = async (payloads) => {
  try {
    const { data } = await axiosInstance.put(`views`, payloads);
    return data;
  } catch (error) {
    return error;
  }
};

export const SearchJob = async (payloads) => {
  try {
    const { data } = await axiosInstance.post(`jobs/search`, payloads);
    return data;
  } catch (error) {
    return error;
  }
};

export const JobStatus = async (id, payloads) => {
  try {
    const { data } = await axiosInstance.put(
      `joblivestatus/${id}`,
      payloads,
      header
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const UpdateJob = async (id, payloads) => {
  try {
    const { data } = await axiosInstance.put(`/job/${id}`, payloads, header);
    toast.success(data?.message);
    return data;
  } catch (error) {
    return error;
  }
};

export const GetMyJobs = createAsyncThunk("/jobs/user/:id", async (id) => {
  try {
    const { data } = await axiosInstance.get(`jobs/user/${id}`);
    return data;
  } catch (error) {
    return error;
  }
});
export const GetAllApplicants = createAsyncThunk(
  "/applicants/job/:id",
  async (id) => {
    try {
      const { data } = await axiosInstance.get(`applicants/job/${id}`);
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const CreateMyJobs = async (id, datas) => {
  try {
    const { data } = await axiosInstance.put(`jobs/user/${id}`, datas, header);
    toast.success(data?.message);
    return data;
  } catch (error) {
    return error;
  }
};

export const getAllJobs = createAsyncThunk("/jobs/all", async (jobs) => {
  try {
    let url = "jobs";

    if (jobs?.type) {
      url += `?type=${jobs?.type}`;
    }

    if (jobs?.page) {
      url = `jobs?type=${jobs?.type}&$page=${jobs?.page}`;
    }

    if (jobs?.admin_approval && !jobs?.search) {
      url = `jobs?admin_approval=${jobs?.admin_approval}&$page=${jobs?.page}`;
    }

    if(jobs?.search && jobs?.admin_approval) {
      url = `jobs?admin_approval=${jobs?.admin_approval}&$page=${jobs?.page}&$search=${jobs?.search}`;
    }
    const { data } = await axiosInstance.get(url);

    return data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error.message;
  }
});

export const GetCountJobs = async () => {
  try {
    const { data } = await axiosInstance.get(`jobs/count`);
    // console.log(data);
    
    return data;
  } catch (error) {
    return error;
  }
};
export const GetMyPostedJobs = async (id, payloads) => {
  try {
    const { data } = await axiosInstance.get(`myjobs`, header);
    return data;
  } catch (error) {
    return error;
  }
};
export const GetMyAllSubscription = async (id) => {
  try {
    const { data } = await axiosInstance.get(`subscriptions?id=${id}`, header);
    return data;
  } catch (error) {
    return error;
  }
};
export const JobApproval = async (id, payloads) => {
  try {
    const { data } = await axiosInstance.put(
      `jobstatus/${id}`,
      payloads,
      header
    );
    toast.success(data?.message);
    return data;
  } catch (error) {
    toast.success(error?.message);

    return error;
  }
};
export const CandidateStatus = async (payloads) => {
  try {
    const { data } = await axiosInstance.post(
      `job/applicant`,
      payloads,
      header
    );
    toast.success(data?.message);
    return data;
  } catch (error) {
    return error;
  }
};

export const DeleteJob = async (id) => {
  try {
    const { data } = await axiosInstance.delete(`jobs/user/${id}`,header);
    toast.success(data?.message);
    return data;
  } catch (error) {
    return error;
  }
};

export const JobSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    // Fetch All Jobs
    [getAllJobs.pending]: (state) => {
      state.loading = true;
    },
    [getAllJobs.fulfilled]: (state, { payload }) => {
      state.allJobs = payload;
    },
    [getAllJobs.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // Fetch One Jobs
    [GetOneJob.pending]: (state) => {
      state.loading = true;
    },
    [GetOneJob.fulfilled]: (state, { payload }) => {
      if (payload?.success) {
        state.jobdetails = payload?.jobs[0];
        state.similarjobs = payload?.similarJobs;
      }
    },
    [GetOneJob.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    // Fetch One Jobs
    [GetMyJobs.pending]: (state) => {
      state.loading = true;
    },
    [GetMyJobs.fulfilled]: (state, { payload }) => {
      if (payload?.success) {
        state.myjobs = payload?.joblist;
      }
    },
    [GetMyJobs.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    //Ftech All Applicants of Jobs
    [GetAllApplicants.pending]: (state) => {
      state.loading = true;
    },
    [GetAllApplicants.fulfilled]: (state, { payload }) => {
      if (payload?.success) {
        state.jobapplicants = payload?.applicants;
      }
    },
    [GetAllApplicants.rejected]: (state, { payload }) => {
      state.loading = false;
    },
  },
});
