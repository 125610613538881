import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance, config } from "../../Axios/AxiosInstance";
import toast from "react-hot-toast";

let header = config;

// http://localhost:3000/api/sliders/all
export const FetchSliders = createAsyncThunk("sliders/all/fetch", async () => {
  try {
    let res = await axiosInstance.get("sliders/all", header);
    return res?.data;
  } catch (error) {
    return error.message;
  }
});

// http://localhost:3000/api/sliders
export const AddSlider = async (data,contentType) => {
  try {
    let res = await axiosInstance.post("sliders", data, {
      ...config,
      headers: {
        ...config.headers,
        "Content-Type": contentType || config.headers["Content-Type"],
      },
    });
    toast.success(res?.data?.message);
    return res?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error.message;
  }
};

// http://localhost:3000/api/sliders/:id
export const DeleteSliders = async (id) => {
  try {
    let data = await axiosInstance.delete(`sliders/${id}`, header);
    toast(data?.data?.message);
    return data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error.message;
  }
};
const initialState = {
  slidersData: [],
  status: "idel",
};

export const Sliderslice = createSlice({
  name: "slidersData",
  initialState,
  reducer: {},
  extraReducers: {
    [FetchSliders.pending]: (state) => {
      state.status = "Loading......";
      //   state.sliders_data = null;
    },
    [FetchSliders.fulfilled]: (state, { payload }) => {
      state.status = "idle";
      state.slidersData = payload;
    },
    [FetchSliders.rejected]: (state) => {
      state.status = "idel";
    },
  },
});
