import React, { useState } from "react";
import { axiosInstance } from "../../Axios/AxiosInstance";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("newsletter", { email });
      setMessage(response.data.message);
    } catch (error) {
      setMessage("Subscription failed");
    }
  };

  return (
    <div className="newsletter">
  <div className="container">
    <div className="row">
      <div className="col-sm-12">
        <div className="content">
          {message ? (
            <p className="visible">{message}</p> 
          ) : (
            <p className="hidden"></p> 
          )}
          <form onSubmit={handleSubmit}>
            <h2>SUBSCRIBE TO OUR NEWSLETTER</h2>
            <div className="input-group">
              <input
                type="email"
                className="form-control"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                aria-label="Email address for newsletter subscription"
              />
              <span className="input-group-btn">
                <button className="btn" type="submit">
                  Subscribe Now
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

  );
};

export default Newsletter;
