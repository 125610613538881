import React, { useEffect, useState, useRef } from "react";
import { GetUser, UpdateUser } from "../../../../Redux/Slices/User";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { GoPlus } from "react-icons/go";
import { TfiDownload } from "react-icons/tfi";
import { TbReplaceFilled } from "react-icons/tb";
import { BsFilePdf } from "react-icons/bs";

const Resume = ({userData}) => {
  const id = localStorage.getItem("id");
  // const { userData } = useSelector((state) => state?.User);
  const [resume, setResume] = useState(null);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  //   Url Split for PDf Name
  const filePath = userData?.resume?.split("/")?.slice(-1);
  const handleFileChange = (e) => {
    setResume(e.target.files[0]);
  };

  // Url Path
  let resumeUrl = process.env.REACT_APP_AZURE_STORAGE_URL;
  
  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const handleResumeUpload = async () => {
    if (resume) {
      const formData = new FormData();
      formData.append("resume", resume);

      try {
        await UpdateUser(id, formData, "multipartformdata");
        dispatch(GetUser(id));
        setResume(null);
      } catch (error) {
        console.error("Error uploading resume:", error);
        toast.error("Error uploading resume. Please try again.");
      }
    } else {
      toast.error("Add a resume file before uploading");
    }
  };
  // To View PDf
  const handleView = () => {
    // console.log(userData.resume);
    // window.open(userData?.resume, "_blank");
  };
  // For Download PDf
  const handleDownload = () => {
    let url = resumeUrl + userData?.resume;
    window.open(url, "_blank");
  };
  // useEffect(() => {
  //   dispatch(GetUser(id));
  // }, [dispatch, id]);

  return (
    <>
      <div className="prfile-card my-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-center">
            <h3 className="card-head-line">Resume</h3>
          </div>
          <div>
            <button className="btnpf4" onClick={openFileInput}>
              <GoPlus className="fs-2" />
            </button>
            <input
              type="file"
              id="fileInput"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept="application/pdf"
              onChange={handleFileChange}
            />
          </div>
        </div>
        {/* <PDFViewer pdfUrl={userData?.resume} /> */}
        <span>
          <BsFilePdf className="fs-4" />{" "}
          {userData?.resume ? (
            <>
              <span>{filePath}</span>
              <div className="p-3">
            
                <button onClick={handleDownload} className="btnpf5">
                  <TfiDownload className="fs-5" /> Download
                </button>
                <button onClick={openFileInput} className="btnpf5">
                  <TbReplaceFilled className="fs-5" /> Replace
                </button>
              </div>
            </>
          ) : (
            <span className="">Not Available</span>
          )}
        </span>
        {resume && (
          <button
            onClick={handleResumeUpload}
            className="mt-4 ms-2 w-25 mx-auto upload-btn"
          >
            Upload Resume
          </button>
        )}
      </div>
    </>
  );
};

export default Resume;
