import React, { useEffect } from "react";
import AdminNavbar from "./Common/AdminNavbar";
import AdminSideBar from "./Common/AdminSideBar";
import { PiBagSimple } from "react-icons/pi";
import { FaIndustry, FaRupeeSign } from "react-icons/fa";
import { GoLocation } from "react-icons/go";
import { FcGraduationCap } from "react-icons/fc";
import { useParams } from "react-router-dom";
import { GetOneJob } from "../../Redux/Slices/JobPost";
import { useDispatch, useSelector } from "react-redux";
import { formatTimeAgo } from "../../Helper/FromatTime";

const JobReview = () => {
  const { id } = useParams();
  const role = localStorage.getItem("role");
  const dispatch = useDispatch();
  const { jobdetails } = useSelector((state) => state?.Jobs);
  useEffect(() => {
    dispatch(GetOneJob(id));
    document.title = "Review Job | CAJobPortal";
  }, []);

  let qulaificationName = jobdetails?.qualification
    .map((value) => value.name)
    .join(" ,");
  return (
    <>
      {role === "Employer" && (
        <div className="applicant-profile-container">
          {/* ------- Main Content  ------- */}
          <h2 className="headline">Review Job</h2>
          <div className="main-content-child">
            <div className="">
              <div className="joblist-card m-2">
                <h5 className="job-card fs-5">{jobdetails?.title}</h5>
                <p className="company-name">{jobdetails?.company_name}</p>
                <div className="job-details my-2 ms-2">
                  <span>
                    {" "}
                    <PiBagSimple style={{ fontSize: "19px" }} />{" "}
                    {jobdetails?.exp?.from} Years - {jobdetails?.exp?.to} Years
                  </span>
                  <span>
                    <FaRupeeSign style={{ fontSize: "19px" }} />{" "}
                    {jobdetails?.ctc?.from_lac}.{jobdetails?.ctc?.from_thousand}{" "}
                    Lacs - {jobdetails?.ctc?.to_lac}
                    {jobdetails?.ctc?.to_thousand} Lacs
                  </span>
                  <span>
                    <GoLocation style={{ fontSize: "19px" }} />{" "}
                    {jobdetails?.cities[0]}
                  </span>
                  <span>
                    <FaIndustry style={{ fontSize: "19px" }} />{" "}
                    {jobdetails?.industry?.name}
                  </span>
                </div>
                <p className="ms-2">
                  <FcGraduationCap style={{ fontSize: "19px" }} />{" "}
                  {qulaificationName}
                </p>

                <div className="card-buttom d-flex flex-row-reverse">
                  <button className="btn btn-primary card-buttom2">
                    {formatTimeAgo(new Date(jobdetails?.posted_date))}
                  </button>
                  <p className="job-type">Job Type : {jobdetails?.category}</p>
                </div>
              </div>
              <div className="job-description-details ms-2">
                <p className="fs-6 details-head">Job description</p>
                <ul>
                  <li dangerouslySetInnerHTML={{ __html: jobdetails?.description }}></li>
                  {/* <li>{jobdetails?.form_link}</li> */}
                </ul>
              </div>
              <div className="job-description-details ms-2">
                <p className="fs-6 details-head">Company Details</p>
                <p>{jobdetails?.about_company}</p>
              </div>
              <div className="job-description-details ms-2">
                <p className="fs-6 details-head">
                  Posted By : {jobdetails?.created_by?.name}
                </p>
                <p>
                  {} at {jobdetails?.company_name}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {role === "Admin" && (
        <div>
          <AdminNavbar />

          <div className="row m-0">
            <div className="col-md-2 p-0 nav-content">
              {/* ------- Sidebar ------- */}
              <AdminSideBar />
            </div>
            <div className="main-content col-md-10 p-0">
              {/* ------- Main Content  ------- */}
              <h2 className="headline">Review Job</h2>
              <div className="main-content-child">
                <div className="">
                  <div className="joblist-card m-2">
                    <h5 className="job-card fs-5">{jobdetails?.title}</h5>
                    <p className="company-name">{jobdetails?.company_name}</p>
                    <div className="job-details my-2 ms-2">
                      <span>
                        {" "}
                        <PiBagSimple style={{ fontSize: "19px" }} />{" "}
                        {jobdetails?.exp?.from} Years - {jobdetails?.exp?.to}{" "}
                        Years
                      </span>
                      <span>
                        <FaRupeeSign style={{ fontSize: "19px" }} />{" "}
                        {jobdetails?.ctc?.from_lac}.
                        {jobdetails?.ctc?.from_thousand} Lacs -{" "}
                        {jobdetails?.ctc?.to_lac}
                        {jobdetails?.ctc?.to_thousand} Lacs
                      </span>
                      <span>
                        <GoLocation style={{ fontSize: "19px" }} />{" "}
                        {jobdetails?.cities[0]}
                      </span>
                      <span>
                        <FaIndustry style={{ fontSize: "19px" }} />{" "}
                        {jobdetails?.industry?.name}
                      </span>
                    </div>
                    <p className="ms-2">
                      <FcGraduationCap style={{ fontSize: "19px" }} />{" "}
                      {qulaificationName}
                    </p>

                    <div className="card-buttom d-flex flex-row-reverse">
                      <button className="btn btn-primary card-buttom2">
                        {formatTimeAgo(new Date(jobdetails?.posted_date))}
                      </button>
                      <p className="job-type">
                        Job Type : {jobdetails?.category}
                      </p>
                    </div>
                  </div>
                  <div className="job-description-details ms-2">
                    <p className="fs-6 details-head">Job description</p>
                    <ul>
                    <li dangerouslySetInnerHTML={{ __html: jobdetails?.description }}></li>
                      <li>{jobdetails?.form_link}</li>
                    </ul>
                  </div>
                  <div className="job-description-details ms-2">
                    <p className="fs-6 details-head">Company Details</p>
                    <p>{jobdetails?.about_company}</p>
                  </div>
                  <div className="job-description-details ms-2">
                    <p className="fs-6 details-head">
                      Posted By : {jobdetails?.created?.name}
                    </p>
                    <p>
                      {} at {jobdetails?.company_name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JobReview;
