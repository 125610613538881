import React, { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { BarLoader } from "react-spinners";
import {
  AddSlider,
  DeleteSliders,
  FetchSliders,
} from "../../Redux/Slices/Slider";
import AdminSideBar from "./Common/AdminSideBar";
import AdminNavbar from "./Common/AdminNavbar";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

const Sliders = () => {
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();
  const { slidersData } = useSelector((state) => state?.Slider);

  //Submit
  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };
  // Handle image upload
  const handleImageUpload = async () => {
    if (image) {
      const formData = new FormData();
      formData.append("image", image);
      await AddSlider(formData, "multipartformdata");
      dispatch(FetchSliders());
      setImage("");
    } else {
      toast.error("Add Image");
    }
  };

  // Delete
  const handleDelete = async (id) => {
    await DeleteSliders(id);
    dispatch(FetchSliders());
  };

  useEffect(() => {
    if (slidersData?.length === 0) {
      dispatch(FetchSliders());
    }
    document.title = "Sliders | Admin";

  }, [dispatch]);

  // Format the date
  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const formattedDate = dateObject
      .toISOString()
      .replace("T", " ")
      .replace("Z", "");
    return formattedDate.slice(0, -5);
  };
  return (
    <div>
      {/* ------- Navbar ------- */}
      <AdminNavbar />
      <div className="row m-0">
        <div className="col-md-2 p-0 nav-content">
          {/* ------- Sidebar ------- */}
          <AdminSideBar />
        </div>
        <div className="main-content col-md-10 p-0">
          {/* ------- Main Content  ------- */}
          <h2 className="headline">Sliders</h2>
          <div className="main-content-child">
            <label className="form-label">Choose Logo Image</label>
            <div className=" mb-3">
              <input
                onChange={handleFileChange}
                type="file"
                className="form-control w-25"
                id="customFile"
              />
              <button
                type="button"
                className="add-btn mt-3"
                onClick={handleImageUpload}
              >
                Upload Image
              </button>
            </div>

            {/* ------ Table -------- */}

            <div className="main-panel">
              <div className="content-wrapper ">
                <div className="col-md-12 detail-page_margin">
                  <div className="">
                    {slidersData.length > 0 ? (
                      <table
                        id="example"
                        className="table table-striped table-bordered datatable responsive"
                      >
                        <thead>
                          <tr>
                            <th>Id </th>
                            <th>Image</th>
                            <th>Created At</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {slidersData.map((item, index) => (
                            <tr key={index + 1}>
                              <th scope="row">{index + 1}</th>
                              <td className="w-50 ">
                                <img
                                  className="img-fluid w-50 h-50"
                                  src={item?.image}
                                  alt={item?._id}
                                />
                              </td>
                              <td>{formatDate(item?.created_at)}</td>
                              <td>
                                <button
                                  type="button"
                                  value={item?._id}
                                  onClick={(e) => handleDelete(item?._id)}
                                  className="delete-btn"
                                >
                                  <AiFillDelete className="me-1 fs-6 " /> Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <BarLoader color="#36d7b7" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sliders;
