import axios from "axios";
import toast from "react-hot-toast";
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  //  baseURL: "https://cajobportal.com/api/v1",
  // baseURL: "https://cajobportal.neurapses.dev/api/v1",
  // baseURL: "http://localhost:3030",
});

const initialToken = JSON.parse(localStorage.getItem("token"));
const token = `Bearer ${initialToken}`;
axiosInstance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response && error.response.status === 401) {
    localStorage.clear();
    window.location = '/signin'
    toast("Logout Successfully")
    // dispatch(logout());
      // store.dispatch(SignOut()).then(() => {
      //     window.location = SignInUrl
      // });        
  }  
  return Promise.reject(error);
});

export const config = {
  
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
    Authorization: token,
  },
};
