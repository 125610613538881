import React, { useEffect } from "react";
import AdminSideBar from "./Common/AdminSideBar";
import AdminNavbar from "./Common/AdminNavbar";
import AdminHome from "./AdminHome";

const Dashboard = () => {
  useEffect(()=>{
    document.title = "Dashboard | Admin";

  },[])
  return (
    <div className="p-0">
      {/* ------- Navbar ------- */}
      <AdminNavbar />
      <div className="row m-0 w-100">
        <div className="col-12 col-md-2 p-0 nav-content">
          {/* ------- Sidebar ------- */}
          <AdminSideBar />
        </div>
        <div className="main-content col-md-10 col-12 p-0">
          {/* ------- Main Content  ------- */}
          <h2 className="headline">Dashboard</h2>
          <AdminHome />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
