import React from "react";
import { BsInfoCircle } from "react-icons/bs";
import { FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";


const NoSubscriptionAlert = ({salesHistory}) => {
  const navigate = useNavigate();

  if(!salesHistory){
   return <BeatLoader color="#0cffdf" size={15} />
  }
  return (
    <div>
      {salesHistory?.length >
          0 ? (
        <></>
      ) : (
        <>
          {" "}
          <div className="">
            <div className="no-sub-msg my-4">
              <h6 className="msg">
                <BsInfoCircle
                  style={{ fontSize: "22px", color: "rgb(0, 128, 255)" }}
                />{" "}
                You don't have any subscription plan
              </h6>
              <button
                onClick={() => navigate("/employer/package")}
                className="buy-btn "
              >
                Buy Now <FiArrowRight />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NoSubscriptionAlert;
