import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetUser, UpdateUser } from "../../../../Redux/Slices/User";
import { lacOption, thousandOption } from "../../../../Helper/Option";
import { GoPlus } from "react-icons/go";
import "react-datepicker/dist/react-datepicker.css";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import { FetchIndustries } from "../../../../Redux/Slices/Industry";
import { FetchSkill } from "../../../../Redux/Slices/Skill";
import CitiesData from "../../../../Helper/Cities.json";

const CareerProfile = ({ userData }) => {
  // const { userData } = useSelector((state) => state?.User);
  const { industriesData } = useSelector((state) => state?.Industry);
  const { skillsData } = useSelector((state) => state?.Skill);
  const [editData, setEditData] = useState();
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setEditData({
      profile_headline: userData?.career?.profile_headline,
      accomplishments: userData?.career?.accomplishments,
      lac: userData?.career?.expected_salary?.lac,
      thousand: userData?.career?.expected_salary?.thousand,
      _id: userData?._id,
    });
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setEditData({
      profile_headline: userData?.career?.profile_headline,
      accomplishments: userData?.career?.accomplishments,
      lac: userData?.career?.expected_salary?.lac,
      thousand: userData?.career?.expected_salary?.thousand,
      _id: userData?._id,
    });
  }, [userData]);

  const [error, setError] = useState({});
  const dispatch = useDispatch();

  // --------------- City ----------------

  const [selectedCities, setselectedCities] = useState([]);
  const animatedComponents = makeAnimated();

  const citiesOptions = CitiesData.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const handleSelectCities = (cities) => {
    setselectedCities(cities);
  };

  // Setting up initial values when user data is loaded
  useEffect(() => {
    if (userData?.career?.preferred_location) {
      const defaultcities = userData?.career?.preferred_location?.map(
        (item) => ({
          value: item,
          label: item,
        })
      );
      setselectedCities(defaultcities);
    }
  }, [userData]);

  //------------- Industry select ---------
  const [selectedIndustries, setSelectedIndustries] = useState([]);

  const industryOptions = industriesData?.docs?.map((item) => ({
    value: item._id,
    label: item.name,
  }));

  const handleSelectIndustry = (industries) => {
    setSelectedIndustries(industries);
  };

  useEffect(() => {
    if (userData?.career?.industry) {
      const defaultIndustries = userData.career.industry.map((industry) => ({
        value: industry._id,
        label: industry.name,
      }));
      setSelectedIndustries(defaultIndustries);
    }
  }, [userData]);

  const [selectedFnSkills, setSelectedFnSkills] = useState([]);
  const [selectedItSkills, setSelectedItSkills] = useState([]);

  const itSkillOptions = skillsData?.itDocs?.map((item) => ({
    value: item._id,
    label: item.name,
    type: item.type,
  }));

  const fnSkillOptions = skillsData?.docs?.map((item) => ({
    value: item._id,
    label: item.name,
    type: item.type,
  }));

  const handleSelectIt = (itSkill) => {
    setSelectedItSkills(itSkill);
  };

  const handleSelectFn = (fnSkill) => {
    setSelectedFnSkills(fnSkill);
  };

  useEffect(() => {
    if (userData?.career?.skills) {
      const filteredFnSkills = userData.career.skills.filter(
        (skill) => skill.type === "Functional"
      );
      const defaultFn = filteredFnSkills.map((skill) => ({
        value: skill._id,
        label: skill.name,
        type: skill.type,
      }));
      setSelectedFnSkills(defaultFn);

      const filteredItSkills = userData.career.skills.filter(
        (skill) => skill.type === "IT"
      );
      const defaultIt = filteredItSkills.map((skill) => ({
        value: skill._id,
        label: skill.name,
        type: skill.type,
      }));
      setSelectedItSkills(defaultIt);
    }
  }, [userData]);

  // ------------- OnChange Validation --------------

  let name, value;
  const postUserData = (e) => {
    name = e.target.name;
    value = e.target.value;

    setEditData((prevData) => ({ ...prevData, [name]: value }));
  };

  //  -------------- Update  --------------

  const handleUpdate = async (id) => {
    const updatedSkills = [...selectedItSkills, ...selectedFnSkills].map(
      (skill) => skill.value
    );
    let data = {
      career: {
        profile_headline:
          editData?.profile_headline || userData?.career?.profile_headline,
        accomplishments:
          editData?.accomplishments || userData?.career?.accomplishments,
        expected_salary: {
          lac: editData?.lac || userData?.career?.expected_salary?.lac,
          thousand:
            editData?.thousand || userData?.career?.expected_salary?.thousand,
        },
        industry:
          selectedIndustries?.length > 0
            ? selectedIndustries.map((industry) => industry.value)
            : userData?.career?.industry,
        skills:
          updatedSkills.length > 0 ? updatedSkills : userData?.career?.skills,
        preferred_location:
          selectedCities?.length > 0
            ? selectedCities.map((city) => city.value)
            : userData?.career?.preferred_location,
      },
    };
    await UpdateUser(id, data, "application/json");
    dispatch(GetUser(id));
    setEditData("");
    closeModal();
  };

  useEffect(() => {
    let data = {
      page: 1,
      search: "",
    };
    dispatch(FetchIndustries(data));
    dispatch(FetchSkill(data));
  }, []);

  // Industr Name
  let industryName = userData?.career?.industry
    ?.map((value) => value?.name)
    .join(", ");

  // IT Name
  let itName = userData?.career?.skills
    ?.filter((value) => value?.type === "IT")
    ?.map((value) => value.name)
    .join(", ");

  // FN Name
  let fnName = userData?.career?.skills
    ?.filter((value) => value.type === "Functional")
    ?.map((value) => value.name)
    .join(", ");

  //Location
  let location = userData?.career?.preferred_location
    .map((value) => value)
    .join(", ");

  // Modal
  useEffect(() => {
    if (showModal) {
      const backdrop = document.createElement("div");
      backdrop.className = "modal-backdrop fade show";
      document.body.appendChild(backdrop);
      document.body.classList.add("modal-open");
    } else {
      const backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) {
        backdrop.remove();
      }
      document.body.classList.remove("modal-open");
    }

    // Clean up on unmount
    return () => {
      const backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) {
        backdrop.remove();
      }
      document.body.classList.remove("modal-open");
    };
  }, [showModal]);

  return (
    <>
      <div className="prfile-card my-3">
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-center">
            <h3 className="card-head-line">Career Profile</h3>
          </div>
          <div>
            <button
              // onClick={(e) => handleEdit()}
              onClick={openModal}
              // data-bs-toggle="modal"
              // data-bs-target="#editCareer"
              className="btnpf4"
            >
              <GoPlus className="fs-2" />
            </button>
          </div>
        </div>
        {/*------ Additional Details ---- */}
        {userData?.career && (
          <div className=" mt-2">
            <p>
              <span className="prof-details">Profile Headline</span>:{" "}
              {userData?.career?.profile_headline}{" "}
              {/* <MdDeleteOutline onClick={() => handleDelete(index)} /> */}
            </p>
            <p>
              <span className="prof-details">Career Accomplishments</span> :{" "}
              {userData?.career?.accomplishments}
            </p>
            {userData?.career?.expected_salary?.lac >= 0 && (
              <p>
                <span className="prof-details">Expected Salary</span> :{" "}
                {userData?.career?.expected_salary?.lac}
                {userData?.career?.expected_salary?.thousand !== undefined
                  ? `.${userData?.career?.expected_salary?.thousand}`
                  : ""}{" "}
                Lacs
              </p>
            )}

            
            <p>
              <span className="prof-details">Industry</span> : {industryName}
            </p>
            <p>
              <span className="prof-details">IT Skills</span> : {itName}
            </p>
            <p>
              <span className="prof-details">Functional Skills</span> : {fnName}
            </p>
            <p>
              <span className="prof-details">Preferred Location</span> :{" "}
              {location}
            </p>
          </div>
        )}

        {/* Add  Modal */}
        {showModal && (
          <div
            className="modal fade show"
            style={{ display: "block" }}
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Career Profile
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeModal}
                  />
                </div>
                <div className="modal-body">
                  <form className="experience-modal">
                    {/* -------- profile_headline  --------- */}
                    <label htmlFor="recipient-name" className="col-form-label">
                      {error.attempt ? (
                        <span style={{ color: "red" }}> {error.attempt}</span>
                      ) : (
                        "Profile Headline"
                      )}
                    </label>
                    <textarea
                      id="profile_headline"
                      name="profile_headline"
                      value={editData?.profile_headline || ""}
                      rows={5}
                      placeholder="Profile Headline"
                      className="form-control"
                      onChange={(e) => postUserData(e)}
                    />

                    {/* -------- accomplishments  --------- */}
                    <label htmlFor="recipient-name" className="col-form-label">
                      {error.attempt ? (
                        <span style={{ color: "red" }}> {error.attempt}</span>
                      ) : (
                        "Career Accomplishments"
                      )}
                    </label>
                    <textarea
                      id="accomplishments"
                      name="accomplishments"
                      rows={5}
                      value={editData?.accomplishments || ""}
                      placeholder="Career Accomplishments"
                      className="form-control"
                      onChange={(e) => postUserData(e)}
                    />

                    {/* --------- Expected Salary------------ */}
                    <label htmlFor="recipient-name" className="col-form-label">
                      {error.lac ? (
                        <span style={{ color: "red" }}> {error.lac}</span>
                      ) : (
                        "Expected Salary"
                      )}
                    </label>
                    <div className="d-flex align-items-center">
                      {/* ---- lac ---- */}
                      <select
                        name="lac"
                        onChange={(e) => postUserData(e)}
                        className="form-select me-3"
                        aria-label="Default select example"
                        value={editData?.lac || ""}
                      >
                        <option value={""}>Select</option>
                        {lacOption?.map((value) => (
                          <option key={value + 2} value={value}>
                            {`${value} Lacs`}
                          </option>
                        ))}
                      </select>
                      {/* ----- thousand----- */}
                      <select
                        name="thousand"
                        onChange={(e) => postUserData(e)}
                        className="form-select"
                        aria-label="Default select example"
                        value={editData?.thousand || ""}
                      >
                        <option value={""}>Select </option>
                        {thousandOption?.map((value) => (
                          <option key={value} value={value}>
                            {`${value} Thousands`}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/*----- Industry -------- */}
                    <label htmlFor="recipient-name" className="col-form-label">
                      {error.industry ? (
                        <span style={{ color: "red" }}> {error.year}</span>
                      ) : (
                        "Industry"
                      )}
                    </label>
                    <Select
                      id="industry-select"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={industryOptions}
                      onChange={handleSelectIndustry}
                      value={selectedIndustries}
                    />

                    {/*----- IT Skill -------- */}
                    <label htmlFor="recipient-name" className="col-form-label">
                      {error.skill ? (
                        <span style={{ color: "red" }}> {error.year}</span>
                      ) : (
                        "IT Skills"
                      )}
                    </label>
                    <Select
                      id="multiple-select-IT"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={itSkillOptions}
                      onChange={handleSelectIt}
                      value={selectedItSkills}
                    />

                    {/*----- Functional Skill -------- */}
                    <label htmlFor="recipient-name" className="col-form-label">
                      {error.year ? (
                        <span style={{ color: "red" }}> {error.year}</span>
                      ) : (
                        "Functional Skills"
                      )}
                    </label>
                    <Select
                      id="multiple-select-FN"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={fnSkillOptions}
                      onChange={handleSelectFn}
                      value={selectedFnSkills}
                    />

                    {/*----- Location -------- */}
                    <label htmlFor="recipient-name" className="col-form-label">
                      {error.year ? (
                        <span style={{ color: "red" }}> {error.year}</span>
                      ) : (
                        "Preferred Location"
                      )}
                    </label>
                    <Select
                      id="multiple-select"
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={citiesOptions}
                      onChange={handleSelectCities}
                      value={selectedCities}
                    />
                  </form>
                </div>

                {/* Button */}
                <div className="modal-footer">
                  <button
                    onClick={(e) => handleUpdate(editData?._id)}
                    type="button"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CareerProfile;
