import toast from "react-hot-toast";
import { axiosInstance, config } from "../../Axios/AxiosInstance";

let header = config;
export const GetPackage = async () => {
  try {
    const { data } = await axiosInstance.get("package");
    return data;
  } catch (error) {
    console.error("Error fetching package:", error);
    return error;
  }
};
export const GetOnePackage = async (id) => {
  try {
    const { data } = await axiosInstance.get(`package/${id}`, header);
    return data;
  } catch (error) {
    console.error("Error fetching package:", error);
    return error;
  }
};

export const CreatePackage = async (body) => {
  try {
    const { data } = await axiosInstance.post("package", body, header);
    toast.success(data?.message)
    return data;
  } catch (error) {
    console.error("Error fetching package:", error);
    return error;
  }
};

export const DeletePackage = async (id) => {
  try {
    const { data } = await axiosInstance.delete(`/package/${id}`,header);
    return data;
  } catch (error) {
    console.error("Error fetching package:", error);
    return error;
  }
};

export const CreateSubscriptionOrder = async (body) => {
  try {
    const { data } = await axiosInstance.post("subscription", body, header);
    toast.success(data?.message);
    return data;
  } catch (error) {
    console.error("Error fetching package:", error);
    return error;
  }
};
export const CreateSubscriptionByAdmin = async (body) => {
  try {
    const { data } = await axiosInstance.post("subscriptionbyadmin", body, header);
    toast.success(data?.message);
    return data;
  } catch (error) {
    console.error("Error fetching package:", error);
    return error;
  }
};

export const GetAllSubscription = async (datas) => {
  try {
    let url = "subscription";

    if (datas?.page) {
      url += `?$page=${datas?.page}`;
    }
    const { data } = await axiosInstance.get(url, header);
    return data;
  } catch (error) {
    console.error("Error fetching package:", error);
    return error;
  }
};

export const GetUserSubscriptions = async (datas) => {
  try {
    let url = "subscriptions";

    // if (datas?.page) {
    //   url += `?$page=${datas?.page}`;
    // }
    const { data } = await axiosInstance.get(url, header);
    return data;
  } catch (error) {
    console.error("Error fetching package:", error);
    return error;
  }
};

export const searchAllSubscription = async (datas) => {
  try {
    let url = "subscription";

    if (datas?.data.search) {
      url += `?$page=${datas?.data?.page}&$search=${datas?.data?.search}`;
    }
    const { data } = await axiosInstance.get(url, header);
    return data;
  } catch (error) {
    console.error("Error fetching package:", error);
    return error;
  }
};
