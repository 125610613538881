import React, { useEffect, useState } from "react";
import { FaUserTie, FaUserPlus } from "react-icons/fa";
import { BsFillPeopleFill } from "react-icons/bs";
import { AiOutlineFileDone } from "react-icons/ai";
import { UserCount } from "../../Redux/Slices/User";

const AdminHome = () => {
  const [count, setCount] = useState(null);

  const fetchCounts = async () => {
    try {
      const data = await UserCount();
      setCount(data);
    } catch (error) {
      console.error("Error fetching counts:", error);
      // Handle error, maybe set an error state to show to the user
    }
  };

  useEffect(() => {
    fetchCounts();
  }, []);

  return (
    <>
      <div className="main-content-child">
        <div className="d-flex flex-wrap justify-content-around">
          <div className="dash-card p-4 bg-white shadow p-3 mb-5 bg-body-tertiary rounded">
            <p className="text-center">
              <BsFillPeopleFill className="fs-1 text-primary" />
            </p>
            <div>
              <p className="dashcard-text">
                Employers - {count?.employerCount}
              </p>
              {/* Add similar lines for "Active Employers" and "InActive Employers" */}
            </div>
          </div>
          <div className="dash-card p-4 bg-white shadow p-3 mb-5 bg-body-tertiary rounded">
            <p className="text-center">
              <FaUserTie className="fs-1 text-warning" />
            </p>
            <div>
              <p className="dashcard-text">
                Jobseekers - {count?.jobseekerCount}
              </p>
              <p className="dashcard-text">Resume - {count?.resumecount}</p>

              {/* Add similar lines for "Active Jobseekers" and "InActive Jobseekers" */}
            </div>
          </div>
          <div className="dash-card p-4 bg-white shadow p-3 mb-5 bg-body-tertiary rounded">
            <p className="text-center">
              <FaUserPlus className="fs-1 text-info" />
            </p>
            <div>
              <p className="dashcard-text text-nowrap">
                No Of Jobs Post - {count?.jobcount}
              </p>
              {/* Add similar lines for "Active Jobs", "InActive Jobs", and "Expired Jobs" */}
            </div>
          </div>
          <div className="dash-card p-4 bg-white shadow p-3 mb-5 bg-body-tertiary rounded">
            <p className="text-center">
              <AiOutlineFileDone className="fs-1 text-success" />
            </p>
            <div>
              <p className="dashcard-text">
                Applied Jobs - {count?.appliedjob}{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminHome;
