import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance, config } from "../../Axios/AxiosInstance";
import toast from "react-hot-toast";

//Token
let header = config;

// http://localhost:3000/api/folder
export const CreateFolder = async (payload) => {
  try {
    let { data } = await axiosInstance.post("folder", payload, header);
    toast.success(data?.message);
    return data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error.message;
  }
};
// http://localhost:3000/api/subfolder
export const CreateSubFolder = async (id,payload) => {
  try {
    let { data } = await axiosInstance.post(`subfolder/${id}`, payload, header);
    toast.success(data?.message);
    return data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error.message;
  }
};

// http://localhost:3000/api/folder
export const GetToSubFolder = async (id) => {
  try {
    let { data } = await axiosInstance.get(`subfolder/${id}`, header);
    return data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error.message;
  }
};

export const searchAllFolders = async (datas) => {
  try {
    let url = "folder";

    if (datas?.data.search || datas?.data.limit) {
      url += `?$page=${datas?.data?.page}&$search=${datas?.data?.search}&$limit=${datas?.data.limit}`;
    }
    const { data } = await axiosInstance.get(url, header);
    return data;
  } catch (error) {
    console.error("Error fetching package:", error);
    return error;
  }
};

export const paginateAllFolders = async (datas) => {
  try {
    let url = "folder";

    if (datas?.page) {
      url += `?$page=${datas?.page}`;
    }
    const { data } = await axiosInstance.get(url, header);
    return data;
  } catch (error) {
    console.error("Error fetching package:", error);
    return error;
  }
};

// http://localhost:3000/api/folder
export const GetFolder = async (data) => {
  try {
    let { data } = await axiosInstance.get("folder?$page=1&$limit=10", header);
    return data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error.message;
  }
};
