import React from "react";
import FeaturedJob from "../../../asset/new/images/Frame_325.png";
import PSUJob from "../../../asset/new/images/Frame_326.png";
import ThirdParty from "../../../asset/new/images/Frame_327.png";
import Newsletter from "../Newsletter";
import { Link, useNavigate } from "react-router-dom";
const JobCategory = () => {
  
  return (
    <>
      <div className="container" style={{ marginTop: 50 }}>
        <div className="row">
          <h4 className="mb-4">Job Category</h4>
          <div className="col-12 col-md-4 mt-3 mt-md-0">
            <Link
             to={'/featured-job'}
              style={{ color: "black", textDecoration: "none" }}
            >
              <div
                className="card"
                style={{
                  maxWidth: "100%",
                  margin: "auto",
                  borderRadius: 10,
                  backgroundColor: "#BBE6F9",
                  height: "100%",
                  border: "none",
                }}
              >
                
                <div className="card-body cord" >
                  <h5 className=" text-center">
                    <img
                      src={FeaturedJob}
                      style={{
                        width: 45,
                        color: "white",
                        height: "auto",
                        objectFit: "cover",
                      }}
                      alt="star"
                    />
                  </h5>
                  <Link to={'/featured-job'} className="card-title text-center job_category_title">
                    Featured Jobs
                  </Link>
                  <p
                    className="card-text text-center job_category_desc"
                    style={{ fontSize: 12 }}
                  >
                    Jobs managed by CAJobPortal on behalf of clients
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-md-4 mt-3 mt-md-0">
            <Link
              to={'/career-sites'}
              style={{ color: "black", textDecoration: "none" }}
            >
              <div
                className="card"
                style={{
                  maxWidth: "100%",
                  margin: "auto",
                  borderRadius: 13,
                  backgroundColor: "#BBE6F9",
                  height: "100%",
                  border: "none",
                }}
              >
                <div className="card-body cord career">
                  <h5 className=" text-center">
                    <img
                      src={PSUJob}
                      style={{ width: 43, height: "auto", objectFit: "cover" }}
                      alt="star"
                    />
                  </h5>
                  <Link to={"/career-sites"} className="card-title text-center job_category_title">
                    PSU Jobs / Career sites
                  </Link>
                  <p
                    className="card-text text-center careertext job_category_desc"
                    style={{ fontSize: 12 }}
                  >
                    Jobs hosted on the career sites of companies
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-md-4 mt-3 mt-md-0">
            <Link
              to={'/third-party-listing'}
              style={{ color: "black", textDecoration: "none" }}
            >
              <div
                className="card"
                style={{
                  maxWidth: "100%",
                  margin: "auto",
                  borderRadius: 13,
                  backgroundColor: "#BBE6F9",
                  height: "100%",
                  border: "none",
                }}
              >
                <div className="card-body thirdp cord">
                  <h5 className=" text-center">
                    <img
                      src={ThirdParty}
                      style={{ width: 43, height: "auto", objectFit: "cover" }}
                      alt="star"
                    />
                  </h5>
                  <h5 className="card-title text-center job_category_title">
                    Third party listings
                  </h5>
                  <p
                    className="card-text text-center job_category_desc oneline "
                    style={{ fontSize: 12 }}
                  >
                    Jobs posted directly by companies / search firms
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>

      {/* // -------- News Lettter ------- */}

      <Newsletter />
    </>
  );
};

export default JobCategory;
