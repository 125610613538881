import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { Store } from "./Redux/Store/Store";
import { Toaster } from "react-hot-toast";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
let client = process.env.REACT_APP_GOOGLE_CLIENT_ID;
root.render(
  //<React.StrictMode>
    <GoogleOAuthProvider clientId={client}>
      <Provider store={Store}>
        <App />
        <Toaster />
      </Provider>
    </GoogleOAuthProvider>
  //</React.StrictMode>
);
reportWebVitals();
