import React, { useEffect, useState } from "react";
import AdminNavbar from "./Common/AdminNavbar";
import AdminSideBar from "./Common/AdminSideBar";
import { useDispatch, useSelector } from "react-redux";
import { ExportUser, FetchUsers, Pagination } from "../../Redux/Slices/User";
import { BarLoader } from "react-spinners";
import toast from "react-hot-toast";
import { GetMyAllSubscription } from "../../Redux/Slices/JobPost";

const EmployerList = () => {
  const { usersData } = useSelector((state) => state?.User);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState("");
  const [packageDetails, setPackageDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  

  //View Subscription Details

  const handleView = async (data) => {
    let res = await GetMyAllSubscription(data?._id);
    await setUserDetails({ ...data, res });
  };

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filterUser, setFilterUser] = useState(usersData);

  useEffect(() => {
    const fetchData = async () => {
      let data = await Pagination("Employer", currentPage, itemsPerPage);
      setFilterUser(data?.users);
    };

    fetchData();
    let data;
    let prevPage = localStorage.getItem('prevPage');
    if (prevPage) {
      data = JSON.parse(prevPage);
    } else {
      data = {
        role: "Employer",
        page:1,
        search:searchQuery
      };
    }
    dispatch(FetchUsers(data));
    document.title = "Employer";
  }, []);

  // Employer List
  // const jobRecruiterUsers = usersData?.docs?.filter(
  //   (user) =>
  //     user?.name?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
  //     user?.email?.toLowerCase().includes(searchQuery?.toLowerCase())
  // );

  const handleSort = (field) => {
    if (sortBy === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(field);
      setSortOrder("asc");
    }
  };

  // Sort the jobRecruiterUsers array
  // const sortedUsers = jobRecruiterUsers?.sort((a, b) => {
  //   const aValue = a[sortBy] || "";
  //   const bValue = b[sortBy] || "";
  //   return sortOrder === "asc"
  //     ? aValue.localeCompare(bValue)
  //     : bValue.localeCompare(aValue);
  // });

  const exportUser = async () => {
    try {
      setLoading(true);
      await ExportUser("Employer");
      setLoading(false);
      toast.success("User exported successfully");
    } catch (error) {
      setLoading(false);
      toast.error("Failed to export user");
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    let data = {
      role:"Employer",
      search:e.target.value,
      page:1
    };
    dispatch(FetchUsers(data));
    
  };

  const handlePageChange = async (page) => {
    let data = {
      role: "Employer",
      page: page,
      search:searchQuery
    };
    dispatch(FetchUsers(data));
    localStorage.setItem('prevPage',JSON.stringify(data))
  };

  // Calculate visible page range
  let startPage = 1;
  let endPage = 1;

  if (usersData?.pagination) {
    startPage = Math.max(1, usersData.pagination.page - 2);
    endPage = Math.min(
      usersData.pagination.totalPages,
      usersData.pagination.page + 2
    );

    // Adjust start and end page if close to beginning or end
    if (usersData.pagination.page <= 3) {
      endPage = Math.min(5, usersData.pagination.totalPages);
    } else if (
      usersData.pagination.page >=
      usersData.pagination.totalPages - 2
    ) {
      startPage = Math.max(1, usersData.pagination.totalPages - 4);
    }
  }

  // Package details

  const packaghandler = async (userId) => {
    let data = await GetMyAllSubscription(userId)
      setPackageDetails(data)
  };
  return (
    <div>
      <div>
        {/* ------- Navbar ------- */}
        <AdminNavbar />
        <div className="row m-0">
          <div className="col-6 col-md-2 nav-content p-0">
            {/* ------- Sidebar ------- */}
            <AdminSideBar />
          </div>
          <div className="main-content col-md-10 p-0">
            <h2 className="headline">Employers List</h2>
            {/* ------- Main Content  ------- */}
            <div className="main-content-child">
              <div className="d-flex justify-content-between">
                <button onClick={exportUser} className="export-btn">
                  {loading ? <BarLoader color="white" /> : "Export All Records"}
                </button>
                <input
                  type="search"
                  id="form1"
                  placeholder="Search"
                  className="search-list"
                  onChange={(e) => handleSearch(e)}
                />
              </div>
              {usersData?.docs && (
                <div className="main-panel">
                <div className="content-wrapper ">
                  <div className="col-md-12 detail-page_margin">
                    <div className="">
                      {usersData?.docs[0].data?.length > 0 ? (
                        <table
                          id="example"
                          className="table table-striped table-bordered datatable responsive"
                        >
                          <thead>
                            <tr className="th-sm">
                              <th
                                className="headerSortDown"
                                onClick={() => handleSort("name")}
                              ></th>
                              <th
                                className="headerSortUp"
                                onClick={() =>
                                  handleSort("account_id.company.name")
                                }
                              >
                                Company Name
                              </th>
                              <th onClick={() => handleSort("name")}>
                                Employer Name{" "}
                              </th>
                              <th
                                onClick={() =>
                                  handleSort("account_id.designation")
                                }
                              >
                                Designation
                              </th>
                              <th onClick={() => handleSort("email")}>Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            {usersData?.docs[0].data.map((user, index) => (
                              <tr key={index}>
                                <th scope="row">
                                  <button
                                    onClick={() => {
                                      handleView(user);
                                      packaghandler(user?._id);
                                    }}
                                    type="button"
                                    className="show-btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#viewUserDetails"
                                  >
                                    +
                                  </button>
                                </th>
                                <td>
                                  {user?.experience[0]?.company_name ?? ""}
                                </td>
                                <td>{user?.name || ""}</td>
                                <td>
                                  {user?.experience[0]?.designation ?? ""}
                                </td>
                                <td>{user?.email || ""}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div>
                           <center>No Data Available In Table</center>
                          {/* <BarLoader color="#36d7b7" /> */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              )}
              

              {/* --- Pagination ----- */}
              {usersData?.pagination && usersData?.docs?.length > 0 && (
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li
                      className={`page-item ${
                        usersData?.pagination?.prevPage ? "" : "disabled"
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() =>
                          handlePageChange(usersData?.pagination?.prevPage)
                        }
                      >
                        Previous
                      </button>
                    </li>
                    {Array.from(
                      { length: endPage - startPage + 1 },
                      (_, i) => startPage + i
                    ).map((page) => (
                      <li
                        key={page}
                        className={`page-item ${
                          usersData?.pagination?.page === page ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(page)}
                        >
                          {page}
                        </button>
                      </li>
                    ))}
                    <li
                      className={`page-item ${
                        usersData?.pagination?.nextPage ? "" : "disabled"
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() =>
                          handlePageChange(usersData?.pagination?.nextPage)
                        }
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              )}
              {/* ----- Pagination End */}
            </div>
            {/* Modal Box  User Details*/}
            <div
              className="modal fade"
              id="viewUserDetails"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      User Details
                    </h5>
                    {/* Close Modal */}
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="mb-3">
                        {/* User details */}
                        <p>
                          <span className="prof-details">Mobile :</span>{" "}
                          {userDetails?.mobile || "Not Available"}
                        </p>
                        <p>
                          <span className="prof-details">
                            Company Website :
                          </span>{" "}
                          {userDetails?.experience?.[0]?.company_web ??
                            "Not Available"}
                        </p>
                        <p>
                          <span className="prof-details">
                            Job Posting Quota :{" "}
                          </span>{" "}
                          {packageDetails?.total_job_postings
                          }
                          {}
                        </p>
                        <p>
                          <span className="prof-details">
                            CV Search Quota :{" "}
                          </span>{" "}
                          {packageDetails?.total_cv_searches
}
                          {}
                        </p>
                        <p>
                        <span className="prof-details">GSTIN : </span>{" "}
                        {userDetails?.gst}
                        {}
                        </p>
                       
                      
                        <p>
                          <span className="prof-details">
                            Validity :{" "}
                          </span>{" "}
                          {userDetails?.res?.subscriptions.length > 0 && (
                          
                            <span>{userDetails?.res?.subscriptions[0]?.package?.validity_day} days</span>
                          )}
                          
                          
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployerList;
