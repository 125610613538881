import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance, config } from "../../Axios/AxiosInstance";
import toast from "react-hot-toast";

//Token
let header = config;

// http://localhost:3000/api/industries/all
export const FetchIndustries = createAsyncThunk(
  "industries/all/fetch",
  async (data) => {
    try {
      let res = await axiosInstance.get(`industries/all?$page=${data.page}&$search=${data?.search}&$limit=${data?.limit ? data.limit : 10}`);
      return res?.data;
    } catch (error) {
      return error.message
    }
  }
);

// http://localhost:3000/api/industries
export const AddIndustry = async (data) => {
  try {
    let res = await axiosInstance.post("industries", data, header);
    toast.success(res?.data?.message);
    return res?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error.message
  }
};

// http://localhost:3000/api/industries/:id
export const EditIndustry = async (id) => {
  try {
    let data = await axiosInstance.get(`industries/${id}`, header);
    return data?.data;
  } catch (error) {
    return error.message
  }
};

// http://localhost:3000/api/industries/:id
export const UpdateIndustry = async (data, id) => {
  try {
    const res = await axiosInstance.put(`industries/${id}`, data, header);
    toast.success(res?.data?.message);
    return res?.data;
  } catch (error) {
    if (
      error?.response?.data?.message.split(" ").splice(0, 3).join(" ") ===
      "E11000 duplicate key"
    ) {
      toast.error("Name Already Exists");
    }
    return error.message
  }
};
// http://localhost:3000/api/industries/:id
export const DeleteIndustry = async (id) => {
  try {
    let data = await axiosInstance.delete(`industries/${id}`, header);
    toast.success(data?.data?.message);
    return data?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    return error.message
  }
};
const initialState = {
  industriesData: [],
  status: "idel",
  loading: false,

};

export const IndustrytSlice = createSlice({
  name: "IndustriesData",
  initialState,
  reducer: {},
  extraReducers: {
    [FetchIndustries.pending]: (state) => {
      state.status = "Loading......";
      // state.industriesData = null;
    },
    [FetchIndustries.fulfilled]: (state, { payload }) => {
      state.status = "idle";
      state.industriesData = payload;
    },
    [FetchIndustries.rejected]: (state) => {
      state.status = "idel";
    },
  },
});
